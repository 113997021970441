import Switch from 'react-switch';
import { stylesGlobalDefault } from '../../global/globalStyle';
import { ContainerSwitchAndText, SwitchDiv, TextArea } from './styled';

interface InputSwitchInterface {
  onChange: (e: boolean) => unknown;
  onClick?: (props: any) => void;
  text: string;
  checked: boolean;
}

export const InputSwitch = (props: InputSwitchInterface) => {
  return (
    <>
      <ContainerSwitchAndText onClick={props.onClick}>
        <SwitchDiv>
          <Switch
            checked={props.checked}
            onChange={props.onChange}
            onColor='#98e6c1'
            onHandleColor={stylesGlobalDefault.activedItem}
            handleDiameter={20}
            uncheckedIcon={false}
            checkedIcon={false}
            boxShadow='0px 1px 5px rgba(0, 0, 0, 0.6)'
            activeBoxShadow='0px 0px 1px 10px rgba(0, 0, 0, 0.2)'
            height={15}
            width={35}
            className='react-switch'
            id='material-switch'
          />
        </SwitchDiv>
        <TextArea>
          <span>{props.text}</span>
        </TextArea>
      </ContainerSwitchAndText>
    </>
  );
};
