import { useState } from "react";
//Toast
import { useDispatch } from "react-redux";
import { useToast } from "../../../hooks/toast";
import { hideLoading, showLoading } from "../../../store/modules/login/actions";

//Permissões
import { ReportArea } from "../../../components/Body/ReportArea";
import { UserInterface } from "../../../components/Body/ReportArea/dtos";
import { reportService } from "../../../services/report";
import { FORMS_ADMIN } from "../../../utils/entities/permission";
import { getValueFromlocalStorage } from "../../../utils/sessionStorageEncrypt";

export function ProceduresPerYer() {
  const today = new Date().toISOString().split('T')[0];
  const [element, setElement] = useState<UserInterface>();
  const [listReport, setListReport] = useState<UserInterface[]>([]);
  const [unitId, setUnitId] = useState()
  const user = getValueFromlocalStorage("@Kenta:user");
  const [initialDate, setInitialDate] = useState<any>(today)
  const [endDate, setendDate] = useState<any>(today)

  const dispatch = useDispatch();
  const { addToast } = useToast();

  if (user) {
    const user_parsed = JSON.parse(user);
    const userCustomerId = user_parsed.customerId;
  }

  async function onLoadList() {
    if (!unitId) {
      addToast({
        type: "warning",
        title: "Aviso",
        description: "Você precisa selecionar uma unidade de policia."
      });
      return;
    }

    dispatch(showLoading())
    const response = await reportService.getAllPoliceUnitPerYer(unitId!);
    if (response) {
      if (response.status) {
        setListReport(response.data.data);
      } else {
        addToast({
          type: "error",
          title: "Erro",
          description: response.message
        });
      }
    }
    dispatch(hideLoading());

  }
  function handleReport() {
    onLoadList()
  }


  return (
    <>
      <ReportArea
        title={"Procedimentos por ano"}
        InputSearchLabel="Buscar por:"
        list={listReport}
        headerAddButtonOnClick={() => { }}
        headerTrashButtonOnClick={() => alert("click trash")}
        onClick={handleReport}
        setItemReturnClick={setElement}
        setOpenModal={() => { }}
        setIsEdit={false}
        permissionName={FORMS_ADMIN.POLICE_UNIT}
        onChangeSituation={setUnitId}
        initialDate={initialDate}
        endDate={endDate}
        setInitialDate={setInitialDate}
        setEndDate={setendDate}
      />
    </>
  );
}
