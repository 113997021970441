import styled from "styled-components";

interface IContainerList {
  height?: string;
}
export const ContainerList = styled.div<IContainerList>`
  width: 100%;
  //height: ${(props) => (props.height ? props.height : "61.5vh")} !important;
  padding-top: 16px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  justify-content: space-between;

  /* @media screen and (min-height: 850px) {
    //height: 70vh;
    height: 63vh;
  } */

  /* ::-webkit-scrollbar{
    display: none;
  } */
  @media screen and (max-height: 1440px) {
    //background-color: blue;
    height: ${(props) => (props.height ? props.height : "75vh")} !important;
  }
  @media screen and (max-height: 1080px) {
    //background-color: red;
    height: ${(props) => (props.height ? props.height : "62vh")} !important;
  }
  @media screen and (max-height: 768px) {
    //background-color: orange;
    height: ${(props) => (props.height ? props.height : "55vh")} !important;
  }
  @media screen and (max-height: 720px) {
    //background-color: green;
    height: ${(props) => (props.height ? props.height : "42vh")} !important;
  }
  @media screen and (max-height: 480px) {
    //background-color: yellow;
    height: ${(props) => (props.height ? props.height : "28vh")} !important;    
  }
`;

export const ListItems = styled.div`
  //position: relative;
  padding-right: 20px;
`;

export const ListPagination = styled.div`
  height: 6vh !important;
  //position: absolute;
  margin-bottom: 24px;
  padding-right: 24px;
  bottom: 0;
  right: 0;
`;
