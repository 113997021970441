import React, { useState } from 'react';
import { CircleBadge } from '../Badges/Circle';
import { Container, LabelContainer, Label, TextArea, ContainerTextArea } from './styles';

type ITextArea = {
  label?: string,
  required?: boolean,
  onChange: (e: string) => unknown,
  value?: string,
  style?: React.CSSProperties,
};

export const TextAreaComponent: React.FC<ITextArea> = ({ label, required = false, value, onChange, style }) => {
  const [focus, setFocus] = useState(false)
  return (
    <Container style={style}>
      <LabelContainer>
        <Label>{label}</Label>
        {required && <CircleBadge radius="50px" widthHeigt="8px" />}
      </LabelContainer>
      <ContainerTextArea focus={focus} onFocus={() => setFocus(true)} onBlur={() => setFocus(false)}>
        <TextArea value={value} onChange={(e) => onChange(e.target.value)} />
      </ContainerTextArea>
    </Container>
  );
};
