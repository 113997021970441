import { ContainerListEmpty, Circle, ImgCircle, TextCircle } from "./styles";
import File from "../../assets/svg/FileIcon-White.svg";
import Video from "../../assets/svg/video.svg";

type IListEmpty = {
  title?: string;
  typeImg?: string;
  showImage?: boolean;
  titleCustom?: string;
};

export const ListEmpty = (props: IListEmpty) => {
  const { showImage = true } = props;
  return (
    <ContainerListEmpty>
      <>
        {showImage ? (
          <Circle>
            <ImgCircle>
              <img
                src={props.typeImg === "oitiva" ? Video : File}
                style={{ width: "100%", height: "100%" }}
                alt=""
              />
            </ImgCircle>
          </Circle>
        ) : null}
      </>
      <TextCircle>
        {props.titleCustom
          ? props.titleCustom
          : props.title
          ? ` Nenhum(a) ${props.title.toLowerCase()} cadastrado(a)`
          : ``}
      </TextCircle>
    </ContainerListEmpty>
  );
};
