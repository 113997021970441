import { AxiosError } from "axios";
import api from "../api";
import { IReponse } from "../interface";
import { returnResponse } from "../utils";
import { CreateAction, IProfile, ResponseActionAdded } from "./interfaces";
import { LogOperation } from "../../utils/entities/logOperation";
import { getValueFromlocalStorage } from "../../utils/sessionStorageEncrypt";

export interface TipoProfileData {
  active: boolean;
  customerId: number;
  id: number;
  name: string;
  description?: string;
}

export interface TipoProfileResponse {
  data: TipoProfileData[];
  errors: string[];
  message: string;
  ok: boolean;
}

export const profilesService = {
  getAllByCustomerId: async (
    status?: 0 | 1 | null
  ): Promise<IReponse<Array<IProfile>>> => {
    try {
      const user = getValueFromlocalStorage("@Kenta:user");

      const userParsed = user ? JSON.parse(user) : null;

      if (userParsed) {
        const { data } = await api.get(
          `/UserCustomerProfile/GetAllByCustomerId?customerId=${
            userParsed.customerId
          }${status !== null ? `&Active=${status}` : ""}`
        );

        return {
          status: true,
          body: data.data,
        };
      } else {
        throw new Error("user not set on local storage");
      }
    } catch (error: any) {
      return {
        status: true,
        body: error.response,
      };
    }
  },
  getAllByCustomerId2: async (
    type: number
  ): Promise<IReponse<Array<IProfile>>> => {
    try {
      const user = getValueFromlocalStorage("@Kenta:user");

      const userParsed = user ? JSON.parse(user) : null;

      if (userParsed) {
        const { data } = await api.get(
          `/UserCustomerProfile/GetAllByCustomerId2?customerId=${userParsed.customerId}&type=${type}`
        );

        return {
          status: true,
          body: data.data,
        };
      } else {
        throw new Error("user not set on local storage");
      }
    } catch (error: any) {
      return {
        status: true,
        body: error.response,
      };
    }
  },
  getFormList: async (customerProfileId: any) => {
    try {
      const user = getValueFromlocalStorage("@Kenta:user");

      const userParsed = user ? JSON.parse(user) : null;

      if (userParsed) {
        const { data } = await api.get(
          `/UserCustomerProfile/GetProfile?customerId=${userParsed.customerId}&userCustomerProfileId=${customerProfileId}`
        );

        return {
          status: true,
          body: data.data,
        };
      } else {
        throw new Error("user not set on local storage");
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        let { severity, status, message, data } = returnResponse({
          statusCode: error.response?.status,
          data: error?.response?.data,
          message: error?.response?.data?.errors[0]?.message,
        });

        return {
          severity,
          status,
          message: message ? message : "Error",
          data,
        };
      }

      return {
        status: false,
        message: "Error",
        data: null,
        severity: "error",
      };
    }
  },
  getAllProfiles: async (authToken: string | null) => {
    if (!authToken) {
      return {
        status: false,
        message: "Não é possível inativar o registro sem o token correto.",
      };
    }

    try {
      const config = {
        headers: { Authorization: `Bearer ${authToken}` },
      };
      const { data } = await api.get<TipoProfileResponse>(
        `/UserCustomerProfile/GetProfile?customerId=${LogOperation.getCustomerId()}`,
        config
      );

      return {
        status: true,
        data: data,
      };
    } catch (error) {
      if (error instanceof AxiosError) {
        let { severity, status, message, data } = returnResponse({
          statusCode: error.response?.status,
          data: error?.response?.data,
          message: error?.response?.data?.errors[0]?.message,
        });

        return {
          severity,
          status,
          message: message ? message : "Error",
          data,
        };
      }

      return {
        status: false,
        message: "Error",
        data: null,
        severity: "error",
      };
    }
  },

  createProfile: async (authToken: string | null, profile: any) => {
    if (!authToken) {
      return {
        status: false,
        message: "Não é possível inativar o registro sem o token correto.",
      };
    }

    try {
      const body = {
        ...profile,
      };
      const { data } = await api.post<any>(`/UserCustomerProfile/Add`, body);

      return {
        status: true,
        data: data.data,
      };
    } catch (error) {
      if (error instanceof AxiosError) {
        let { severity, status, message, data } = returnResponse({
          statusCode: error.response?.status,
          data: error?.response?.data,
          message: error?.response?.data?.errors[0]?.message,
        });

        return {
          severity,
          status,
          message: message ? message : "Error",
          data,
        };
      }

      return {
        status: false,
        message: "Error",
        data: null,
        severity: "error",
      };
    }
  },
  editProfile: async (authToken: string | null, profile: any) => {
    if (!authToken) {
      return {
        status: false,
        message: "Não é possível inativar o registro sem o token correto.",
      };
    }

    try {
      const body = {
        ...profile,
      };
      const { data } = await api.put<any>(`/UserCustomerProfile/Update`, body);

      return {
        status: true,
        data: data.data,
      };
    } catch (error) {
      if (error instanceof AxiosError) {
        let { severity, status, message, data } = returnResponse({
          statusCode: error.response?.status,
          data: error?.response?.data,
          message: error?.response?.data?.errors[0]?.message,
        });

        return {
          severity,
          status,
          message: message ? message : "Error",
          data,
        };
      }

      return {
        status: false,
        message: "Error",
        data: null,
        severity: "error",
      };
    }
  },
  createRelationProfileAction: async (
    authToken: string | null,
    userCustomerProfileId: number,
    systemObjectCustomerId: number
  ) => {
    if (!authToken) {
      return {
        status: false,
        message: "Não é possível inativar o registro sem o token correto.",
      };
    }

    if (!userCustomerProfileId || !systemObjectCustomerId) {
      return {
        status: false,
        message: "Não é possível criar ações sem os dados corretos.",
      };
    }

    try {
      const body = {
        userCustomerProfileId,
        systemObjectCustomerId,
      };
      const { data } = await api.post<ResponseActionAdded>(
        `/SystemObjectUserCustomerProfile/Add`,
        body
      );

      return {
        status: true,
        data: data.data,
      };
    } catch (error) {
      if (error instanceof AxiosError) {
        let { severity, status, message, data } = returnResponse({
          statusCode: error.response?.status,
          data: error?.response?.data,
          message: error?.response?.data?.errors[0]?.message,
        });

        return {
          severity,
          status,
          message: message ? message : "Error",
          data,
        };
      }

      return {
        status: false,
        message: "Error",
        data: null,
        severity: "error",
      };
    }
  },
  createActionByProfileRelation: async (
    authToken: string | null,
    arrayOfActions: CreateAction[]
  ) => {
    if (!authToken) {
      return {
        status: false,
        message: "Não é possível inativar o registro sem o token correto.",
      };
    }

    if (!arrayOfActions || arrayOfActions.length <= 0) {
      return {
        status: false,
        message: "Não é possível criar ações sem os dados corretos.",
      };
    }

    try {
      const { data } = await api.post<ResponseActionAdded>(
        `/SystemObjectUserCustomerProfileAction/AddAll`,
        arrayOfActions
      );

      return {
        status: true,
        data: data.data,
      };
    } catch (error) {
      if (error instanceof AxiosError) {
        let { severity, status, message, data } = returnResponse({
          statusCode: error.response?.status,
          data: error?.response?.data,
          message: error?.response?.data?.errors[0]?.message,
        });

        return {
          severity,
          status,
          message: message ? message : "Error",
          data,
        };
      }

      return {
        status: false,
        message: "Error",
        data: null,
        severity: "error",
      };
    }
  },
  createActionByProfileRelationAction: async (
    authToken: string | null,
    action: CreateAction
  ) => {
    if (!authToken) {
      return {
        status: false,
        message: "Não é possível inativar o registro sem o token correto.",
      };
    }

    if (!action) {
      return {
        status: false,
        message: "Não é possível criar ações sem os dados corretos.",
      };
    }

    try {
      const { data } = await api.post<ResponseActionAdded>(
        `/SystemObjectUserCustomerProfileAction/Add`,
        action
      );

      return {
        status: true,
        data: data.data,
      };
    } catch (error) {
      if (error instanceof AxiosError) {
        let { severity, status, message, data } = returnResponse({
          statusCode: error.response?.status,
          data: error?.response?.data,
          message: error?.response?.data?.errors[0]?.message,
        });

        return {
          severity,
          status,
          message: message ? message : "Error",
          data,
        };
      }

      return {
        status: false,
        message: "Error",
        data: null,
        severity: "error",
      };
    }
  },
  changeProfileStatus: async (
    authToken: string,
    profileId: number,
    status: boolean
  ) => {
    if (!authToken) {
      return {
        status: false,
        message: "Não é possível inativar o registro sem o token correto.",
      };
    }

    if (!profileId) {
      return {
        status: false,
        message: "Não é possível criar ações sem os dados corretos.",
      };
    }

    try {
      const { data } = await api.patch<any>(
        `/UserCustomerProfile/Update`,
        [
          {
            op: "replace",
            value: status,
            path: "/active",
          },
        ],
        {
          params: {
            id: profileId,
          },
        }
      );

      return {
        status: true,
        data: data.data,
      };
    } catch (error) {
      if (error instanceof AxiosError) {
        let { severity, status, message, data } = returnResponse({
          statusCode: error.response?.status,
          data: error?.response?.data,
          message: error?.response?.data?.errors[0]?.message,
        });

        return {
          severity,
          status,
          message: message ? message : "Error",
          data,
        };
      }

      return {
        status: false,
        message: "Error",
        data: null,
        severity: "error",
      };
    }
  },
  changeProfileNomeEDescricao: async (
    authToken: string,
    profileId: number,
    name: string,
    description: string
  ) => {
    if (!authToken) {
      return {
        status: false,
        message: "Não é possível inativar o registro sem o token correto.",
      };
    }

    if (!profileId || !name || !description) {
      return {
        status: false,
        message: "Não é possível criar ações sem os dados corretos.",
      };
    }

    try {
      const { data } = await api.patch<any>(
        `/UserCustomerProfile/Update`,
        [
          {
            op: "replace",
            value: name,
            path: "/name",
          },
          {
            op: "replace",
            value: description,
            path: "/description",
          },
        ],
        {
          params: {
            id: profileId,
          },
        }
      );

      return {
        status: true,
        data: data.data,
      };
    } catch (error) {
      if (error instanceof AxiosError) {
        let { severity, status, message, data } = returnResponse({
          statusCode: error.response?.status,
          data: error?.response?.data,
          message: error?.response?.data?.errors[0]?.message,
        });

        return {
          severity,
          status,
          message: message ? message : "Error",
          data,
        };
      }

      return {
        status: false,
        message: "Error",
        data: null,
        severity: "error",
      };
    }
  },
  deleteAction: async (
    authToken: string,
    systemObjectUserCustomerProfileActionId: any
  ) => {
    if (!authToken) {
      return {
        status: false,
        message: "Não é possível inativar o registro sem o token correto.",
      };
    }

    if (!systemObjectUserCustomerProfileActionId) {
      return {
        status: false,
        message: "Não é possível criar ações sem os dados corretos.",
      };
    }

    try {
      const { data } = await api.delete<any>(
        `SystemObjectUserCustomerProfileAction/Delete`,
        {
          params: {
            id: systemObjectUserCustomerProfileActionId,
          },
        }
      );

      return {
        status: true,
        data: data.data,
      };
    } catch (error) {
      if (error instanceof AxiosError) {
        let { severity, status, message, data } = returnResponse({
          statusCode: error.response?.status,
          data: error?.response?.data,
          message: error?.response?.data?.errors[0]?.message,
        });

        return {
          severity,
          status,
          message: message ? message : "Error",
          data,
        };
      }

      return {
        status: false,
        message: "Error",
        data: null,
        severity: "error",
      };
    }
  },
};
