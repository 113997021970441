import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AuthScreen } from "..";
import { InputTextArea } from "../../../components/InputTextArea";
import { Typhography } from "../../../components/Typhography";
import { useToast } from "../../../hooks/toast";
import { CustomerCertificateService } from "../../../services/customerCertificate";
import { hideLoading, showLoading } from "../../../store/modules/login/actions";
import { Button, ForgotPasswordButton } from "../styles";
import {
  Container,
  ContainerDescription,
  ContainerTitle,
} from "../forgotPassword/style";
import { LogOperation } from "../../../utils/entities/logOperation";

export function ImportCertificate() {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [certificate, setCertificate] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { addToast } = useToast();
  const inputRef = useRef<any>();

  async function importCertificatePublic() {
    setLoading(true);
    dispatch(showLoading());
    const response = await CustomerCertificateService.importCertificate(
      certificate
    );
    if (response.status) {
      addToast({
        type: "success",
        title: "Certificado",
        description: "Certificado atualizado com sucesso",
      });
      await LogOperation.postLogOperation("CustomerCertificate", 7, 0);
      navigate(-1);
    } else {
      addToast({
        type: "error",
        title: "Certificado",
        description: response.message,
      });
      setError(true);
      dispatch(hideLoading());
      setLoading(false);
      return;
    }
    dispatch(hideLoading());
    setLoading(false);
  }

  useEffect(() => {
    if (inputRef && inputRef.current) inputRef.current.focus();
  }, []);

  return (
    <AuthScreen>
      <Container style={{ height: "600px", width: "100%", marginBottom:"100px" }}>
        <ContainerTitle>
          <Typhography fontStyle="bold" size="very-bigger">
            Importar certificado
          </Typhography>
        </ContainerTitle>
        <ContainerDescription>
          <Typhography fontStyle="regular" size="medium">
            Informe código do certificado
          </Typhography>
        </ContainerDescription>        
          <InputTextArea
            error={error}
            label={""}
            value={certificate}
            onChange={(e) => setCertificate(e)}
            required={false}
            width={356}
            height={450}
            startFocus={false}
          />
        
        <div style={{ width: "380px" }}>
          <Button
            disabled={loading}
            type="button"            
            onClick={() => {
              if (!certificate) {
                addToast({
                  type: "error",
                  title: "Certificado",
                  description: "Preencha o campo destacado",
                });
                setError(true);
                return;
              }
              importCertificatePublic();
            }}
          >
            Continuar
          </Button>
        </div>

        <ForgotPasswordButton onClick={() => navigate(-1)}>
          Voltar ao login
        </ForgotPasswordButton>
      </Container>
    </AuthScreen>
  );
}
