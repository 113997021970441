import React from 'react';
import { Container, Title, Description } from './styles';

export type IProcedureItemSidebar = {
  title: string,
  description: string,
};

export const ProcedureItemSidebar: React.FC<IProcedureItemSidebar> = ({ title, description }) => {
  return (
    <Container>
      <Title>{title}</Title>
      <Description>{description}</Description>
    </Container>
  );
};
