import { AxiosError } from "axios";
import api from "../api";
import { returnResponse } from "../utils";

export const AttendantRoleService = {
  getAll: async (customerId: number, status?: 0 | 1 | null) => {
    try {
      const { data } = await api.get<any>(
        `/AttendantRole/GetAllByCustomerId?customerId=${customerId}${
          status === 0 || status === 1 ? `&active=${status}` : ""
        }`
      );

      return {
        status: true,
        data: data,
        message: data.message,
      };
    } catch (error) {
      if (error instanceof AxiosError) {
        let { severity, status, message, data } = returnResponse({
          statusCode: error.response?.status,
          data: error?.response?.data,
          message:
            error?.response?.data.message ||
            error?.response?.data?.errors[0]?.message,
        });

        return {
          severity,
          status,
          message: message ? message : "Error",
          data,
        };
      }

      return {
        status: false,
        message: "Error",
        data: null,
        severity: "error",
      };
    }
  },

  getById: async (id: number) => {
    try {
      const { data } = await api.get<any>(`/AttendantRole/Get?id=${id}`);

      return {
        status: true,
        data: data,
        message: data.message,
      };
    } catch (error) {
      if (error instanceof AxiosError) {
        let { severity, status, message, data } = returnResponse({
          statusCode: error.response?.status,
          data: error?.response?.data,
          message: error?.response?.data?.errors[0]?.message,
        });

        return {
          severity,
          status,
          message: message ? message : "Error",
          data,
        };
      }

      return {
        status: false,
        message: "Error",
        data: null,
        severity: "error",
      };
    }
  },

  post: async (authToken: string | null, attendantRole: any) => {
    try {
      const body = {
        ...attendantRole,
      };
      const { data } = await api.post<any>(`/AttendantRole/Add`, body);

      return {
        status: true,
        data: data.data,
      };
    } catch (error) {
      if (error instanceof AxiosError) {
        let { severity, status, message, data } = returnResponse({
          statusCode: error.response?.status,
          data: error?.response?.data,
          message: error?.response?.data?.errors[0]?.message,
        });

        return {
          severity,
          status,
          message: message ? message : "Error",
          data,
        };
      }

      return {
        status: false,
        message: "Error",
        data: null,
        severity: "error",
      };
    }
  },

  put: async (authToken: string | null, attendantRole: any) => {
    try {
      const body = {
        ...attendantRole,
      };
      const { data } = await api.put<any>(`/AttendantRole/Update`, body);

      return {
        status: true,
        data: data,
      };
    } catch (error) {
      if (error instanceof AxiosError) {
        let { severity, status, message, data } = returnResponse({
          statusCode: error.response?.status,
          data: error?.response?.data,
          message: error?.response?.data?.errors[0]?.message,
        });

        return {
          severity,
          status,
          message: message ? message : "Error",
          data,
        };
      }
      let severity: "error" | "warning" | "success";
      severity = "error";
      return {
        status: false,
        message: "Error",
        data: null,
        severity: severity,
      };
    }
  },

  delete: async (authToken: string | null, attendantRoleId: number) => {
    try {
      const body = [
        {
          op: "replace",
          value: false,
          path: "active",
        },
      ];

      const { data } = await api.patch(
        `/AttendantRole/update?id=${attendantRoleId}`,
        body
      );

      return {
        status: true,
        data: data,
      };
    } catch (error) {
      if (error instanceof AxiosError) {
        let { severity, status, message, data } = returnResponse({
          statusCode: error.response?.status,
          data: error?.response?.data,
          message: error?.response?.data?.errors[0]?.message,
        });

        return {
          severity,
          status,
          message: message ? message : "Error",
          data,
        };
      }

      return {
        status: false,
        message: "Error",
        data: null,
        severity: "error",
      };
    }
  },
};
