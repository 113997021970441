import styled from 'styled-components';
import { stylesGlobalDefault } from '../../global/globalStyle';

export const Container = styled.div`
  width: 100%;
`;

export const DateContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 30px;
`;

export const EmptyList = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: -10px;
  font-family: ${stylesGlobalDefault.fontFamily};
  color: ${stylesGlobalDefault.gray};
  font-style: italic;
  font-size: 14px;
`;
