import { format } from "date-fns";
import React, { useEffect } from "react";
import { ProcedureItemType } from "../../pages/app/procedure/types";
import { HeaderProcedure } from "../HeaderProcedure";
import { ProcedureItem } from "../ProcedureItem";
import { Container, Empty } from "./styles";

export type IProcedureList = {
  data: Array<ProcedureItemType>;
  title?: string;
  currentIndex: number;
  setCurrentIndex: (index: number) => unknown;
  setModalHearing: (boolean: boolean) => unknown;
  addButtonClick?: () => unknown;
  deleteButtonClick?: () => unknown;
  setItemData: (data: any) => any | null;
  procedureNumber?: string;
  searchProcedureByFilters?: any;
  selectCurrentProcedure?: any;
  isFilter: boolean;
};

export const ProcedureList: React.FC<IProcedureList> = ({
  data = [],
  title,
  addButtonClick,
  deleteButtonClick,
  setCurrentIndex,
  currentIndex,
  setItemData,
  setModalHearing,
  isFilter,
  searchProcedureByFilters,
  selectCurrentProcedure,
}) => {
  const selectProcedure = (index: number, data: object) => {
    if (currentIndex !== index) {
      setCurrentIndex(index);
      setItemData(data);
      setModalHearing(false);
    } else {
      setCurrentIndex(-1);
      setItemData(null);
      setModalHearing(false);
    }
  };

  useEffect(() => {
    if (selectCurrentProcedure) {
      let index = null;
      let currentObj = null;

      data.map((val, i) => {
        if (val.procedureNumber == selectCurrentProcedure) {
          index = i;
          currentObj = val;
        }
      });

      if (currentObj && index != null && index >= 0) {
        setCurrentIndex(index);
        setItemData(currentObj);
        setModalHearing(false);
      }
    }
  }, [selectCurrentProcedure]);

  return (
    <>
      <HeaderProcedure
        title={title}
        isFilter={isFilter}
        AddButtonClick={addButtonClick}
        searchProcedureByFilters={searchProcedureByFilters}
        DeleteButtonClick={deleteButtonClick ? deleteButtonClick : undefined}
      />
      <Container>
        {data.length > 0 ? (
          data.map((item, index) => (
            <ProcedureItem
              onClick={() => {
                if(currentIndex !== index)
                  selectProcedure(index, item)}
            }
              key={item.id || index}
              selected={currentIndex === index || false}
              title={
                item.procedureNumber
                  ? item.procedureNumber
                  : `Sem titulo ${index + 1}`
              }
              description={
                item.procedureCustomerSourceName
                  ? item.procedureCustomerSourceName
                  : "Sem descrição"
              }
              date={format(new Date(item.expectedDate), "dd/MM/yyyy")}
              isAgended={true}
              statusProcedure={item.status}
            />
          ))
        ) : (
          <Empty>Nenhum procedimento cadastrado</Empty>
        )}
      </Container>
    </>
  );
};
