import { useState, useEffect } from "react";
import { InputDateGlobal } from "../../../components/InputDateGlobal";
import { DropdownGlobal, IDropDown } from "../../DropdownGlobal";
import {
  Container,
  ContainerBody,
  ContainerInputs,
} from "../../../pages/app/StyleCSS/bodyCreateEdit";

import api from "../../../services/api";
import { AttendantService } from "../../../services/attendant";
import { useToast } from "../../../hooks/toast";
import { CheckBoxConfidential } from "../../CheckBoxConfidetial";
import { useSelector } from "react-redux";
import { getValueFromlocalStorage } from "../../../utils/sessionStorageEncrypt";
import { DatePickerKenta } from "../../DatePicker";

interface HearingCreateEdit {
  attendantDropSelected: any;
  setAttendantDropSelected: (e: number | undefined) => unknown;
  hearingTypeDropSelected: any;
  setHearingTypeDropSelected: (e: number) => unknown;
  date: string;
  setDateSelected: (e: string) => unknown;
  time: string;
  setTimeSelected: (e: string) => unknown;
  procedureCustomerId: any;
  active: boolean;
  onChangeSwitch: (e: boolean) => unknown;
  itemDataHearings?: any;
  isEditHearing: boolean;
  editAttendantName?: string;
  editHearingTypeName?: string;
  isConfidential: boolean;
  policeChiefList: any[];
  setIsConfidential: (isConfidential: boolean) => unknown;
  setChief: (chief: any) => unknown;
  chief: any;
  errorFieldHearing: boolean;
  setDateClear: (e: boolean) => unknown;
  setTimeClear: (e: boolean) => unknown;
}

interface IAttedant {
  id?: number;
  name: string;
  value: string;
  key?: number;
  procedureCustomerAttendantId?: number;
}

export const CreateEditHearing = (props: HearingCreateEdit): JSX.Element => {
  const { procedureCustomerId } = props;
  const [dropDownHearingType, setDropDownHearingType] = useState<IDropDown[]>(
    []
  );
  const [dropDownAttendant, setDropDownAttendant] = useState<IAttedant[]>([]);

  //const [dateEdit, setDateEdit] = useState("");
  const [timeEdit, setTimeEdit] = useState("");
  const [showDefaultValue, setShowDefaultValue] = useState(true);
  const [placeHolder, setPlaceHolder] = useState(
    props.isEditHearing && props.editAttendantName && props.itemDataHearings
      ? `${props.editAttendantName} - ${props.itemDataHearings?.attendantRole}`
      : "Selecione"
  );
  const { addToast } = useToast();

  const user = getValueFromlocalStorage("@Kenta:user");
  const { isAdmin } = useSelector((state: any) => state.profile);

  useEffect(() => {
    if (props.isEditHearing) {
      const [date, time] = props.itemDataHearings.expectedDate.split("T");
      setTimeEdit(time);
    }
  }, []);

  //HearingType;
  useEffect(() => {
    (async () => {
      if (props) {
        if (user) {
          const user_parsed = JSON.parse(user);
          await api
            .get(
              `/HearingType/GetAllByCustomerId?customerId=${user_parsed.customerId}`
            )
            .then((response) => {
              const onlyActives = response.data.data.filter(
                (e: any) => e.active
              );
              setDropDownHearingType(onlyActives);
            })
            .catch((error) => console.log(error));
        }
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (user && props.hearingTypeDropSelected) {
        setShowDefaultValue(true);
        props.setAttendantDropSelected(undefined);
        setPlaceHolder("Carregando ...");
        const { body, success } = await AttendantService.getAttedantByType(
          procedureCustomerId.id,
          props.hearingTypeDropSelected
        );

        if (success) {
          if (!body.length) {
            addToast({
              type: "warning",
              title: "Aviso",
              description:
                "Este tipo de participante não foi vinculado à oitiva, selecione outro",
            });

            setPlaceHolder("Nenhum participante foi encontrado");
            props.setAttendantDropSelected(undefined);
            setShowDefaultValue(true);
            return setDropDownAttendant(body);
          }

          const list: any = [];

          body.forEach((element: any) => {
            list.push({
              ...element,
              name: `${element.name} - ${element.role}`,
            });
          });

          props.setAttendantDropSelected(undefined);
          setShowDefaultValue(false);
          return setDropDownAttendant(list);
        }
      }
    })();
  }, [props.hearingTypeDropSelected]);

  function changeTime(e: any) {
    if (e === "") {
      props.setTimeClear(true);
    } else {
      props.setTimeClear(false);
      props.setTimeSelected(e);
      setTimeEdit(e);
    }
  }

  return (
    <Container>
      <ContainerBody>
        <ContainerInputs>
          <DatePickerKenta
            error={!props.date && props.errorFieldHearing}
            value={props.date}
            onChange={(e) => props.setDateSelected(e)}
            label="Data de abertura"
            required={true}
            disabled={
              props.isEditHearing && props?.itemDataHearings?.status !== "A"
                ? true
                : false
            }
            limitToday={true}
          ></DatePickerKenta>

          <InputDateGlobal
            marginLeft={12}
            error={!props.time && props.errorFieldHearing}
            value={timeEdit}
            onChange={(e) => changeTime(e)}
            type="time"
            label="Horário"
            required={true}
            disabled={
              props.isEditHearing && props?.itemDataHearings?.status !== "A"
                ? true
                : false
            }
          />
        </ContainerInputs>
        <ContainerInputs>
          <DropdownGlobal
            error={!props.hearingTypeDropSelected && props.errorFieldHearing}
            editDropdown={props.editHearingTypeName}
            setSelected={props.setHearingTypeDropSelected}
            options={dropDownHearingType as any}
            labelName="Tipos"
            required={true}
            disabled={
              props.isEditHearing && props?.itemDataHearings?.status !== "A"
                ? true
                : false
            }
          />
        </ContainerInputs>
        <ContainerInputs>
          <DropdownGlobal
            error={!props.attendantDropSelected && props.errorFieldHearing}
            editDropdown={props.editAttendantName}
            setSelected={props.setAttendantDropSelected}
            isAttedantProcedure={true}
            showDefaultValue={showDefaultValue}
            defaultValue={placeHolder}
            options={dropDownAttendant as any}
            labelName="Participantes"
            disabled={
              (!props.hearingTypeDropSelected ||
              props?.itemDataHearings?.status !== "A"
                ? true
                : false) && props.isEditHearing
            }
            required={true}
          />
        </ContainerInputs>
        {isAdmin &&
          props.itemDataHearings &&
          props.itemDataHearings.isClassified && (
            <ContainerInputs>
              <DropdownGlobal
                error={!props.attendantDropSelected && props.errorFieldHearing}
                setSelected={props.setChief}
                isAttedantProcedure={false}
                options={props.policeChiefList}
                labelName="Delegados"
                disabled={!props.policeChiefList.length}
                required={true}
              />
            </ContainerInputs>
          )}
        <ContainerInputs>
          <CheckBoxConfidential
            value={props.isConfidential}
            label="Confidencial"
            checked={() => props.setIsConfidential(!props.isConfidential)}
          />
        </ContainerInputs>
      </ContainerBody>
    </Container>
  );
};
