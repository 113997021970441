import { userCreateUnitTypes } from "./actios.types";


export interface SetUserCreateUnit{
  type: userCreateUnitTypes.SET_USERCREATE_UNIT,
  payload: userCreateUnitTypes
}

export const setUserCreateUnit = (userCreateUnit: any): SetUserCreateUnit => ({
    type: userCreateUnitTypes.SET_USERCREATE_UNIT,
    payload: userCreateUnit
});


