import styled from "styled-components";
import { stylesGlobalDefault } from "../../../global/globalStyle";

export const Container = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
padding: 24px;
gap: 12px;
display: inline-block;
width: 240px;
height: 165px;
/* flex-basis: calc(33.33% - 20px);  */
/* Adjust this value as desired */
background: rgba(240, 202, 104, 0.24);
border-radius: 8px;
position: relative;
/* flex: none; */
order: 0;
align-self: stretch;
margin: 4px;

@media (max-width: 1280px) {
  width: 180px;
}
`;

export const Svg = styled.img`
  position: absolute;
  right: 8.33%;
  top: 12.5%;
  bottom: 12.5%;
`;

export const Title = styled.h3`
  width: 140px;
  height: 36px;
  font-family: ${stylesGlobalDefault.fontFamily};
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 125%;
  color: ${stylesGlobalDefault.black};

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
`;

export const Label = styled.span`
  width: 21px;
  height: 42px;
  font-family: ${stylesGlobalDefault.fontFamily};
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 42px;

  /* Neutral/Black */
  color: ${stylesGlobalDefault.black};

  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;
`;
