import styled, { css } from "styled-components";

interface IHearing {
  selected: boolean;
  disabled: boolean;
}

export const HearingListContainer = styled.div`
  width: 100% -48px;
  height: 100%;
  padding: 24px;
`;

export const Container = styled.div<IHearing>`
  height: 80px;
  width: 100% - 20px;
  display: flex;
  flex-direction: row;
  margin-bottom: 4px;
  align-items: center;
  justify-content: space-between;
  border: 2px solid #ecf0f3;
  background-color: #ecf0f3;
  padding: 4px 16px 4px 4px;
  border-radius: 8px;
  opacity: ${(props) => (props.disabled ? 0.6 : 1)};

  &:hover {
    border: 2px solid #1d9bf0;
    opacity: ${(props) => (props.disabled ? 0.6 : 1)};
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  }

  ${(props) =>
    props.selected &&
    css`
      background: #e4f1fa;
      border: 2px solid #1d9bf0;
    `}
`;

export const ContainerDateText = styled.div`
  width: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const TextArea = styled.div`
  width: 100%;
  //background-color: blue;
`;

export const ImgDiv = styled.div`
  padding-left: 3%;
  padding-right: 3%;
`;

export const Title = styled.div`
  font-family: "Inter", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 125%;
  color: #333;
`;

export const Description = styled.div`
  font-family: "Inter", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  color: #666;
  margin-top: 4px;
`;

export const InfoArea = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  svg {
    font-size: 30px;
    cursor: pointer;
    color: #666;
    margin-right: 10px;

    &:hover {
      color: #3b3b3b;
    }
  }
`;

export const DateLabel = styled.div`
  font-family: "Inter", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
`;

export const DateBlackDiv = styled.div`
  padding: 4px 8px;
  background: black;
  border-radius: 7px;
  margin: 25px;
`;

export const DateBlackSpan = styled.div`
  font-family: "Inter", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #fff;
`;

export const EmptyHearing = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 16px;
  color: #b2b2b2;
  margin-top: 24px;
  font-family: "Inter Tight", Arial, Helvetica, sans-serif;
  align-items: center;
  justify-content: center;
`;
