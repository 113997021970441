import React, { useState, Fragment, useEffect } from "react";
import Webcam from "react-webcam";
import CameraIcon from "../../assets/svg/camera-arquivos.svg";
import {
  BoxWebCanImage,
  ButtonTakePhoto,
  WebCanContainer,
  avatarStyle,
} from "./styles";
import { useToast } from "../../hooks/toast";
import ModalSelectOrTakePicture from "../ModalSelectOrTakePicture";
import { useSelector } from "react-redux";
import { storageBaseUrl } from "../../services/api";
import Avatar from "react-avatar";
import { getValueFromlocalStorage } from "../../utils/sessionStorageEncrypt";

const videoConstraints = {
  width: 1280,
  height: 720,
  facingMode: "user",
};

interface IPhotoWebCam {
  img: any;
  setImg: (value: any) => unknown;
  setFileImg: (value: File) => unknown;
}

const PhotoWebCam = (props: IPhotoWebCam) => {
  const { addToast } = useToast();
  const [showMyPicture, setShowMyPicture] = useState(true);
  const [showModalPicture, setShowModalPicture] = useState(false);
  const [preview, setPreview] = useState("");
  const [userItem, setUserItem] = useState<any>();

  const webcamRef = React.useRef<any>(null);

  const { policeUnit } = useSelector((state: any) => state.policeUnit);
  const { user } = useSelector((state: any) => state.user);
  const userStorage = getValueFromlocalStorage("@Kenta:user");

  useEffect(() => {
    if (userStorage) {
      const passedUser = JSON.parse(userStorage);
      setUserItem(passedUser);
    }
  }, []);

  const capture = React.useCallback(() => {
    const imageSrc = webcamRef?.current?.getScreenshot();

    if (!webcamRef?.current?.state?.hasUserMedia) {
      addToast({
        type: "warning",
        title: "Aviso",
        description: "Camera não encontrada",
      });
      return;
    }

    try {
      const canvas = document.createElement("canvas");
      const image = new Image();

      image.onload = function () {
        canvas.width = image.width;
        canvas.height = image.height;

        const context = canvas.getContext("2d");
        context?.drawImage(image, 0, 0, image.width, image.height);

        canvas.toBlob(async (blob) => {
          if (blob) {
            const file = new File([blob], "capturaImage.png", {
              type: "image/png",
            });

            props.setFileImg(file);
          }
        }, "image/png");
      };
      image.src = imageSrc;
    } catch (err) {
      console.log("err", err);
    }
    props.setImg(imageSrc);
  }, [webcamRef]);

  const updateFotoByComputer = (file: File) => {
    if (!file) {
      return;
    }

    const objectUrl = URL.createObjectURL(file);
    props.setImg(file);
    props.setFileImg(file);

    setPreview(objectUrl);
  };

  return (
    <>
      <BoxWebCanImage>
        {showMyPicture ? (
          <Fragment>
            <WebCanContainer>
              {props.img ? (
                <img
                  src={
                    props.img
                      ? preview
                      : `${storageBaseUrl}/${policeUnit.containerName}/${user.picture}`
                  }
                  alt=""
                  style={{
                    height: "150px",
                    width: "150px",
                    borderRadius: "50%",
                    objectFit: "cover",
                  }}
                />
              ) : userItem && userItem.picture ? (
                <img
                  src={
                    props.img
                      ? preview
                      : `${storageBaseUrl}/${policeUnit.containerName}/${user.picture}`
                  }
                  alt=""
                  style={{
                    height: "150px",
                    width: "150px",
                    borderRadius: "50%",
                    objectFit: "cover",
                  }}
                />
              ) : (
                <Avatar
                  name={userItem ? userItem.name : "Avatar"}
                  size="150"
                  round={true}
                  style={{
                    height: "150px",
                    width: "150px",
                    borderRadius: "50%",
                    objectFit: "cover",
                  }}
                />
              )}
              <ButtonTakePhoto>
                <img
                  src={CameraIcon}
                  alt=""
                  onClick={() => setShowModalPicture(!showModalPicture)}
                  style={{
                    height: "40px",
                    backgroundColor: "white",
                    borderRadius: "50px",
                  }}
                />
                {showModalPicture && (
                  <ModalSelectOrTakePicture
                    takePicture={() => setShowMyPicture(!showMyPicture)}
                    onClose={() => setShowModalPicture(false)}
                    setFileImg={updateFotoByComputer}
                  />
                )}
              </ButtonTakePhoto>
            </WebCanContainer>
          </Fragment>
        ) : props.img !== "" ? (
          <>
            <WebCanContainer>
              <img
                src={props.img}
                alt=""
                style={{
                  height: "150px",
                  width: "150px",
                  borderRadius: "50%",
                  objectFit: "cover",
                }}
              />
            </WebCanContainer>
            <ButtonTakePhoto>
              <img
                src={CameraIcon}
                alt=""
                onClick={() => props.setImg("")}
                style={{
                  height: "40px",
                  backgroundColor: "white",
                  borderRadius: "50px",
                }}
              />
            </ButtonTakePhoto>
          </>
        ) : (
          <>
            <WebCanContainer>
              <Webcam
                audio={false}
                height={"150px"}
                ref={webcamRef}
                screenshotQuality={1024}
                screenshotFormat="image/jpeg"
                width={"150px"}
                videoConstraints={videoConstraints}
                style={{ borderRadius: "50%", objectFit: "cover" }}
              />
            </WebCanContainer>
            <ButtonTakePhoto>
              <img
                src={CameraIcon}
                alt=""
                onClick={capture}
                style={{
                  height: "40px",
                  backgroundColor: "white",
                  borderRadius: "50px",
                }}
              />
            </ButtonTakePhoto>
          </>
        )}
      </BoxWebCanImage>
    </>
  );
};
export default PhotoWebCam;
