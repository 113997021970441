import styled from "styled-components";
import { stylesGlobalDefault } from "../../../global/globalStyle";

export const Container = styled.div`
    width: 49%;
    height: 100%;
    background-color: #f5f5f5;
    border-radius: 8px;
    margin: 8px 8px 8px 8px;
    
`;

export const ContainerChart = styled.div`
  width: 100%;
  height: 30vh;
  /* height: 278px; */
  display: flex;
  flex-direction: row;
  padding-left: 20px;
  border-radius: 8px;
  padding-top: 20px;
  bottom: 0px;
  justify-content: center;
  align-items: center;
`;

export const ContainerTitle = styled.div`
  width: 100%;
  height: 6%;
  display: flex;
  flex-direction: row;
  padding-left: 20px;
  padding-top: 8px;
  margin: 4px;
  /* position: absolute; */
`;

export const TitleOp = styled.label`
  font-size: 16px;
  text-anchor: middle;
`;