import {
  SET_ATTACHMENT_LIST,
  ADD_NEW_ATTACHMENT,
  ADD_ATTACHMENT_UPLOADED,
  CLEAN_ATTACHMENT_UPLOADS,
} from "../actions/actios.types";
import { IUpload } from "../interface";

const INITIAL_STATE: any = {
  uploadList: [] as Array<IUpload>,
  attachmentList: [],
  uploadedList: [] as Array<IUpload>,
};

const attachment = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case ADD_NEW_ATTACHMENT:
      return {
        ...state,
        uploadList: [...state.uploadList, action.attachment],
      };
    case SET_ATTACHMENT_LIST:
      return {
        ...state,
        attachmentList: action.attachmentList,
      };
    case ADD_ATTACHMENT_UPLOADED:
      return {
        ...state,
        uploadedList: [...state.uploadedList, action.attachment],
      };
    case CLEAN_ATTACHMENT_UPLOADS:
      return {
        ...state,
        uploadList: [],
        uploadedList: [],
      };
    default:
      return state;
  }
};

export default attachment;
