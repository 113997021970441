import styled from 'styled-components';
import { stylesGlobalDefault } from '../../global/globalStyle';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  background:${ stylesGlobalDefault.orange };
  border-radius: 50%;
`;

export const Value = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${ stylesGlobalDefault.fontFamily };
  color: ${ stylesGlobalDefault.white };

  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
`;
