import styled, { css } from "styled-components";
import { stylesGlobalDefault } from "../../global/globalStyle";

interface IProps {
  widthHeight?: number;
}

export const Container = styled.button<IProps>`
  width: ${(props) => (props.widthHeight ? props.widthHeight + "px" : "40px")};
  height: ${(props) => (props.widthHeight ? props.widthHeight + "px" : "40px")};
  background-color: ${stylesGlobalDefault.blackMenu1};
  border-radius: 50%;
  border: 2px ${stylesGlobalDefault.blue} solid;
  cursor: pointer;

  ${(props) =>
    props.disabled
      ? css`
          border-color: #7887a9;
          cursor: default;
        `
      : css`
          &:hover {
            border-color: ${stylesGlobalDefault.white};
            background-color: ${stylesGlobalDefault.blueDark};
            background: ${stylesGlobalDefault.blueDark};
          }
        `}
`;

export const PlusIcon = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${stylesGlobalDefault.white};
  font-size: 20px;
`;
