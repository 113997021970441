import React from "react";
import { useNavigate } from "react-router-dom";
import backArrowIcon from "../../assets/hearingDetails/arrow-back.svg";

import {
  HeaderContainer,
  ProcedureHeaderContainer,
  BackButton,
  TitleContainer,
  Title,
  SubTitle,
  AttendantHeaderContainer,
  AttendantContainer,
} from "./styles";

type IHeaderHearing = {
  hearingItemDto: any;
  procedureItem: any;
};

export const HeaderHearing: React.FC<IHeaderHearing> = ({
  hearingItemDto,
  procedureItem,
}) => {
  const navigate = useNavigate();
  
  return (
    <HeaderContainer>
      <ProcedureHeaderContainer>
        <BackButton>
          <img onClick={() => navigate(`/dashboard/procedure?procedureId=${procedureItem.procedureNumber}&hearingId=${hearingItemDto.id}`)} src={backArrowIcon} alt="" />
        </BackButton>
        <TitleContainer>
          <Title>
            {procedureItem
              ? "Procedimento " + procedureItem.procedureNumber
              : "Carregando..."}
          </Title>
          <SubTitle>
            {procedureItem ? procedureItem.procedureCustomerSourceName : ""}
          </SubTitle>
        </TitleContainer>
      </ProcedureHeaderContainer>
      <AttendantHeaderContainer>
        <AttendantContainer>
          <TitleContainer>
            <Title>
              {hearingItemDto ? hearingItemDto.attendantName : "Carregando..."}
            </Title>
            <SubTitle>
              {hearingItemDto ? hearingItemDto.attendantRole : "Carregando..."}
            </SubTitle>
          </TitleContainer>
        </AttendantContainer>
      </AttendantHeaderContainer>
    </HeaderContainer>
  );
};
