import { Container, ContainerChart, ContainerTitle, Label } from './styles';
import { ReactElement } from 'react';


interface ChartProps {
    title: string,
    children: ReactElement,
    styleContainer?: any
}



export function ChartContainer({ title, children, styleContainer }: ChartProps) {

  return (
    <Container style={styleContainer}>
      <ContainerTitle>
        <Label>{title}</Label>
      </ContainerTitle>
      <ContainerChart>
        {children}
      </ContainerChart>
    </Container>
  );
};
