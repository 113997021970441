import styled, { css } from 'styled-components';
import { ButtonProps } from '.';

const COLOR = {
  primary: css`
    color: #fff;
    background: #e98d0b;
  `,
  secondary: css`
    color: #000;
    background: #fff;
  `,
  third: css`
    color: #1d9bf0;
    background: #fff;
    border: 2px solid;
  `,
  forth: css`
    color: #fff;
    background: #1d9bf0;
    border: none;
  `,
  fifth: css`
    color: #fff;
    background: #3e424e;
    border: 2px solid #1d9bf0;
  `,
};

const DISABLED = css`
  cursor: not-allowed;
  background: #d4d4d4;
  color: #f5f5f5;
`;

export const Container =
  styled.button <
  ButtonProps >
  `
  padding: 10px 15px;
  cursor: pointer;
  border: none;
  border-radius: 50px;
  outline: none;
  transition: all 0.2s;
  text-transform: uppercase;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  
  ${({ color }) =>
  color === 'secondary'
    ? css`
        &:hover {
          background: #ECF0F3
        }
      `
    : css`
        
      `}

  ${(props) => props.color && COLOR[props.color]}
  ${(props) => props.disabled && DISABLED}
`;
