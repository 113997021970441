import styled, { keyframes } from "styled-components";
import { stylesGlobalDefault } from "../../global/globalStyle";

export const appearFromLeft = keyframes`
  from {
    opacity: 0;
    position: absolute;
    transform: translateX(125%);
  }
  to{
    opacity: 1;
    position: relative;
    transform: translateX(0);
  }
`;

export const Container = styled.div`
  width: 260px;
  height: 95vh;
  display: flex;
  flex-direction: column;
  padding: 24px;
  background-color: ${stylesGlobalDefault.white};
  animation: ${appearFromLeft} 0.5s;
`;

export const styleImage = {
  width: 14,
  height: 14,
  cursor: "pointer",
};

export const avatarStyle = {
  marginBottom: 8,
};

export const Separator = styled.hr`
  width: 100%;
  margin: 24px 0;
  color: #d1dbe3;
`;

export const ContainerHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 40px;
`;

export const ContainerClose = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  height: 15px;
`;

export const ContainerMenu = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TitleHeader = styled.span`
  font-family: ${stylesGlobalDefault.fontFamily};
  color: ${stylesGlobalDefault.titleNameColor};
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  margin: 8px 0;
`;

export const Subtitle = styled.span`
  font-family: ${stylesGlobalDefault.fontFamily};
  color: ${stylesGlobalDefault.subTitleNameColor};
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
`;

export const Label = styled.label`
  font-family: ${stylesGlobalDefault.fontFamily};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${stylesGlobalDefault.LabelColor};
`;

export const LabelLink = styled.a`
  font-family: ${stylesGlobalDefault.fontFamily};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${stylesGlobalDefault.LabelColor};
  text-decoration: none;
  margin-bottom: 24px;
`;

export const TitleMenu = styled.span`
  font-family: ${stylesGlobalDefault.fontFamily};
  text-transform: uppercase;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  margin-bottom: 24px;
  color: ${stylesGlobalDefault.titleMenuColor};
`;

export const VersionContainer = styled.div`
  display: flex;
  position: absolute;
  align-self: center;
  align-items: center;
  bottom: 40px;
  font-family: ${stylesGlobalDefault.fontFamily};
  font-style: normal;
  font-weight: 700;
  flex-direction: column;

  img {
    width: 100px;
    margin-bottom: 4px;
    opacity: 0.8;
  }
`;

export const customStyles = {
  content: {
    border: "none",
    backgroundColor: "rgba(35, 35, 35, -0.25)",
    inset: "auto",
    opacity: 1,
    padding: 0,
    top: 0,
    left: 0,
    borderRadius: 0,
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    zIndex: 999,
    // position: 'initial',
  },
  overlay: {
    backgroundColor: "rgba(35, 35, 35, 0.75)",
    display: "flex",
    justifyContent: "flex-end",
    zIndex: 999,
  },
};
