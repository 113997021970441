import { Permission } from "../../../../utils/entities/permission";
import { PermissionTypes } from "../actions/actios.types";

interface PermissionState {
  permissions: Permission[] | null;
}

const initialState: PermissionState = {
  permissions: [],
};

const permissionReducer = (
  state = initialState,
  action: any,
): PermissionState => {
  switch (action.type) {
    case PermissionTypes.SET_PERMISSION:
      return {
        ...state,
        permissions: action.payload,
      };
    default:
      return state;
  }
};
export default permissionReducer;
