import api from "../api";
import { ICustomerTemplate } from "./interface";

interface ICreateTemplate {
  id?: number;
  customerId: number;
  type: number;
  title: string;
  description: string;
  template: string;
  keywords: string;
  active: boolean | null;
}

interface IKeywords {
  hearingId: number;
  customerTemplateId: number;
}

export const customerTemplateService = {
  getAllByCustomerId: async ({
    customerId,
    active,
    type,
  }: ICustomerTemplate) => {
    try {
      if (active !== null && !type) {
        const { data } = await api.get(
          `/CustomerTemplate/GetAllByCustomerId?customerId=${customerId}&active=${active}`
        );
        return {
          success: true,
          message: "",
          body: data.data,
        };
      }

      if (active === null && !type) {
        const { data } = await api.get(
          `/CustomerTemplate/GetAllByCustomerId?customerId=${customerId}`
        );

        return {
          success: true,
          message: "",
          body: data.data,
        };
      }

      if (active && type) {
        const { data } = await api.get(
          `/CustomerTemplate/GetAllByCustomerId?customerId=${customerId}&active=${active}&type=${type}`
        );
        return {
          success: true,
          message: "",
          body: data.data,
        };
      }

      return {
        success: true,
        message: "",
        body: [],
      };
    } catch (error: any) {
      return {
        success: false,
        message: error.response?.data?.message,
        body: null,
      };
    }
  },
  createTemplate: async (params: ICreateTemplate) => {
    try {
      const { data } = await api.post("/CustomerTemplate/Add", params);

      return {
        success: true,
        body: data,
        message: data.message,
      };
    } catch (error: any) {
      return {
        success: false,
        body: {},
        message:
          error?.response?.data.message ||
          error?.response?.data?.errors[0]?.message,
      };
    }
  },
  updateTemplate: async (param: ICreateTemplate) => {
    try {
      const { data } = await api.put("/CustomerTemplate/Update", param);

      return {
        success: true,
        body: data.data,
        message: data?.message,
      };
    } catch (error: any) {
      return {
        success: false,
        body: {},
        message: error?.response?.data?.message,
      };
    }
  },
  SetKeywords: async ({ hearingId, customerTemplateId }: IKeywords) => {
    try {
      const { data } = await api.get(
        `/CustomerTemplate/SetKeywords?hearingId=${hearingId}&customerTemplateId=${customerTemplateId}`
      );

      return {
        success: true,
        body: data.data,
        message: "",
      };
    } catch (error: any) {
      return {
        success: false,
        body: {},
        message: error?.response?.data?.message,
      };
    }
  },
};
