import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 98vh;
  overflow-y: hidden;

  @media screen and (min-height: 850px) {
    height: 100vh;
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 98vh;

  @media screen and (min-height: 850px) {
    height: 100vh;
  }
`;
