import React from "react";
import { Container, ButtonContainer } from "./styles";

//Toast
import { useToast } from "../../hooks/toast";

interface IModalSelectOrTakePicture {
  takePicture: () => unknown;
  onClose: () => unknown;
  setFileImg: (file: any) => unknown;
}

const ModalSelectOrTakePicture: React.FC<IModalSelectOrTakePicture> = ({
  takePicture,
  setFileImg,
  onClose,
}) => {
  const { addToast } = useToast();

  return (
    <Container>
      <ButtonContainer onClick={takePicture}>
        <span>Tirar foto</span>
      </ButtonContainer>
      <ButtonContainer>
        <span>Enviar do computador</span>
        <input
          onChange={(e) => {
            onClose();
            if (e.target.files && e.target.files[0].size < 500000) {
              setFileImg(e.target.files[0]);
            } else {
              addToast({
                type: "warning",
                title: "Foto",
                description: "Tamanho da foto deve ser inferior a 500 KB",
              });
            }
          }}
          type="file"
        />
      </ButtonContainer>
    </Container>
  );
};

export default ModalSelectOrTakePicture;
