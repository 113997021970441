import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  DropDownContainer,
  DropDownHeader,
  DropDownList,
  DropDownListContainer,
  ListItem,
  Content,
} from "./style";
import ArrowDown from "../../assets/svg/arrow-down.svg";
import ArrowUp from "../../assets/svg/arrow-up.svg";
import checkDrop from "../../assets/svg/check_drop.svg";
import { UserCustomerProfile } from "../../services/user_customer_profile/types";
import { useDispatch } from "react-redux";
import { setPoliceUnit } from "../../store/modules/policeUnit/actions";
import { useSelector } from "react-redux";
import { PermissionsService } from "../../services/permissions";
import { setPermissions } from "../../store/modules/permission/actions";
import { hideLoading, showLoading } from "../../store/modules/login/actions";
import { UserCustomerProfileService } from "../../services/user_customer_profile";
import { setIsAdmin } from "../../store/modules/profile/actions";
import {
  deleteFromlocalStorage,
  getValueFromlocalStorage,
  insertTolocalStorage,
} from "../../utils/sessionStorageEncrypt";
import { CustomerCertificateService } from "../../services/customerCertificate";
import { useToast } from "../../hooks/toast";
import { useNavigate } from "react-router-dom";


export const SelecionarUnidade = (props: {
  unidades: UserCustomerProfile[];
  record?: boolean;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  // const [selectedOption, setSelectedOption] = useState<UserCustomerProfile | null>(
  //   props.unidades[0]
  // );
  const dispatch = useDispatch<any>();
  const toggling = () => setIsOpen(!isOpen);
  const { policeUnit } = useSelector((state: any) => state.policeUnit);
  const { addToast } = useToast();
  const navigate = useNavigate();
  const statusRef = useRef<any>(null);

  function onOptionClicked(value: UserCustomerProfile) {
    setIsOpen(false);
    // dispatch(showLoading())
    if (
      value.profileId !== policeUnit.profileId ||
      value.unitId !== policeUnit.unitId
    ) {
      dispatch(setPermissions([]));
      dispatch(setPoliceUnit(value));
      navigate(`/dashboard/procedure`, {
        state: {},
      });
      // Promise.resolve(
      //   updatePermissions(value.customerId, value.profileId, value.unitId)
      // );
    }
  }

  const updatePermissions = async (
    customerId: number,
    userCustomerProfile: number,
    unitId: number
  ) => {
    dispatch(showLoading());
    const authToken = getValueFromlocalStorage("@Kenta:access_Token");
    const user = getValueFromlocalStorage("@Kenta:user");
    if (authToken) {
      const { data } = await PermissionsService.getPermissions(
        authToken,
        customerId,
        userCustomerProfile
      );

      if (data && data.length > 0) {
        dispatch(setPermissions(data));

        if (user) {
          const parsedUser = JSON.parse(user);
          const { success, body } =
            await UserCustomerProfileService.getPermissionToSeeIfHasPermissionClassified(
              parsedUser.id,
              userCustomerProfile,
              unitId
            );

          const isAdmin: boolean = Boolean(body);

          if (success) {
            dispatch(setIsAdmin(isAdmin));
          }
        }
      } else {
        dispatch(setPermissions([]));

        if (user) {
          const parsedUser = JSON.parse(user);
          const { success, body } =
            await UserCustomerProfileService.getPermissionToSeeIfHasPermissionClassified(
              parsedUser.id,
              userCustomerProfile,
              unitId
            );

          const isAdmin: boolean = Boolean(body);

          if (success) {
            dispatch(setIsAdmin(isAdmin));
          }
        }
      }

      // dispatch(hideLoading())
    }
  };

  useEffect(() => {
    if (!policeUnit && props.unidades && props.unidades.length > 0) {
      let indexSelected = localStorage.getItem("IndexProfileSelected");

      if (indexSelected) {
        dispatch(setPoliceUnit(props.unidades[Number(indexSelected)]));
      } else {
        dispatch(setPoliceUnit(props.unidades[0]));
      }
    }
  }, []);

  useEffect(() => {
    const handleDocumentClick = (e: any) => {
      if (statusRef.current && !statusRef.current.contains(e.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, [setIsOpen, statusRef]);

  return (
    <Container ref={statusRef}>
      <Content
        onClick={
          props.record
            ? () =>
                addToast({
                  type: "warning",
                  title: "Aviso",
                  description: "Esta ação está indisponível nesta tela",
                })
            : toggling
        }
      >
        <DropDownContainer>
          <DropDownHeader>
            <strong>
              {policeUnit
                ? policeUnit.policeUnit + " - " + policeUnit.profile
                : ""}
            </strong>
          </DropDownHeader>
          {isOpen && (
            <DropDownListContainer>
              <DropDownList>
                {props.unidades.map((option, index: number) => (
                  <ListItem
                    isSelected={
                      option.profileId === policeUnit?.profileId &&
                      option.policeUnit === policeUnit.policeUnit
                    }
                    onClick={() => {
                      localStorage.setItem(
                        "IndexProfileSelected",
                        String(index)
                      );
                      onOptionClicked(option);
                    }}
                    key={String(Math.random()) + option}
                  >
                    {option.policeUnit + " - " + option.profile}

                    {option.profileId === policeUnit?.profileId &&
                      option.policeUnit === policeUnit.policeUnit && (
                        <img
                          className="icon"
                          src={checkDrop}
                          onClick={toggling}
                          style={{ cursor: "pointer" }}
                          alt=""
                        />
                      )}
                  </ListItem>
                ))}
              </DropDownList>
            </DropDownListContainer>
          )}
        </DropDownContainer>
        <div>
          <img
            className="icon"
            src={isOpen ? ArrowUp : ArrowDown}
            style={{ cursor: "pointer" }}
            alt=""
          />
        </div>
      </Content>
    </Container>
  );
};
