import api from "../api";
import { AxiosError } from "axios";
import { returnResponse } from "../utils";
import { listOperation } from "../../utils/listsOperations";

export interface systemObjectsSubMenus {
  id: number;
  title: string;
  name?: string;
  description?: string;
  hasLineBottom: boolean;
  list: Array<systemObject>;
}

export interface systemObject {
  id: number;
  name: string;
  description: string;
  url: string;
  navigate: string;
  active: boolean;
  permissions: any;
}

export const SystemObjectService = {
  getAll: async () => {
    try {
      const data = await api.get<Array<systemObject>>(`/SystemObject/GetAll`);
      //makeMenu(data.data);

      //console.log("data api", data)

      return {
        status: true,
        data: data.data,
        //message: data.message,
      };
    } catch (error) {
      console.log("error", error);
      if (error instanceof AxiosError) {
        let { severity, status, message, data } = returnResponse({
          statusCode: error.response?.status,
          data: error?.response?.data,
          message: error?.response?.data?.errors[0]?.message,
        });

        return {
          severity,
          status,
          message: message ? message : "Error",
          data,
        };
      }

      return {
        status: false,
        message: "Error",
        data: null,
        severity: "error",
      };
    }
  },
};

async function makeMenu(menus: any) {
  let menu: Array<systemObjectsSubMenus> = [];
  if (menus) {
    let orderByMenu = listOperation.groupBy(
      menus.data,
      (p: systemObject) => p.url
    );
    const keys = Object.keys(orderByMenu);

    for (let i = 0; i < keys.length; ++i) {
      const key = keys[i];
      let urlSplit = key.split("/");

      let titleMenu: systemObjectsSubMenus = {
        id: i + 1,
        hasLineBottom: false,
        title: urlSplit[3],
        list: [],
      };

      orderByMenu[key].forEach((element: systemObject) => {
        titleMenu.list.push({
          active: element.active,
          description: element.description,
          name: element.name,
          id: element.id,
          navigate: `/${urlSplit[1]}/${urlSplit[2]}/${element.name}`,
          url: element.url,
          permissions: [],
        });
      });
      menu.push(titleMenu);
    }
    //console.log("menu final", menu);
  }
  return menu;
}
