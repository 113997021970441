import React, { Fragment, useEffect, useState } from "react";
import {
  Container,
  AttachmentEmpty,
  AttachmentContainer,
  AttachmentIconContainer,
  ContainerText,
  LeftContainer,
  Title,
  Description,
  RightContainer,
  AttachmentListContainer,
} from "./styles";
import { TitleSidebar } from "../../components/TitleSidebar";
import ModalRight from "../../components/ModalRight";
import { AttachmentChildrenModal } from "../AttachmentChildrenModal";
import { hearingFileService } from "../../services/hearingFile";
import { useQuery } from "../../utils/get";
import fileIcon from "../../assets/hearingDetails/file.svg";
import { convertType, formatYmd } from "../../utils/format";
import edit from "../../assets/hearingDetails/edit_attachment.svg";
import IconDelete from "../../assets/svg/IconDelete.svg";
import { useDispatch, useSelector } from "react-redux";
import { useToast } from "../../hooks/toast";
import { hideLoading, showLoading } from "../../store/modules/login/actions";
import { ModalConfirmation } from "../../components/ModalConfirmation";
import { Tooltip } from "react-tooltip";
import { v4 } from "uuid";
import moment from "moment";
import { LogOperation } from "../../utils/entities/logOperation";
import {
  addNewAttachment,
  setAttachmentList,
} from "../../store/modules/attachment/actions";
import { IUpload } from "../../store/modules/attachment/interface";
import uuid from "react-uuid";

interface IAttachmentComponent {
  openModal: boolean;
  setOpenModal: (e: boolean) => unknown;
}

const AttachmentComponent: React.FC<IAttachmentComponent> = ({
  setOpenModal,
  openModal,
}) => {
  const [showAttachment, setShowAttachment] = useState(false);
  const { attachmentList } = useSelector((state: any) => state.attachment);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [name, setName] = useState<string>("");
  const [type, setType] = useState<any>(null);
  const [file, setFile] = useState<any | null>(null);
  const [isEdit, setEdit] = useState(false);
  const [editItem, setEditItem] = useState<any>(null);
  const [typeError, setTypeError] = useState<boolean>(false);
  const [nameError, setNameError] = useState<boolean>(false);
  const [fileError, setFileError] = useState<boolean>(false);

  const { addToast } = useToast();
  const query = useQuery();
  const hearingId = query.get("hearingId");
  const dispatch = useDispatch();

  const { policeUnit } = useSelector((state: any) => state.policeUnit);

  const unit = policeUnit;

  const container = unit ? unit.containerName : "santacatarina";
  const customerId = unit ? unit.customerId : 1;

  const closeModal = () => {
    setEditItem(null);
    setTypeError(false);
    setNameError(false);
    setFileError(false);
    setOpenModal(false);
    setOpenDeleteModal(false);
    setName("");
    setEdit(false);
    setType(null);
    setFile(null);
  };

  const getAttachmentList = async () => {
    dispatch(showLoading());
    const { success, body } = await hearingFileService.getAll(
      hearingId ? hearingId : ""
    );

    if (success) {
      const list: any = [];

      body.forEach((element: any) => {
        if (element.type !== 1) {
          list.push(element);
        }
      });

      dispatch(setAttachmentList(list));
      dispatch(hideLoading());
    }
  };

  const handleSaveAttachment = async () => {
    try {
      if (!type || !file || !name.trim()) {
        if (!type) {
          setTypeError(true);
        }

        if (!file) {
          setFileError(true);
        }

        if (!name.trim()) {
          setName(name.trim());
          setNameError(true);
        }

        return addToast({
          type: "warning",
          title: "Preencha todos os campos",
          description:
            "Para salvar um anexo é necessário preencher todos os campos",
        });
      }

      let typeData = "";
      let typeDataAudio = "";
      const date = new Date();
      const folder = type.name
        .normalize("NFD")
        .replace(/[^a-zA-Z\s]/g, "")
        .toLocaleLowerCase();

      if (folder === "documento") {
        typeData = "application";
      } else if (folder === "foto") {
        typeData = "image";
      } else if (folder === "audio" && file.type.includes("video/mp4")) {
        typeData = "video";
      } else {
        typeData = folder;
      }

      const extension = file.name.match(/\.[a-z0-9A-Z]{2,4}/i);

      const filename = `${v4()}_${moment().format(
        "DDMMYYYYHHmmss"
      )}${extension}`;

      if (
        (file && !file.type.includes(typeData)) ||
        (file && !file.type.includes(typeDataAudio))
      ) {
        addToast({
          type: "warning",
          title: "Tipo de arquivo inválido",
          description: "Adicione um tipo de arquivo válido",
        });

        setTypeError(true);
        return;
      }

      dispatch(showLoading());
      setOpenModal(false);
      const payload = {
        type: type.id,
        title: name,
        filename: `inqueritodigitalssppc\\${date.getFullYear()}\\unidade_${customerId}\\${folder}\\${filename}`,
        duration: 0,
        fileOrder: 0,
        size: file.size,
        hash: v4(),
        createIn: formatYmd(new Date()),
        updateIn: formatYmd(new Date()),
        upload: true,
        device: LogOperation.getDevice(),
        ip: LogOperation.getMachineIP(),
        logPoliceUnitId: LogOperation.getPoliceUnitId(),
        logUserId: LogOperation.getUserId(),
      };

      const fileItem = new FormData();
      fileItem.append("content", file, payload.filename);

      const uploadBody: IUpload = {
        id: payload.filename,
        hearingId: hearingId ? hearingId : "0",
        file: fileItem,
        finished: false,
        payload: payload,
        containerName: unit.containerName,
      };

      dispatch(addNewAttachment(uploadBody));

      dispatch(hideLoading());
      closeModal();
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleDeleteAttachment = async () => {
    dispatch(showLoading());
    let idFileDeleted = editItem.id;
    const { success } = await hearingFileService.delete(editItem.id);

    if (success && editItem) {
      closeModal();
      const filterAtachment = attachmentList.filter(
        (x: any) => x.id !== editItem.id
      );

      dispatch(setAttachmentList(filterAtachment));
      await LogOperation.postLogOperation("HearingFile", 3, idFileDeleted);
      addToast({
        type: "success",
        title: "Anexo",
        description: "Seu anexo foi excluído com sucesso",
      });
    }

    dispatch(hideLoading());
  };

  const handleOpenDeleteModal = async () => {
    setOpenModal(false);
    setOpenDeleteModal(true);
  };

  const handleEditAttachment = async () => {
    if (!name.trim()) {
      setName("");
      setNameError(true);
      return addToast({
        type: "warning",
        title: "Editar Anexo",
        description: "Preencha os campos destacados",
      });
    }
    setNameError(false);
    dispatch(showLoading());
    await hearingFileService.patch(name, editItem.id);
    await getAttachmentList();
    setOpenModal(false);
    closeModal();

    return addToast({
      type: "success",
      title: "Anexo",
      description: "Anexo atualizado com sucesso",
    });
  };

  const onEditItem = (item: any) => {
    setEditItem(item);
    setName(item.title);
    setType(item.type);
    setEdit(true);
    setOpenModal(true);
  };

  useEffect(() => {
    (async () => {
      await getAttachmentList();
    })();
  }, []);

  function formatFileSize(bytes: number) {
    if (bytes < 1024) {
      return bytes + " bytes";
    } else if (bytes < 1024 * 1024) {
      return (bytes / 1024).toFixed(2) + " KB";
    } else if (bytes < 1024 * 1024 * 1024) {
      return (bytes / (1024 * 1024)).toFixed(2) + " MB";
    } else if (bytes < 1024 * 1024 * 1024 * 1024) {
      return (bytes / (1024 * 1024 * 1024)).toFixed(2) + " GB";
    } else {
      return (bytes / (1024 * 1024 * 1024 * 1024)).toFixed(2) + " TB";
    }
  }

  async function setLogDownload(item: any) {
    await LogOperation.postLogOperation("download", 6, 0);
  }

  return (
    <Fragment>
      <Container>
        <>
          <TitleSidebar
            titleSize={16}
            title="Arquivos"
            quantity={attachmentList.length ? attachmentList.length : undefined}
            onAddSidebar={() => setOpenModal(true)}
            onShow={() => setShowAttachment(!showAttachment)}
            show={showAttachment}
          />
          <AttachmentListContainer>
            {showAttachment &&
              (attachmentList.length ? (
                attachmentList.map((item: any, index: number) => {
                  return (
                    <AttachmentContainer key={index}>
                      <LeftContainer>
                        <AttachmentIconContainer>
                          <img src={fileIcon} alt="" />
                        </AttachmentIconContainer>
                        <ContainerText>
                          <Title>{item.title}</Title>
                          <Description>{`${convertType(
                            item.type
                          )}, ${formatFileSize(item.size)}`}</Description>
                        </ContainerText>
                      </LeftContainer>
                      <RightContainer className="container_buttons">
                        <button
                          onClick={() => {
                            handleOpenDeleteModal();
                            setEditItem(item);
                          }}
                          id="delete"
                        >
                          <img style={{ width: 18 }} src={IconDelete} alt="" />
                        </button>
                        <button onClick={() => onEditItem(item)} id="edit">
                          <img src={edit} alt="" />
                        </button>
                        <a
                          target="_blank"
                          href={`${process.env.REACT_APP_STORAGE_BASE_URL}/${container}/${item.filename}`}
                          rel="noreferrer"
                        >
                          {/* <button id="download" onClick={() => setLogDownload(item)}>
                          <img src={download} alt="" />
                        </button> */}
                        </a>
                      </RightContainer>
                      <Tooltip
                        anchorSelect="#delete"
                        place="top"
                        content="Excluir"
                      />
                      <Tooltip
                        anchorSelect="#edit"
                        place="top"
                        content="Editar"
                      />

                      <Tooltip
                        anchorSelect="#download"
                        place="top"
                        content="Download"
                      />
                    </AttachmentContainer>
                  );
                })
              ) : (
                <AttachmentEmpty>Nenhum arquivo anexado</AttachmentEmpty>
              ))}
          </AttachmentListContainer>
        </>
      </Container>
      <ModalRight
        isOpen={openModal}
        setIsOpen={closeModal}
        title={isEdit ? "Editar anexo" : "Criar anexo"}
        isEdit={isEdit}
        onClickAplicar={isEdit ? handleEditAttachment : handleSaveAttachment}
        onClickDelete={handleOpenDeleteModal}
        showIconDelete={false}
      >
        <AttachmentChildrenModal
          typeError={typeError}
          nameError={nameError}
          fileError={fileError}
          isEdit={isEdit}
          editItem={editItem}
          type={type}
          name={name}
          setType={setType}
          setName={setName}
          file={file}
          setFile={setFile}
        />
      </ModalRight>
      <ModalConfirmation
        onClose={() => {
          setOpenDeleteModal(false);
        }}
        title="Excluir anexo"
        titleBody="Deseja excluir o anexo?"
        subtitleBody="Esta ação não poderá ser desfeita"
        labelButton="Excluir"
        onClickButton={handleDeleteAttachment}
        isOpen={openDeleteModal}
      />
    </Fragment>
  );
};

export default AttachmentComponent;
