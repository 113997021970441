import styled from 'styled-components';
import { stylesGlobalDefault } from '../../../global/globalStyle';

export const ContainerSchedule = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  background: ${(props) => props.color};
  border-radius: 20px;
`;

export const Scheduled = styled.span`
  font-family: ${stylesGlobalDefault.fontFamily};
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  color: ${(props) => props.color};
`;
