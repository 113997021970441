import { useEffect, useState } from "react";
import { CustomerCertificateService } from "../../../services/customerCertificate/index";
import { CreateEditCustomerCertificate } from "./CreateEdit";
import { AddButton } from "../../../components/AddButton";
import { HeaderContainer } from "../../../features/HeaderHearing/styles";
import { TitleContainer } from "../../../components/Toast/styles";
import { Container, Title } from "../../../components/Body/AdminArea/styles";
import ModalRight from "../../../components/ModalRight";
import { formatDDMMYYYY } from "../../../utils/format";
import {
  TitleCertificate,
  InfoCertificate,
  ContainerInfosCertificate,
} from "./styles";
import login_logo from "../../../assets/kenta.svg";
import { ListEmpty } from "../../../components/ListEmpty";

//Toast
import { hideLoading, showLoading } from "../../../store/modules/login/actions";
import { useDispatch } from "react-redux";
import { useToast } from "../../../hooks/toast";
import { LogOperation } from "../../../utils/entities/logOperation";
import { getValueFromlocalStorage } from "../../../utils/sessionStorageEncrypt";
export function CustomerCertificate() {
  const user = getValueFromlocalStorage("@Kenta:user");

  const dispatch = useDispatch();
  const { addToast } = useToast();

  let customerId: any = null;

  if (user) {
    const user_parsed = JSON.parse(user);
    customerId = user_parsed.customerId;
  }

  const [valueCertificate, setCertificate] = useState<string>("");
  const [fieldError, setFieldError] = useState<boolean>(false);
  const [startFocus, setStartFocus] = useState<boolean>(false);

  const [amount, setAmount] = useState<string>("");
  const [extraAmount, setExtraAmount] = useState<string>("");
  const [finishDate, setFinishDate] = useState<string>("");
  const [modality, setModality] = useState<string>("");
  const [startDate, setStartDate] = useState<string>("");

  const [load, setLoad] = useState<boolean>(true);
  const [openModal, setOpenModal] = useState(false);
  const [hasCertificate, setHasCertificate] = useState<boolean>(false);

  useEffect(() => {
    onLoadList();
  }, []);

  interface iCustomerCertificate {
    id: string;
    cnpj: string;
    modality: string;
    title: string;
    description: string;
    amount: string;
    extraAmount: string;
    startDate: string;
    finishDate: string;
  }

  async function onLoadList() {
    setLoad(true);
    dispatch(showLoading());
    const response = await CustomerCertificateService.getAll(customerId);

    if (response) {
      if (response.status) {
        let certificate = response.data?.data as iCustomerCertificate;
        setHasCertificate(true);
        setAmount(certificate.amount);        
        setExtraAmount(certificate.extraAmount);
        setModality(certificate.description);
        setStartDate(formatDDMMYYYY(certificate.startDate));
        setFinishDate(formatDDMMYYYY(certificate.finishDate));
      } else {
        setHasCertificate(false);
        addToast({
          type: "warning",
          title: response.message + "",
        });
      }
    }

    dispatch(hideLoading());
    setLoad(false);
  }

  async function importCertificate() {
    try {
      if (!valueCertificate) {
        addToast({
          type: "warning",
          title: "Certificado",
          description:
            "Para importar um certificado é necessário preencher os campos destacados.",
        });
        setFieldError(true);
        setStartFocus(false);
        return;
      }
      dispatch(showLoading());

      const result = await CustomerCertificateService.importCertificate(
        valueCertificate
      );

      if (result.status) {
        addToast({
          type: "success",
          title: "Certificado",
          description: "Certificado foi atualizado com sucesso.",
        });
        await LogOperation.postLogOperation("CustomerCertificate", 7, 0);
        clear();
        await onLoadList();
      } else {
        addToast({
          type: "error",
          title: "Certificado",
          description: result.message,
        });
      }
      dispatch(hideLoading());
    } catch (error) {
      addToast({
        type: "error",
        title: "Certificado",
        description: "Falha ao atualizar o certificado",
      });
    }
  }

  function clear() {
    setOpenModal(false);
    setCertificate("");
    setFieldError(false);
  }

  return (
    <>
      <Container>
        <HeaderContainer>
          <TitleContainer>
            <Title>Certificado</Title>
            {
              <AddButton
                id="addCertificate"
                onClick={() => setOpenModal(true)}
              />
            }
          </TitleContainer>
        </HeaderContainer>
        {load ? null : hasCertificate ? (
          <>
            <ContainerInfosCertificate>
              <TitleCertificate>
                <img src={login_logo} alt="" />
              </TitleCertificate>
              <div
                style={{
                  fontSize: "26px",
                  fontWeight: "bold",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              >
                Kenta Inquérito
              </div>
              <div
                style={{
                  fontSize: "18px",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              >
                {/* {LogOperation.getGithubInfo().version} */}
              </div>
              {/* <div>---------------------------</div> */}
            </ContainerInfosCertificate>
            <ContainerInfosCertificate>
              <TitleCertificate>Modalidade</TitleCertificate>
              <InfoCertificate>{modality}</InfoCertificate>
            </ContainerInfosCertificate>
            <ContainerInfosCertificate>
              <TitleCertificate>Limite</TitleCertificate>
              <InfoCertificate>{amount}</InfoCertificate>
            </ContainerInfosCertificate>
            <ContainerInfosCertificate>
              <TitleCertificate>Extra</TitleCertificate>
              <InfoCertificate>{extraAmount}</InfoCertificate>
            </ContainerInfosCertificate>
            <ContainerInfosCertificate>
              <TitleCertificate>Data início</TitleCertificate>
              <InfoCertificate>{startDate}</InfoCertificate>
            </ContainerInfosCertificate>
            <ContainerInfosCertificate>
              <TitleCertificate>Data término</TitleCertificate>
              <InfoCertificate>{finishDate}</InfoCertificate>
            </ContainerInfosCertificate>
          </>
        ) : (
          <ListEmpty title="certificado"></ListEmpty>
        )}
      </Container>
      <ModalRight
        isOpen={openModal}
        setIsOpen={() => clear()}
        title={"Importar certificado"}
        onClickAplicar={importCertificate}
      >
        <CreateEditCustomerCertificate
          valueCertificate={valueCertificate}
          setCertificate={setCertificate}
          fieldError={fieldError}
          label="Certificado"
          required={true}
          height={380}
          width={360}
          startFocus={startFocus}
        />
      </ModalRight>
    </>
  );
}
