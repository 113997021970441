import React, { useEffect, useState } from "react";
import { CircleBadge } from "../Badges/Circle";

import {
  Container,
  ContainerInput,
  InputText,
  Label,
} from "./styles";
import moment from "moment";

type IInput = {
  id?: string;
  width?: number;
  height?: number;
  marginLeft?: number;
  label: string;
  value?: string | number;
  icon?: boolean;
  maxLength?: number;
  error?: boolean;
  setType?: (e: any) => unknown;
  onChange: (e: string) => unknown;
  disabled?: boolean;
  required: boolean;
};

export const InputDateUserUnit: React.FC<IInput> = ({
  id,
  label,
  maxLength,
  onChange,
  value,
  disabled = false,
  error = false,
  width,
  height,
  required,
  marginLeft,
}) => {
  const [focus, setFocus] = useState<boolean>(false);
  const [errorRequired, setErrorRequired] = useState<boolean>(false);

  const activeFocus = () => {
    setFocus(true);
  };

  const inactiveFocus = () => {
    setFocus(false);
  };

  function selectValue(e: any) {
    if (e !== null) {
      onChange(e);
      setErrorRequired(false);
    }
  }

  useEffect(() => {
    if (error) {
      setErrorRequired(true);
    }
  }, [error]);

  return (
    <Container style={{ marginLeft: marginLeft }}>
      <Label>{label}</Label>
      {required && !disabled && (
        <CircleBadge radius="50px" widthHeigt="8px" />
      )}
      <ContainerInput
        width={width}
        height={height}
        notError={!errorRequired && !!value}
        error={errorRequired}
        focus={focus}
      >
        <InputText
          id={id}
          min={moment().format("YYYY-MM-DD")}
          autoComplete="on"
          onFocus={activeFocus}
          onBlur={inactiveFocus}
          autoCapitalize="off"
          disabled={disabled}
          type="date"
          defaultValue={moment(value).format("YYYY-MM-DD")}
          required={true}
          onChange={(e) => selectValue(e.target.value)}
        />
      </ContainerInput>
    </Container>
  );
};
