import { usePermissions } from "../../../hooks/permission";
import {
  Container,
  ContainerBody,
  ContainerList,
  ContainerTitleWithNumber,
  Title,
} from "./styles";

import { AddButton } from "../../AddButton";
import { CardAdminItem } from "../../../features/CardAdminItem";
import { HeaderContainer } from "../../../features/HeaderHearing/styles";
import { QuantityComponent } from "../../QuantityComponent";
import { TitleContainer } from "../../Toast/styles";

type AdmProps = {
  title: string;
  InputSearchEdit?: string;
  InputSearchOnChange: (text: string) => any;
  list: Array<any>;
  headerAddButtonOnClick: () => unknown;
  headerTrashButtonOnClick: () => unknown;
  onClick: (item: any) => void;
  onClickDelete?: (props: any) => void;
  setItemReturnClick: any;
  setOpenModal: any;
  searchText?: string;
  setIsEdit: any;
  permissionName: string;
  currentIndex: number;
  items?: any;
  active?: any;
  activeUnit?: boolean;
};

export function UserArea({
  title,
  list,
  headerAddButtonOnClick,
  onClick,
  permissionName,
  currentIndex,
  active,
}: AdmProps) {
  const { create, edit, read } = usePermissions(permissionName);
  return (
    <Container>
      <HeaderContainer style={{ backgroundColor: "white" }}>
        <TitleContainer>
          <ContainerTitleWithNumber>
            <Title style={{ marginRight: 10 }}>{title}</Title>
            <QuantityComponent
              value={
                list?.length > 0
                  ? list?.filter((e: any) => e.active || e.policeUnit).length
                  : 0
              }
            />
          </ContainerTitleWithNumber>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              width: 100,
            }}
          >
            {create && (
              <AddButton
                onClick={headerAddButtonOnClick}
                icon={true}
                style={{
                  width: "24px",
                  height: "24px",
                  backgroundColor: "white",
                }}
              />
            )}
          </div>
        </TitleContainer>
      </HeaderContainer>
      <ContainerBody>
        <ContainerList>
          {read
            ? list?.map((item: any, index: number) => {
                return item.active || item.policeUnit ? (
                  <CardAdminItem
                    key={item.id}
                    isActive={active}
                    id={item?.customerId}
                    name={item?.policeUnit}
                    description={item.profile}
                    active={item.active}
                    onClickEditAction={() => onClick(item)}
                    disable={edit ? false : true}
                  />
                ) : null;
              })
            : null}
        </ContainerList>
      </ContainerBody>
    </Container>
  );
}
