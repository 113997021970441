import styled from "styled-components";
import { shade } from "polished";
import signInBackgroundImg from "../../assets/background_login.png";
import { stylesGlobalDefault } from "../../global/globalStyle"; 

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100vh;
  //background: #ecf0f3;
  background: ${stylesGlobalDefault.backgroundListItemColor};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 380px;
  //background: #fff;
  background: ${stylesGlobalDefault.white};
  padding: 0 140px;

  img {
    margin-bottom: 10px;
  }
`;

export const Form = styled.form`
  width: 100%;
`;

interface ButtonProps {
  backgroundButton?: "primary" | "secondary";
}

export const Button = styled.button<ButtonProps>`
  background: ${(props) =>
    //props.backgroundButton !== "secondary" ? "#1D9BF0" : "#FFF"};
    props.backgroundButton !== "secondary" ? stylesGlobalDefault.blue : stylesGlobalDefault.white };
  color: ${(props) =>
    //props.backgroundButton !== "secondary" ? "#FFF" : "#1D9BF0"};
    props.backgroundButton !== "secondary" ? stylesGlobalDefault.white : stylesGlobalDefault.blue };
  border: ${(props) =>
    //props.backgroundButton !== "secondary" ? 0 : "2px solid #1D9BF0"};
    props.backgroundButton !== "secondary" ? 0 : `2px solid ${stylesGlobalDefault.blue}`};
  width: 100%;
  height: 40px;
  border-radius: 50px;
  margin-top: 8px;
  //font-family: "Inter";
  font-family: ${stylesGlobalDefault.fontFamily};
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 125%;
  text-align: center;
  transition: 0.3s;

  &:hover {
    //background: ${shade(0.2, "#0D77BF")};
    background: ${shade(0.2, stylesGlobalDefault.blueDark)};
    transition: 0.2s;
    //color: #fff;
    color: ${stylesGlobalDefault.white};
    cursor: pointer;
  }
`;

export const Background = styled.div`
  flex: 1;
  background: url(${signInBackgroundImg}) no-repeat center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ForgotPasswordButton = styled.button`
  //color: #1d9bf0;
  color: ${stylesGlobalDefault.blue};
  background: none;
  font-family: ${stylesGlobalDefault.fontFamily};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin-top: 30px;
  border: 0;

  cursor: pointer;
`;

export const ContainerTextCenter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  strong {
    font-size: 36px;
    color: ${stylesGlobalDefault.white};
    text-align: center;
    font-family: ${stylesGlobalDefault.fontFamily};
    font-weight: 700;
  }
  span {
    font-size: 28px;
    color: ${stylesGlobalDefault.white};
    text-align: center;
    font-family: ${stylesGlobalDefault.fontFamily};
    font-weight: 700;
  }
`;

export const TopBar = styled.div`
  width: 136px;
  height: 4px;
  background: ${stylesGlobalDefault.blue};
  margin-bottom: 24px;
`;

export const IconsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 24px;
`;

export const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border: 3px solid ${stylesGlobalDefault.blue};
  background: none;
  border-radius: 50%;
  margin-right: 16px;
`;

export const ContainerApp = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 60px;
  left: 60px;

  strong {
    font-family: ${stylesGlobalDefault.fontFamily};
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: ${stylesGlobalDefault.white};
  }
`;

export const ContainerAppInside = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 14px;
`;

export const App = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 42px;
  border: 2px solid ${stylesGlobalDefault.white};
  border-radius: 8px;
  background: none;
  margin-right: 12px;
  padding: 0 15px;

  img {
    & + img {
      margin-left: 8px;
      margin-bottom: -2px;
    }
  }
`;
