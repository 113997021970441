import styled, {keyframes} from "styled-components";



export const ContainerInputDates = styled.div`
  width: 350px;
  display: flex;
  flex-direction: "row";
  justify-content: "space-between";
`;

export const DropContainer = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  width: 350px;
  padding: 8px;
  height: 470px;
  background: #ECF0F3;
  border-top: 1px solid #000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 999;
`;

const dropAnimation = keyframes`
  0% {
    transform: translateY(-10px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

export const Content = styled.div<{ isOpen: boolean }>`
  animation: ${dropAnimation} 0.2s ease-out;
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  /* opacity: 0; */
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background: #3e424e;
  z-index: 999;
`;
