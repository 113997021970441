import React from "react";
import { InputGeneral } from "../../../../components/Input";
import { InputSwitch } from "../../../../components/InputSwitch";
import {
  Container,
  ContainerBody,
  ContainerInputs,
  ContainerSwitch,
} from "../../StyleCSS/bodyCreateEdit";
import VMasker from "vanilla-masker";

interface PoliceUnitCreateEdit {
  isEdit: boolean;
  name: string;
  onChangeName: (e: any) => unknown;
  description: string;
  onChangeDescription: (e: any) => unknown;
  city: string;
  onChangeCity: (e: any) => unknown;
  address: string;
  onChangeAddress: (e: any) => unknown;
  addressNumber: number;
  onChangeAddressNumber: (e: any) => unknown;
  phone: string;
  onChangePhone: (e: any) => unknown;
  regional: boolean;
  onChangeRegional: (e: any) => unknown;
  regionalCode: number;
  onChangeRegionalCode: (e: any) => unknown;
  extraContent: string;
  onChangeExtraContent: (e: any) => unknown;
  active: boolean;
  onChangeSwitch: (e: boolean) => unknown;
  latLng: string;
  onChangeLatLng: (e: any) => unknown;
  foreignKey: string;
  onChangeForeignKey: (e: any) => unknown;
  fieldError: boolean;
}

export const CreateEditPoliceUnit = (
  props: PoliceUnitCreateEdit
): JSX.Element => {
  return (
    <>
      <Container>
        <ContainerBody>
          <ContainerSwitch>
            <InputSwitch
              text="Ativo"
              checked={props.active}
              onChange={() => props.onChangeSwitch(!props.active)}
            />
          </ContainerSwitch>
          <ContainerInputs>
            <InputGeneral
              error={props.fieldError}
              maxLength={240}
              value={props.name}
              onChange={(e) => props.onChangeName(e)}
              type="text"
              label="Nome"
              required={true}
              startFocus={true}
            />
            <InputGeneral
              marginLeft={12}
              error={props.fieldError}
              maxLength={340}
              value={props.description}
              onChange={(e) => props.onChangeDescription(e)}
              type="text"
              label="Descrição"
              required={true}
              startFocus={false}
            />
          </ContainerInputs>
          <ContainerInputs>
            <InputGeneral
              error={props.fieldError}
              maxLength={240}
              value={props.city}
              onChange={(e) => props.onChangeCity(e)}
              type="text"
              label="Cidade"
              required={true}
              startFocus={false}
            />
            <InputGeneral
              marginLeft={12}
              error={props.fieldError}
              maxLength={140}
              value={props.address}
              onChange={(e) => props.onChangeAddress(e)}
              type="text"
              label="Endereço"
              required={true}
              startFocus={false}
            />
          </ContainerInputs>
          <ContainerInputs>
            <InputGeneral
              error={props.fieldError}
              maxLength={10}
              value={props.addressNumber}
              onChange={(e) => props.onChangeAddressNumber(e)}
              type="number"
              label="Número"
              required={true}
              startFocus={false}
            />
            <InputGeneral
              marginLeft={12}
              error={props.fieldError}
              maxLength={15}
              value={
                props.phone
                  ? VMasker.toPattern(props.phone, "(99) 99999-9999")
                  : ""
              }
              onChange={(e) => props.onChangePhone(e)}
              label="Telefone"
              required={true}
              startFocus={false}
            />
          </ContainerInputs>
          {/* <ContainerInputs>
            <InputGeneral
              error={props.fieldError}
              value={props.latLng}
              onChange={(e) => {
                let newValueLatLng = e.replace(
                  /[abcdefghijlmnopqrstuvxzwky!@#$%¨&*()_+=`´{}~^ºª<>;:\?'"¹²³£¢¬§°\|\/\\₢]/g,
                  ""
                );
                props.onChangeLatLng(newValueLatLng);
              }}
              type="text"
              label="Latitude, longitude"
              required={true}
            />
          </ContainerInputs> */}
          <ContainerInputs>
            <InputGeneral
              error={props.fieldError}
              maxLength={50}
              value={props.foreignKey}
              onChange={(e) => props.onChangeForeignKey(e)}
              type="text"
              label="Código extrerno"
              required={false}
              startFocus={false}
            />
          </ContainerInputs>
          <ContainerInputs>
            <InputGeneral
              error={false}
              maxLength={45}
              value={props.extraContent}
              onChange={(e) => props.onChangeExtraContent(e)}
              type="text"
              label="Conteudo extra"
              required={false}
              startFocus={false}
            />
          </ContainerInputs>          
          {/* <ContainerInputs>
            <InputSwitch
              text="Regional"
              checked={props.regional}
              onChange={() => props.onChangeRegional(!props.active)}
            />
          </ContainerInputs> */}
        </ContainerBody>
      </Container>
    </>
  );
};
