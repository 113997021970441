import React from "react";
import { InputGeneral } from "../../../../components/Input";
import {
  Container,
  ContainerBody,
  ContainerInputs,
  ContainerSwitch
} from "../../StyleCSS/bodyCreateEdit";
import { InputSwitch } from "../../../../components/InputSwitch";

interface ProcedureCustomerSourceCreateEdit {
  valueEdit?: string;
  onChangeName: (e: string) => unknown;
  active: boolean;
  onChangeSwitch: (e: boolean) => unknown;
  fieldError: boolean;
}

export const CreateEditProcedureCustomerSource = (
  props: ProcedureCustomerSourceCreateEdit
): JSX.Element => {
  return (
    <>
      <Container>
        <ContainerBody>
          <ContainerSwitch>
            <InputSwitch
              text="Ativo"
              checked={props.active}
              onChange={() => props.onChangeSwitch(!props.active)}
            />
          </ContainerSwitch>
          <ContainerInputs>
            <InputGeneral
              error={props.fieldError}
              value={props.valueEdit}
              onChange={(e) => props.onChangeName(e)}
              type="text"
              label="Nome"
              required={true}
            />
          </ContainerInputs>
        </ContainerBody>
      </Container>5
    </>
  );
};
