import styled from "styled-components";
import { stylesGlobalDefault } from "../../../global/globalStyle";

interface IProps {
  error?: boolean;
}
interface IStatus {
  active: boolean;
}

export const Container = styled.div`
  width: 100%;
  overflow-y: hidden;
`;

export const ContainerBody = styled.div`
  width: 100% - 48px;
  overflow: hidden;
  height: 90%;
  padding: 0 24px;
  padding-bottom: 20px;
`;

export const ContainerCreate = styled.div`
  display: flex;
  align-items: center;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  margin-top: 28px;
`;

export const ContainerButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
  background: #1d9bf0;
  height: 40px;
  border-radius: 50px;
  margin-left: 12px;
  cursor: pointer;
`;

export const Button = styled.span`
  font-family: "Inter", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 125%;
  color: #fff;
`;

export const ContainerList = styled.div`
  width: 100%;
  height: 70%;
  padding: 4px 4px 0px 0px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  @media screen and (min-height: 850px) {
    height: 82vh;
  }
`;

export const Title = styled.h3`
  font-family: "Inter", Arial, Helvetica, sans-serif;
  font-weight: 700;
  font-size: 18px;
  color: #fff;
  margin-right: 12px;
`;

export const CardContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px -40px;
  width: 100% - 40px;
  background: ${stylesGlobalDefault.backgroundListItemColor};
  margin-bottom: 4px;
  border-radius: 8px;
  padding: 20px;
  border: 2px solid ${stylesGlobalDefault.backgroundListItemColor};
  cursor: pointer;

  &:hover {
    border: 2px solid #1d9bf0;
  }
`;

export const ContainerTitleWithNumber = styled.div`
  display: flex;
  align-items: center;
`;

export const LabelCard = styled.label`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 125%;
  color: #000;
`;

export const StatusContainer = styled.div<IStatus>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  background: ${(props) =>
    props.active
      ? stylesGlobalDefault.activedItem
      : stylesGlobalDefault.inactivedItem};
  padding: 4px 8px;
  border-radius: 20px;

  strong {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 12px;
    text-align: right;
    text-transform: uppercase;
    color: #fff;
  }
`;
