import styled from "styled-components";
import { stylesGlobalDefault } from "../../global/globalStyle";

export const Container = styled.div`
  position: absolute;
  width: 145px;
  background: ${stylesGlobalDefault.white};
  //border: 2px solid #b2b2b2;
  border: 2px solid ${stylesGlobalDefault.gray2};
  margin-right: -140px;
  margin-bottom: -110px;
`;

export const ButtonContainer = styled.button`
  display: flex;
  align-items: flex-start;
  width: 100%;
  margin: 0;
  padding: 7px;
  background: ${stylesGlobalDefault.white};
  border: 0;
  cursor: pointer;

  span {
    font-size: 12px;
    font-family: ${stylesGlobalDefault.fontFamily};
    font-weight: 700;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  &:hover {
    background: ${stylesGlobalDefault.blue};
    cursor: pointer;
  }
`;
