import api from "../api";

export const reportService = {
  async getAllProceduresByPoliceStation(
    customerId: number,
    policeUnitId: number,
    startDate: string,
    endDate: string
  ) {
    try {
      const { data } = await api.get<any>(
        `/DataAnalysis/GetAllProceduresByPoliceUnit?customerId=${customerId}&policeUnitId=${policeUnitId}&startDate=${startDate}&finishDate=${endDate}`
      );

      return {
        status: true,
        data: data,
        message: data.message,
      };
    } catch (err) {
      return {
        status: false,
        data: null,
        message: "Houve um erro ao consultar os registros.",
      };
    }
  },
  async getAllPoliceUnitByUserId(userId: number) {
    try {
      const { data } = await api.get<any>(
        `/PoliceUnit/GetAllByUserId?userId=${userId}`
      );

      return {
        status: true,
        data: data,
        message: data.message,
      };
    } catch (err) {
      return {
        status: false,
        data: null,
        message: "Houve um erro ao consultar os registros.",
      };
    }
  },
  async getAllPoliceUnitPerYer(policeUnitId: number) {
    try {
      const { data } = await api.get<any>(
        `DataAnalysis/GetAllProceduresByYear?policeUnitId=${policeUnitId}`
      );

      return {
        status: true,
        data: data,
        message: data.message,
      };
    } catch (err) {
      return {
        status: false,
        data: null,
        message: "Houve um erro ao consultar os registros.",
      };
    }
  },
  async getAllProceduresByCity(
    customerId: number,
    startDate: string,
    endDate: string
  ) {
    try {
      const { data } = await api.get<any>(
        `/api/DataAnalysis/GetAllProceduresByCity?customerId=${customerId}&startDate=${startDate}&finishDate=${endDate}`
      );

      return {
        status: true,
        data: data,
        message: data.message,
      };
    } catch (err) {
      return {
        status: false,
        data: null,
        message: "Houve um erro ao consultar os registros.",
      };
    }
  },
  async getAllLogOperations(
    customerId: number,
    startDate: string | null,
    endDate: string | null,
    operation: number | null,
    device: string | null
  ) {
    try {
      if (!operation && !device) {
        const { data } = await api.get<any>(
          `/LogOperation/GetAll?customerId=${customerId}&startDate=${startDate}&finishDate=${endDate}`
        );
        return {
          status: true,
          data: data,
          message: data.message,
        };
      }

      if (operation && !device) {
        const { data } = await api.get<any>(
          `/LogOperation/GetAll?customerId=${customerId}&startDate=${startDate}&finishDate=${endDate}&operation=${operation}`
        );
        return {
          status: true,
          data: data,
          message: data.message,
        };
      }

      if (!operation && device) {
        const { data } = await api.get<any>(
          `/LogOperation/GetAll?customerId=${customerId}&startDate=${startDate}&finishDate=${endDate}&device=${device}`
        );
        return {
          status: true,
          data: data,
          message: data.message,
        };
      }

      if (!device) {
        const { data } = await api.get<any>(
          `/LogOperation/GetAll?customerId=${customerId}&startDate=${startDate}&finishDate=${endDate}&operation=${operation}`
        );

        return {
          status: true,
          data: data,
          message: data.message,
        };
      }

      if (!operation) {
        const { data } = await api.get<any>(
          `/LogOperation/GetAll?customerId=${customerId}&startDate=${startDate}&finishDate=${endDate}&device=${device}`
        );
        return {
          status: true,
          data: data,
          message: data.message,
        };
      }

      const { data } = await api.get<any>(
        `/LogOperation/GetAll?customerId=${customerId}&startDate=${startDate}&finishDate=${endDate}&operation=${operation}&device=${device}`
      );

      return {
        status: true,
        data: data,
        message: data.message,
      };
    } catch (err) {
      return {
        status: false,
        data: null,
        message: "Houve um erro ao consultar os registros.",
      };
    }
  },
  async getAllLogErros(
    customerId: number,
    startDate: string,
    endDate: string,
    device: string | null,
    platform?: string | null
  ) {
    try {
      const { data } = await api.get<any>(
        `/LogError/GetAll?customerId=${customerId}&startDate=${startDate}&finishDate=${endDate}${
          platform ? `&platform=${platform}` : ""
        }`
      );

      return {
        status: true,
        data: data,
        message: data.message,
      };
    } catch (err) {
      return {
        status: false,
        data: null,
        message: "Houve um erro ao consultar os registros.",
      };
    }
  },
};
