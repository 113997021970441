import { SystemConfigurationService } from "../systemConfiguration";

interface IShortcut {
  name: string;
  value: string;
}

export const globalConfigurationsUtils = {
  setLocalstorage: async (customerId: number) => {
    var response = await SystemConfigurationService.getAllByCustomer(
      customerId
    );

    localStorage.setItem("globalConfiguration", JSON.stringify(response.data));

    let listShortcuts: Array<IShortcut> =
      response.data.length &&
      response.data.find((x: any) => x.name === "Shortcuts")?.value &&
      JSON.parse(response.data.find((x: any) => x.name === "Shortcuts")?.value);
    setShortcutsLoad(listShortcuts);

    // Number register by page list

    let numberPage =
      response.data.length &&
      response.data.find((x: any) => x.name === "PaginationList")?.value;

    let paginationList = numberPage !== null ? numberPage : "10";
    localStorage.setItem("PaginationList", paginationList);

    // deadline precedure
    let customerProcedureDeadlineBD =
      response.data.length &&
      response.data.find((x: any) => x.name === "CustomerProcedureDeadline")
        ?.value;

    let customerProcedureDeadline =
      customerProcedureDeadlineBD !== null ? customerProcedureDeadlineBD : "0";
    localStorage.setItem(
      "CustomerProcedureDeadline",
      customerProcedureDeadline
    );
  },
};

function setShortcutsLoad(listShortcuts: Array<IShortcut>) {
  if (listShortcuts) {
    let playStopAux = listShortcuts.find((x) => x.name === "playStop")?.value;
    let actionBackTimeAux = listShortcuts.find(
      (x) => x.name === "actionBackTime"
    )?.value;
    let actionNextTimeAux = listShortcuts.find(
      (x) => x.name === "actionNextTime"
    )?.value;
    let timeBackNextAux = listShortcuts.find(
      (x) => x.name === "timeBackNext"
    )?.value;
    let slowSpeedAux = listShortcuts.find((x) => x.name === "slowSpeed")?.value;
    let fastSpeedAux = listShortcuts.find((x) => x.name === "fastSpeed")?.value;

    localStorage.setItem(
      "GlobalPlayStop",
      playStopAux ? playStopAux : "Espaço"
    );
    localStorage.setItem(
      "GlobalActionBackTime",
      actionBackTimeAux ? actionBackTimeAux : "ArrowLeft"
    );
    localStorage.setItem(
      "GlobalActionNextTime",
      actionNextTimeAux ? actionNextTimeAux : "ArrowRight"
    );
    localStorage.setItem(
      "GlobalTimeBackNext",
      timeBackNextAux ? timeBackNextAux : "10"
    );
    localStorage.setItem(
      "GlobalSlowSpeed",
      slowSpeedAux ? slowSpeedAux : "Ctrl+ArrowUp"
    );
    localStorage.setItem(
      "GlobalFastSpeed",
      fastSpeedAux ? fastSpeedAux : "Ctrl+ArrowDown"
    );
  } else {
    localStorage.setItem("GlobalPlayStop", "Espaço");
    localStorage.setItem("GlobalActionBackTime", "ArrowLeft");
    localStorage.setItem("GlobalActionNextTime", "ArrowRight");
    localStorage.setItem("GlobalTimeBackNext", "5");
    localStorage.setItem("GlobalSlowSpeed", "Ctrl+ArrowUp");
    localStorage.setItem("GlobalFastSpeed", "Ctrl+ArrowDown");
  }
}
