import React, { useState } from "react";
import { ScheduleItemSidebar } from "../ScheduleItemSidebar";
import { TitleSidebar } from "../TitleSidebar";
import { Container, DateContainer, EmptyList } from "./styles";
import moment from "moment";
import { useSelector } from "react-redux";
import { HearingService } from "../../services/hearing";
import { useToast } from "../../hooks/toast";
import { ModalConfirmation } from "../ModalConfirmation";
import { useDispatch } from "react-redux";
import { hideLoading, showLoading } from "../../store/modules/login/actions";

interface Props {
  onClickAddSidebar: any;
  dateOitivas: any[];
  loadingList: () => unknown;
  onClickGravarOitiva: (item: any) => void;
  onClickEditarOitiva: (item: any) => void;
}

interface HearingDelete {
  id: number;
  guid: string;
  foreignKey: number;
  policeUnitId: number;
  procedureCustomerId: number;
  hearingTypeId: number;
  procedureCustomerAttendantId: number;
  status: string;
  procedureNumber: number;
  expectedDate: Date | string;
  startDate: Date | string;
  finishDate: Date | string;
  duration: number;
  description: string;
  userId: number;
  exportCount: number;
  isClassified: true;
  download: true;
  active: true;
  device?: "PC";
  ip?: string;
  logPoliceUnitId?: number;
  logUserId?: number;
}

export const ScheduledHearingSidebar = ({
  dateOitivas,
  onClickAddSidebar,
  onClickGravarOitiva,
  onClickEditarOitiva,
  loadingList,
}: Props) => {
  const [showHearing, setShowHearing] = useState(false);
  const [openModalHearing, setOpenModalHearing] = useState(false);
  const [showModalConfirmation, setShowModalConfirmation] = useState(false);
  const [hearingItem, setHearingItem] = useState<any>();
  const { attendants } = useSelector((state: any) => state.procedure);
  const dispatch = useDispatch();
  const { addToast } = useToast();

  const onDeleteModal = async (item: HearingDelete) => {
    setHearingItem(item);
    setShowModalConfirmation(true);
  };

  const onDeleteHearing = async () => {
    setShowModalConfirmation(false);
    dispatch(showLoading());
    const { success } = await HearingService.deleteHearingDelete(
      hearingItem.id
    );

    if (success) {
      await loadingList();
      dispatch(hideLoading());
      return addToast({
        type: "success",
        title: "Oitiva excluida",
        description: "Sua oitiva foi excluida com sucesso",
      });
    }

    await loadingList();
    addToast({
      type: "warning",
      title: "Aviso",
      description: "Sua oitiva não pode ser excluida",
    });

    dispatch(hideLoading());
  };

  return (
    <Container>
      <TitleSidebar
        quantity={dateOitivas.length > 0 ? dateOitivas.length : 0}
        titleSize={16}
        title="Oitivas agendadas"
        onShow={() => setShowHearing(!showHearing)}
        disabled={!attendants.length}
        onAddSidebar={() => onClickAddSidebar()}
        show={showHearing}
      />
      {showHearing && (
        <DateContainer>
          {dateOitivas.length > 0 ? (
            dateOitivas.map((item) => {
              return (
                <ScheduleItemSidebar
                  hearing={item}
                  onClick={() => setOpenModalHearing(true)}
                  day={
                    moment(item.expectedDate).format("DD/MM") ===
                    moment(new Date()).format("DD/MM")
                      ? "Hoje"
                      : moment(item.expectedDate).format("DD/MM")
                  }
                  hour={
                    moment(item.expectedDate).format("HH") +
                    "h" +
                    moment(item.expectedDate).format("mm")
                  }
                  title={item.attendantName}
                  key={item.id}
                  onClickDelete={() => onDeleteModal(item)}
                  onClickGravar={() => onClickGravarOitiva(item)}
                  onClickEditar={() => onClickEditarOitiva(item)}
                  description="Vítima"
                />
              );
            })
          ) : (
            <EmptyList>Nenhuma oitiva agendada</EmptyList>
          )}
        </DateContainer>
      )}
      <ModalConfirmation
        onClose={() => {
          setShowModalConfirmation(false);
        }}
        title="Excluir oitiva"
        titleBody="Deseja excluir a oitiva?"
        subtitleBody="Esta ação não poderá ser desfeita"
        labelButton="Excluir"
        onClickButton={onDeleteHearing}
        isOpen={showModalConfirmation}
      />
    </Container>
  );
};
