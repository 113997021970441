import {
  ContainerTabs,
  TabContainer,
  TabContainerTextAndLine,
  TabText,
} from "./styles";

export interface ITabComponent {
  id: string;
  name: string;
  component: JSX.Element;
}

export interface PropsTabsComponent {
  list: Array<ITabComponent>;
  selectedMenu: string;
  setSelectedMenu: (element: string) => unknown;
}

export function TabsComponent(components: PropsTabsComponent) {
  const selectedPage = () => {
    let returnComponent = components.list.find(
      (x) => x.id === components.selectedMenu
    );

    if (returnComponent) {
      return returnComponent.component;
    } else {
      return <div>Nada encontrado</div>;
    }
  };

  return (
    <>
      <ContainerTabs>
        <TabContainer>
          {components.list.map((item, index) => {
            return (
              <TabContainerTextAndLine
                moreThanThreeLetters={item.name.length > 3}
                key={index}
                selected={components.selectedMenu === item.id}
                onClick={() => components.setSelectedMenu(item.id)}
              >
                <TabText>{item.name}</TabText>
              </TabContainerTextAndLine>
            );
          })}
        </TabContainer>
        {selectedPage()}
      </ContainerTabs>
    </>
  );
}
