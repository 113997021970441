import { AxiosError } from "axios";
import api from "../api";
import { returnResponse } from "../utils";

export const DashboardService = {
  getAllForDashboard: async (
    authToken: string | null,
    customerId?: number,
    policeUnitId?: number
  ) => {
    if (!authToken) {
      return {
        status: false,
        message:
          "Não é possível recuperar os dados do dashboard sem estar autenticado.",
      };
    }

    if (!customerId) {
      return {
        status: false,
        message:
          "Não é possível recuperar os dados do dashboard sem o customerId",
      };
    }

    if (!policeUnitId) {
      return {
        status: false,
        message:
          "Não é possível recuperar os dados do dashboard sem a unidade de police selecionada",
      };
    }

    try {
      const params = {
        authToken,
        policeUnitId,
        customerId,
      };
      const { data } = await api.get(`DataAnalysis/GetAllForDashboard`, {
        params,
      });

      return {
        status: true,
        data: data.data,
      };
    } catch (error) {
      if (error instanceof AxiosError) {
        let { severity, status, message, data } = returnResponse({
          statusCode: error.response?.status,
          data: error?.response?.data,
          message: error?.response?.data?.errors[0]?.message,
        });

        return {
          severity,
          status,
          message: message ? message : "Sucesso",
          data,
        };
      }

      return {
        status: false,
        message: "Error",
        data: null,
        severity: "error",
      };
    }
  },
  getAllForDashboardMaster: async (
    authToken: string | null,
    customerId?: number
  ) => {
    if (!authToken) {
      return {
        status: false,
        message:
          "Não é possível recuperar os dados do dashboard master sem estar autenticado.",
      };
    }

    if (!customerId) {
      return {
        status: false,
        message:
          "Não é possível recuperar os dados do dashboard master sem o customerId",
      };
    }

    try {
      const params = {
        authToken,
        customerId,
      };
      const { data } = await api.get(`DataAnalysis/GetAllForDashboardMaster`, {
        params,
      });

      return {
        status: true,
        data: data.data,
      };
    } catch (error) {
      if (error instanceof AxiosError) {
        let { severity, status, message, data } = returnResponse({
          statusCode: error.response?.status,
          data: error?.response?.data,
          message: error?.response?.data?.errors[0]?.message,
        });

        return {
          severity,
          status,
          message: message ? message : "Sucesso",
          data,
        };
      }

      return {
        status: false,
        message: "Error",
        data: null,
        severity: "error",
      };
    }
  },
  getAllProceduresByPoliceUnit: async (
    authToken: string | null,
    customerId?: number,
    policeUnitId?: number,
    startDate?: string,
    finishDate?: string
  ) => {
    if (!authToken) {
      return {
        status: false,
        message:
          "Não é possível recuperar os dados do dashboard sem estar autenticado.",
      };
    }

    if (!customerId) {
      return {
        status: false,
        message:
          "Não é possível recuperar os dados do dashboard sem o customerId",
      };
    }

    if (!policeUnitId) {
      return {
        status: false,
        message:
          "Não é possível recuperar os dados do dashboard sem a unidade de police selecionada",
      };
    }
    if (!startDate) {
      return {
        status: false,
        message:
          "Não é possível recuperar os dados do dashboard sem data de inicio selecionada",
      };
    }
    if (!finishDate) {
      return {
        status: false,
        message:
          "Não é possível recuperar os dados do dashboard sem a data de fim selecionada",
      };
    }

    try {
      const params = {
        authToken,
        policeUnitId,
        customerId,
        startDate,
        finishDate,
      };
      const { data } = await api.get(
        `DataAnalysis/GetAllProceduresByPoliceUnit`,
        {
          params,
        }
      );

      return {
        status: true,
        data: data.data,
      };
    } catch (error) {
      if (error instanceof AxiosError) {
        let { severity, status, message, data } = returnResponse({
          statusCode: error.response?.status,
          data: error?.response?.data,
          message: error?.response?.data?.errors[0]?.message,
        });

        return {
          severity,
          status,
          message: message ? message : "Sucesso",
          data,
        };
      }

      return {
        status: false,
        message: "Error",
        data: null,
        severity: "error",
      };
    }
  },
  getAllProceduresByYear: async (
    authToken: string | null,
    customerId?: number,
    policeUnitId?: number,
    startDate?: string,
    finishDate?: string
  ) => {
    if (!authToken) {
      return {
        status: false,
        message:
          "Não é possível recuperar os dados do dashboard sem estar autenticado.",
      };
    }

    if (!customerId) {
      return {
        status: false,
        message:
          "Não é possível recuperar os dados do dashboard sem o customerId",
      };
    }

    if (!policeUnitId) {
      return {
        status: false,
        message:
          "Não é possível recuperar os dados do dashboard sem a unidade de police selecionada",
      };
    }
    if (!startDate) {
      return {
        status: false,
        message:
          "Não é possível recuperar os dados do dashboard sem data de inicio selecionada",
      };
    }
    if (!finishDate) {
      return {
        status: false,
        message:
          "Não é possível recuperar os dados do dashboard sem a data de fim selecionada",
      };
    }

    try {
      const params = {
        authToken,
        policeUnitId,
        customerId,
        startDate,
        finishDate,
      };
      const { data } = await api.get(`DataAnalysis/GetAllProceduresByYear`, {
        params,
      });

      return {
        status: true,
        data: data.data,
      };
    } catch (error) {
      if (error instanceof AxiosError) {
        let { severity, status, message, data } = returnResponse({
          statusCode: error.response?.status,
          data: error?.response?.data,
          message: error?.response?.data?.errors[0]?.message,
        });

        return {
          severity,
          status,
          message: message ? message : "Sucesso",
          data,
        };
      }

      return {
        status: false,
        message: "Error",
        data: null,
        severity: "error",
      };
    }
  },
  getAllProceduresByCity: async (
    authToken: string | null,
    customerId?: number,
    startDate?: string,
    finishDate?: string
  ) => {
    if (!authToken) {
      return {
        status: false,
        message:
          "Não é possível recuperar os dados do dashboard sem estar autenticado.",
      };
    }

    if (!customerId) {
      return {
        status: false,
        message:
          "Não é possível recuperar os dados do dashboard sem o customerId",
      };
    }

    if (!startDate) {
      return {
        status: false,
        message:
          "Não é possível recuperar os dados do dashboard sem data de inicio selecionada",
      };
    }
    if (!finishDate) {
      return {
        status: false,
        message:
          "Não é possível recuperar os dados do dashboard sem a data de fim selecionada",
      };
    }

    try {
      const params = {
        authToken,
        customerId,
        startDate,
        finishDate,
      };
      const { data } = await api.get(`DataAnalysis/GetAllProceduresByCity`, {
        params,
      });

      return {
        status: true,
        data: data.data,
      };
    } catch (error) {
      if (error instanceof AxiosError) {
        let { severity, status, message, data } = returnResponse({
          statusCode: error.response?.status,
          data: error?.response?.data,
          message: error?.response?.data?.errors[0]?.message,
        });

        return {
          severity,
          status,
          message: message ? message : "Sucesso",
          data,
        };
      }

      return {
        status: false,
        message: "Error",
        data: null,
        severity: "error",
      };
    }
  },
  getAllSumsProcedures: async (
    authToken: string | null,
    city: string,
    dateStart?: string,
    dateEnd?: string,
    procedureCustomerTypeId?: string,
    procedureCustomerSourceId?: string
  ) => {
    if (!authToken) {
      return {
        status: false,
        message:
          "Não é possível recuperar os dados do dashboard sem estar autenticado.",
      };
    }

    if (!city) {
      return {
        status: false,
        message:
          "Não é possível recuperar os dados do dashboard sem o customerId",
      };
    }

    if (!dateStart) {
      return {
        status: false,
        message:
          "Não é possível recuperar os dados do dashboard sem a data de inicio",
      };
    }
    if (!dateEnd) {
      return {
        status: false,
        message:
          "Não é possível recuperar os dados do dashboard sem data de fim",
      };
    }
    if (!dateEnd) {
      return {
        status: false,
        message:
          "Não é possível recuperar os dados do dashboard sem a data de fim selecionada",
      };
    }
    if (!procedureCustomerTypeId) {
      return {
        status: false,
        message:
          "Não é possível recuperar os dados do dashboard sem procedureCustomerTypeId",
      };
    }
    if (!procedureCustomerSourceId) {
      return {
        status: false,
        message:
          "Não é possível recuperar os dados do dashboard sem procedureCustomerSourceId",
      };
    }

    try {
      const params = {
        authToken,
        city,
        dateStart,
        dateEnd,
        procedureCustomerTypeId,
        procedureCustomerSourceId,
      };
      const { data } = await api.get(
        `DataAnalysis/GetAllSumsProcedures`,
        {
          params,
        }
      );

      return {
        status: true,
        data: data.data,
      };
    } catch (error) {
      if (error instanceof AxiosError) {
        let { severity, status, message, data } = returnResponse({
          statusCode: error.response?.status,
          data: error?.response?.data,
          message: error?.response?.data?.errors[0]?.message,
        });

        return {
          severity,
          status,
          message: message ? message : "Sucesso",
          data,
        };
      }

      return {
        status: false,
        message: "Error",
        data: null,
        severity: "error",
      };
    }
  },
  getAllCityByCustomerId: async (
    authToken: string | null,
    customerId?: number,
  ) => {
    if (!authToken) {
      return {
        status: false,
        message:
          "Não é possível recuperar os dados do dashboard sem estar autenticado.",
      };
    }

    if (!customerId) {
      return {
        status: false,
        message:
          "Não é possível recuperar os dados do dashboard sem o customerId",
      };
    }

 

    try {
      const params = {
        authToken,
        customerId,
      };
      const { data } = await api.get(`DataAnalysis/GetAllCityByCustomerId`, {
        params,
      });

      return {
        status: true,
        data: data.data,
      };
    } catch (error) {
      if (error instanceof AxiosError) {
        let { severity, status, message, data } = returnResponse({
          statusCode: error.response?.status,
          data: error?.response?.data,
          message: error?.response?.data?.errors[0]?.message,
        });

        return {
          severity,
          status,
          message: message ? message : "Sucesso",
          data,
        };
      }

      return {
        status: false,
        message: "Error",
        data: null,
        severity: "error",
      };
    }
  },

  async getUsedTranscriptionAmount(cnpj: string, month: string) {
    try {
        const { data } = await api.get<any>(
            `DataAnalysis/GetUsedTranscriptionAmount`,
            {
              params:{
                cnpj,
                month
              }
            }
        );

        return {
            status: true,
            data: data,
            message: data.message,
        };
    } catch (err) {
        return {
            status: false,
            data: null,
            message: "Houve um erro ao consultar os registros.",
        };
    }
},
};
