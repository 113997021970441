import { AxiosError } from "axios";
import api from "../api";
import { returnResponse } from "../utils";

export interface UnitBody {
  id: number;
  unitId: number;
  userId: number;
  policeUnitId: number;
  userCustomerProfileId: number;
  active: boolean;
  expireIn?: Date;
}

export const userService = {
  getAll: async (customerId: number, status?: 0 | 1 | null) => {
    try {
      const { data } = await api.get<any>(
        `/UserCustomer/GetAll?customerId=${customerId}${
          status !== null ? `&active=${status}` : ""
        }`
      );

      return {
        status: true,
        data: data,
        message: data.message,
      };
    } catch (err) {
      return {
        status: false,
        data: null,
        message: "Houve um erro ao consultar os registros.",
      };
    }
  },

  getAllByUser: async (userId: number) => {
    try {
      const { data } = await api.get<any>(
        `/UserCustomerProfile/GetUserCustomerProfileByPoliceUnit?UserCustomerId=${userId}`
      );

      return {
        status: true,
        data: data,
        message: data.message,
      };
    } catch (error) {
      if (error instanceof AxiosError) {
        let { severity, status, message, data } = returnResponse({
          statusCode: error.response?.status,
          data: error?.response?.data,
          message: error?.response?.data?.errors[0]?.message,
        });

        return {
          severity,
          status,
          message: message ? message : "Error",
          data,
        };
      }

      return {
        status: false,
        message: "Error",
        data: null,
        severity: "error",
      };
    }
  },
  getAllByUser2: async (userId: number) => {
    try {
      const { data } = await api.get<any>(
        `/UserCustomerProfile/GetUserCustomerProfileByPoliceUnit2?UserCustomerId=${userId}`
      );

      return {
        status: true,
        data: data,
        message: data.message,
      };
    } catch (error) {
      if (error instanceof AxiosError) {
        let { severity, status, message, data } = returnResponse({
          statusCode: error.response?.status,
          data: error?.response?.data,
          message: error?.response?.data?.errors[0]?.message,
        });

        return {
          severity,
          status,
          message: message ? message : "Error",
          data,
        };
      }

      return {
        status: false,
        message: "Error",
        data: null,
        severity: "error",
      };
    }
  },

  post: async (authToken: string | null, UserUnit: any) => {
    try {
      const body = {
        ...UserUnit,
      };
      const { data } = await api.post<any>(`/UserCustomer/Add`, body);

      return {
        status: true,
        data: data.data,
      };
    } catch (error) {
      if (error instanceof AxiosError) {
        let { severity, status, message, data } = returnResponse({
          statusCode: error.response?.status,
          data: error?.response?.data,
          message:
            error?.response?.data?.message ??
            error?.response?.data?.errors?.[0]?.message,
        });

        return {
          severity,
          status,
          message: message ?? "Error",
          data,
        };
      }

      return {
        status: false,
        message: "Error",
        data: null,
        severity: "error",
      };
    }
  },
  upDate: async (body: any, userId: number) => {
    try {
      const { data } = await api.patch<any>(
        `/UserCustomer/Update?id=${userId}`,
        body
      );

      return {
        status: true,
        data: data.data,
      };
    } catch (error) {
      if (error instanceof AxiosError) {
        let { severity, status, message, data } = returnResponse({
          statusCode: error.response?.status,
          data: error?.response?.data,
          message: error?.response?.data?.errors[0]?.message,
        });

        return {
          severity,
          status,
          message: message ? message : "Error",
          data,
        };
      }

      return {
        status: false,
        message: "Error",
        data: null,
        severity: "error",
      };
    }
  },

  put: async (userUnit: any) => {
    try {
      const body = {
        ...userUnit,
      };
      const { data } = await api.put<any>(
        `/UserCustomerPoliceUnit/Update`,
        body
      );

      return {
        status: true,
        data: data,
      };
    } catch (error) {}
  },
  postUnit: async (unitList: any) => {
    try {
      const { data } = await api.post<any>(
        `/UserCustomerPoliceUnit/AddAll`,
        unitList
      );

      return {
        status: true,
        data: data,
      };
    } catch (error) {}
  },

  putUnit: async (registerId: number, value: boolean) => {
    try {
      const body = [
        {
          op: "replace",
          value: value,
          path: "active",
        },
      ];

      const { data } = await api.patch(
        `/UserCustomerPoliceUnit/Update?id=${registerId}`,
        body
      );

      return {
        status: true,
        data: data,
      };
    } catch (error) {
      if (error instanceof AxiosError) {
        let { severity, status, message, data } = returnResponse({
          statusCode: error.response?.status,
          data: error?.response?.data,
          message: error?.response?.data?.errors[0]?.message,
        });

        return {
          severity,
          status,
          message: message ? message : "Error",
          data,
        };
      }

      return {
        status: false,
        message: "Error",
        data: null,
        severity: "error",
      };
    }
  },
  getAllPoliceChief: async (policeUnitId: number) => {
    try {
      const { data } = await api.get(
        `/UserCustomer/GetAllByRole?policeUnitId=${policeUnitId}`
      );

      return {
        success: true,
        body: data.data,
      };
    } catch (error) {
      return {
        success: false,
        body: {},
      };
    }
  },
  get: async (userId: number) => {
    try {
      const { data } = await api.get<any>(
        `/UserCustomer/Get?id=${userId}`    
      );

      return {
        status: true,
        data: data.data,
        message: data.message,
      };
    } catch (err) {
      return {
        status: false,
        data: null,
        message: "Houve um erro ao consultar os registros.",
      };
    }
  },
};
