import React, { createContext, useContext, useState } from 'react';

// Define a type for the loading context
type LoadingContextType = {
  loadingProfile: boolean;
  setLoadingProfile: React.Dispatch<React.SetStateAction<boolean>>;
};

// Create the loading context
const LoadingContext = createContext<LoadingContextType | undefined>(undefined);

// Create a custom hook to access the loading context
export const useLoadingProfileContext = () => {
  const context = useContext(LoadingContext);
  if (!context) {
    throw new Error('useLoadingContext must be used within a LoadingProvider');
  }
  return context;
};

// Loading provider component
export const LoadingProvider: React.FC<any> = ({children}) => {
  const [loadingProfile, setLoadingProfile] = useState(false);

  return (
    <LoadingContext.Provider value={{ loadingProfile, setLoadingProfile }}>
      {children}
    </LoadingContext.Provider>
  );
};
