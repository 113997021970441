import ReactModal from 'react-modal';
import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  top: 5px;
  right: 5px;
  z-index: 1000;
`;

export const customStyles = {
  content: {
    border: 'none',
    backgroundColor: '#99999999',
    opacity: '0 !important',
    width: '0',
    inset: 'auto',
    padding: 0,
    top: 0,
    left: 0,
    borderRadius: 0,
    zIndex: 1000
    // position: 'initial',
  },
  overlay: {
    backgroundColor: 'none',
    width: '0',
    display: 'flex',
    justifyContent: 'flex-end',
    zIndex: 1000
  },
};
export const StyledModal = styled(ReactModal)`
  .ReactModal__Overlay {
    z-index: 9999;
  }
`;
