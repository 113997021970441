import { InputTextArea } from "../../../../components/InputTextArea/index";
import {
  Container,
  ContainerBody,
  ContainerInputs,
} from "../../StyleCSS/bodyCreateEdit";

interface iCreateEditCustomerCertificate {
  valueCertificate: string;
  setCertificate: (e: string) => unknown;
  fieldError: boolean;
  startFocus?: boolean;
  width?: number;
  height?: number;
  label: string;
  required?: boolean;
}

export const CreateEditCustomerCertificate = (
  props: iCreateEditCustomerCertificate
): JSX.Element => {
  return (
    <>
      <Container>
        <ContainerBody>
          <ContainerInputs>
            <InputTextArea
              error={props.fieldError}
              label={props.label}
              value={props.valueCertificate}
              onChange={(e) => props.setCertificate(e)}
              required={props.required}
              width={props.width}
              height={props.height}
              startFocus={props.startFocus}
            />
          </ContainerInputs>
        </ContainerBody>
      </Container>
    </>
  );
};
