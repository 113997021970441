import React, { useEffect, useState } from "react";
import api from "../../services/api";
import { InputGeneral } from "../../components/Input";
import { DropdownGlobal } from "../../components/DropdownGlobal";
import { InputDateGlobal } from "../../components/InputDateGlobal";
import {
  Container,
  ContainerBody,
  ContainerInputs,
} from "../../pages/app/StyleCSS/bodyCreateEdit";
import { ContainerInputsDate } from "./style";
import { getValueFromlocalStorage } from "../../utils/sessionStorageEncrypt";
import { DatePickerKenta } from "../../components/DatePicker";

type IErrorsFields = {
  procedureNumber: boolean;
  finalDate: boolean;
  date: boolean;
  procedureType: boolean;
  procedureOrigin: boolean;
};

export type IProcedureType = {
  errorFields: IErrorsFields;
  isEdit?: boolean;
  procedureNumber?: string;
  editProcedureTypeName?: string;
  editProcedureOriginName?: string;
  itemData?: any;
  hearingList?: Array<any>;
  setProcedureNumber: (e: string) => unknown;
  setDate: (e: string | null) => unknown;
  setFinalDate: (e: string | null) => unknown;
  setProcedureTypeId: (e: number) => unknown;
  setProcedureOriginId: (e: number) => unknown;
  finalDate: any;
  date: any;
  procedureOriginId: any;
  procedureTypeId: any;
  active: boolean;
  onChangeSwitch: (e: boolean) => unknown;
};

export const ProcedureDetail: React.FC<IProcedureType> = ({
  procedureNumber = "",
  setProcedureNumber,
  editProcedureTypeName,
  editProcedureOriginName,
  setDate,
  setFinalDate,
  date,
  finalDate,
  setProcedureTypeId,
  procedureTypeId,
  setProcedureOriginId,
  procedureOriginId,
  itemData,
  errorFields,
}): JSX.Element => {
  const [procedureTypeList, setProcedureTypeList] = useState<any>([]);
  const [procedureOriginList, setProcedureOriginList] = useState([]);
  //const [expectedDate, setExpectedDate] = useState<string>("");
  //const [deadLineDate, setDeadLineDate] = useState<string>("");

  const user = getValueFromlocalStorage("@Kenta:user");

  useEffect(() => {
    (async () => {
      if (user) {
        // if (itemData) {
        //   const [expected] = itemData.expectedDate.split("T");
        //   const [dead] = itemData.deadLineDate.split("T");

        //   setExpectedDate(expected);
        //   setDeadLineDate(dead);
        // }

        const user_parsed = JSON.parse(user);

        const { data: type } = await api.get(
          `/ProcedureCustomerType/GetAllByCustomerId?customerId=${user_parsed.customerId}`
        );

        const { data: origin } = await api.get(
          `/ProcedureCustomerSource/GetAllByCustomerId?customerId=${user_parsed.customerId}`
        );

        const typeList: any = [];
        type.data.forEach((element: any) => {
          if (element.active) typeList.push(element);
        });

        setProcedureTypeList(typeList);
        setProcedureOriginList(origin.data.filter((e: any) => e.active));
      }
    })();
  }, []);

  useEffect(() => {
    //setExpectedDate(date);
  }, [date]);

  useEffect(() => {
    //setDeadLineDate(finalDate);
  }, [finalDate]);

  function handleKeyPress(event: any) {
    if (
      event.key === "e" ||
      event.key === "E" ||
      event.key === "," ||
      event.key === "."
    ) {
      event.preventDefault();
    }
  }

  return (
    <Container>
      <ContainerBody>
        <ContainerInputs>
          <InputGeneral
            error={!procedureNumber && errorFields.procedureNumber}
            value={procedureNumber}
            onChange={(e) => {
              if (e.length < 9) setProcedureNumber(e);
            }}
            type="number"
            label="Número do procedimento"
            required={true}
            onKeyPress={(e: any) => handleKeyPress(e)}
          />
        </ContainerInputs>
        <ContainerInputsDate>
          {/* <InputDateGlobal
            error={!expectedDate && errorFields.date}
            value={expectedDate}
            onChange={setDate}
            type="date"
            label="Data de abertura"
            required={true}
            limitToday={true}
          />
          <InputDateGlobal
            marginLeft={12}
            error={!deadLineDate && errorFields.finalDate}
            value={deadLineDate}
            onChange={setFinalDate}
            type="date"
            label="Prazo"
            required={true}
            limitToday={true}
            deadlineDate={date}
          /> */}
          <DatePickerKenta
            error={errorFields.date}
            value={date}
            onChange={(e: string) => setDate(e)}
            label="Data de abertura"
            required={true}
            limitToday={true}
          />
          <DatePickerKenta
            marginLeft={12}
            error={errorFields.finalDate}
            value={finalDate}
            onChange={(e: string) => setFinalDate(e)}
            label="Prazo"
            required={true}
            limitToday={true}
            deadlineDate={date}
          />
        </ContainerInputsDate>
        <ContainerInputsDate>
          <DropdownGlobal
            error={!procedureTypeId && errorFields.procedureType}
            editDropdown={editProcedureTypeName}
            setSelected={setProcedureTypeId}
            labelName="Tipo de procedimento"
            options={procedureTypeList}
            required={true}
          />
        </ContainerInputsDate>
        <ContainerInputsDate>
          <DropdownGlobal
            error={!procedureOriginId && errorFields.procedureOrigin}
            editDropdown={editProcedureOriginName}
            setSelected={setProcedureOriginId}
            options={procedureOriginList}
            labelName="Origem"
            required={true}
          />
        </ContainerInputsDate>
      </ContainerBody>
    </Container>
  );
};
