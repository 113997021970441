import styled from "styled-components";
import { stylesGlobalDefault } from "../../global/globalStyle";

export const Container = styled.div`
  height: 89%;
  overflow-y: auto;
  overflow-x: hidden;

  padding: 4px 8px 8px 8px;
  border-right: 1px solid #ecf0f3;

  @media screen and (min-height: 850px) {
    height: 93%;
  }

  /* width */
  ::-webkit-scrollbar {
    width: 7px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    //background: #f1f1f1;
    background: ${ stylesGlobalDefault.white };
    margin-top: 16px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${ stylesGlobalDefault.blue };
    border-radius: 2px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    //background: #555;
    background: ${ stylesGlobalDefault.gray };
  }
`;

export const Body = styled.div`
height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 8px;
`;

export const Empty = styled.div`
  width: 100%;
  text-align: center;
  font-size: 14px;
  color: #7887a9;
  margin-top: 24px;
  font-family: ${ stylesGlobalDefault.fontFamily };
`;
