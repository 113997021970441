import styled from "styled-components";
import { stylesGlobalDefault } from "../../../global/globalStyle";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const ContainerCircle = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 100px;
  background-color: ${stylesGlobalDefault.green};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 30;
`;

export const Label = styled.label`
  font-weight: normal;
  font-size: 50px;
  color: ${stylesGlobalDefault.white};
`;
