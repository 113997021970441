import styled from "styled-components";
import { stylesGlobalDefault } from "../../../global/globalStyle";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 34%;
  margin: 8px;
  height: 260px;
  border-radius: 10px;
  /* background-color: ${stylesGlobalDefault.backgroundListItemColor}; */
  background-color: #f5f5f5;
  border: 1px solid ${stylesGlobalDefault.backgroundListItemColor};
  

  @media (max-width: 2700px) {
    width: 24%;
    margin: 8px;
    /* margin-top: -100px; */
  }

  @media screen and (max-width: 1700px) {
  width: 24%;
  margin: 8px;
  }

  @media screen and (max-width: 1440px) {
  width: 24%;
  margin: 8px;
  }

  @media (max-width: 1280px) {
  width: 24%;
  margin-top: 8px;
  }
 
`;

export const ContainerChart = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;
  margin-left: 8px;
`;

export const ContainerTitle = styled.div`
  width: 98%;
  height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding-left: 8px;
  padding-top: 4px;
  margin: 4px;
  margin-left: 20px;
  /* border-bottom: 1px solid #807E7E; */
`;

export const Circle = styled.circle`
  fill: none;
  stroke-width: 10;
  transform-origin: center;
`;

export const Label = styled.label`
  font-size: 16px;
`;

export const Divider = styled.div`
  width: 98%;
  height: 1px;
  background-color: #e3e3e3;
`;

export const ContainerItem = styled.div`
width: 100%;
height: 35%;
padding: 0px 4px 0px 12px;
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
`;

export const Column = styled.div`
width: 50%;
height: 100%;
display: flex;
align-items: center;
`;

export const ContainerIcon = styled.div`
  width: 34px;
  height: 34px;
  border-radius: 20px;
  background-color: mediumslateblue;
`;
