import React, { useEffect, useState } from "react";
import {
  Container,
  ContainerDateText,
  ContainerImg,
  Description,
  ImgDiv,
  InfoArea,
  TextArea,
  Title,
} from "./styles";
import FileIcon from "../../assets/svg/FileIcon.svg";
// import FileIconS from "../../assets/svg/FileIcon.svg";
import DownloadIcon from "../../assets/hearingDetails/download.svg";
import CameraArquivos from "../../assets/svg/camera-arquivos.svg";
import Video from "../../assets/svg/VideoIcon.svg";
import { Circle } from "../../features/HearingDetailsSidebar/Transcription/styles";

interface Props {
  name?: string;
  description?: string;
  onClick: () => void;
  tipo: number;
  infoAreaWidth?: number;
  infoAreaHeight?: number;
  titleTooltip?: string;
  showButton?: boolean;
  onLoadList?: () => unknown;
  openModal?: boolean;
}

export function CardDownloadFile({
  name,
  description,
  onClick,
  tipo,
  infoAreaWidth,
  infoAreaHeight,
  titleTooltip,
  showButton,
  onLoadList,
  openModal,
}: Props) {
  const [circle, setCircle] = useState(false);

  function retornarIcone() {
    if (tipo === 1) {
      return Video;
    }
    if (tipo === 2) {
      return CameraArquivos;
    }

    if (tipo === 3) {
      return CameraArquivos;
    }

    if (tipo === 4) {
      return FileIcon;
    }
    if (tipo === 5) {
      return Video;
    }
  }
  
  return (
    // <Container selected={false} className="schedule" onClick={onClick}>
    <Container selected={false} className="schedule">
      <ContainerDateText>
        <ImgDiv>
          <img src={retornarIcone()} alt="" width={40} height={40} />
        </ImgDiv>
        <TextArea style={{ width: "100%" }}>
          <Title>{name ? name : "Titulo"}</Title>
          <Description>{description ? description : "Descricao"}</Description>
        </TextArea>
      </ContainerDateText>
      {!showButton ? (
        <InfoArea
          onClick={onClick}
          width={infoAreaWidth}
          heigth={infoAreaHeight}
        >
          <img
            style={{
              width: 16,
              height: 16,
            }}
            src={DownloadIcon}
            alt=""
            data-toggle="tooltip"
            title={titleTooltip}
          />
        </InfoArea>
      ) : (
        <Circle
          selected={circle}
          onClick={() => {
            setCircle(true);
            onClick();
          }}
        ></Circle>
      )}
    </Container>
  );
}
