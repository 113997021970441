import React, { Fragment, useState } from "react";

import { AdminArea } from "../../../components/Body/AdminArea";
import ModalRight from "../../../components/ModalRight";
import { FORMS_ADMIN } from "../../../utils/entities/permission";
import { useNavigate } from "react-router-dom";
import { ImportModal } from "./ImportarModal";
import { SispServices } from "../../../services/sisp";
import { useToast } from "../../../hooks/toast";
import { IImportBody } from "../../../services/sisp/interface";
import { hideLoading, showLoading } from "../../../store/modules/login/actions";
import { useDispatch } from "react-redux";

const SispIntegration: React.FC = () => {
  const navigate = useNavigate();

  const dNow = new Date();

  const [procedureList, setProcedureList] = useState([]);
  const [unitList, setUnitList] = useState([]);
  const [selectedProcedure, setSelectedProcedure] = useState<any>();
  const [selectedUnit, setSelectedUnit] = useState<any>();
  const [year, setYear] = useState<string>(String(dNow.getFullYear()));
  const [procedureNumber, setProcedureNumber] = useState<string>("");
  const [fieldError, setFieldError] = useState<boolean>(false);
  const [show, setShow] = useState(false);

  const dispatch = useDispatch();
  const { addToast } = useToast();

  const handleImport = async () => {
    if (
      !selectedUnit ||
      !selectedProcedure ||
      !year ||
      year.length < 4 ||
      !procedureNumber
    ) {
      setFieldError(true);
      return addToast({
        type: "warning",
        title: "Aviso",
        description: "Preencha todos os campos destacados",
      });
    }

    const body: IImportBody = {
      unidade_sisp: Number(selectedUnit.foreignKey),
      numero_procedimento: Number(procedureNumber),
      ano_procedimento: Number(year),
      id_modulo: Number(selectedProcedure.foreignKey),
    };

    addToast({
      type: "success",
      title: "Em andamento",
      description: "Sua importação está em andamento",
    });

    dispatch(showLoading());

    const { success, message } = await SispServices.importBySisp(body);

    if (!success) {
      return addToast({
        type: "warning",
        title: "Aviso",
        description: message,
      });
    }

    setShow(false);
    addToast({
      type: "success",
      title: "Sucesso",
      description: message,
    });

    dispatch(hideLoading());
  };

  return (
    <Fragment>
      <AdminArea
        title={"Importação de ocorrência"}
        InputSearchLabel="Buscar por:"
        showSearch={false}
        InputSearchOnChange={(text) => {}}
        situation={false}
        list={[]}
        headerAddButtonOnClick={() => setShow(true)}
        onClick={(item: any) => {}}
        setItemReturnClick={() => {}}
        setOpenModal={() => {}}
        setIsEdit={() => {}}
        onChangeSituation={(value: any) => {}}
        showCreateButton={true}
        permissionName={FORMS_ADMIN.IMPORT_SISP}
        headerTrashButtonOnClick={() => console.log("open")}
      />
      <ModalRight
        isOpen={show}
        setIsOpen={() => setShow(false)}
        isEdit={false}
        title={"Importar dados do SISP"}
        onClickAplicar={handleImport}
        showIconDelete={false}
      >
        <ImportModal
          setProcedureList={setProcedureList}
          setUnitList={setUnitList}
          setYear={setYear}
          setProcedureNumber={setProcedureNumber}
          procedureList={procedureList}
          unitList={unitList}
          year={year}
          procedureNumber={procedureNumber}
          setSelectedProcedure={setSelectedProcedure}
          setSelectedUnit={setSelectedUnit}
          fieldError={fieldError}
        />
      </ModalRight>
    </Fragment>
  );
};

export default SispIntegration;
