import styled from "styled-components";

interface IMargin {
  hasPermission: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 80px;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const ContainerBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 80%;
`;

export const ContainerInputs = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 25px;
`;

export const ContainerInputsDate = styled.div<IMargin>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-right: ${(props) => (props.hasPermission ? 8 : 0)}px;
`;

export const ContainerWithButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
