import { useEffect, useState } from "react";
import Modal from "react-modal";
import close from "../../assets/svg/close.svg";
import { showLoading, hideLoading } from "../../store/modules/login/actions";
import ReactLoading from "react-loading";
import {
  Container,
  ContainerHeader,
  ContainerMenu,
  customStyles,
  Label,
  Subtitle,
  TitleHeader,
  styleImage,
  avatarStyle,
  ContainerClose,
  VersionContainer,
  TitleMenu,
  Separator,
  LabelLink,
} from "./style";

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Avatar from "react-avatar";
import { logout } from "../../services/auth";
import { PictureContainer } from "../HeaderUp/styles";
import { storageBaseUrl } from "../../services/api";
import { LogOperation } from "../../utils/entities/logOperation";
import ModalRight from "../ModalRight";
import { MyAccount } from "../../features/MyAccount";
import { deleteFromlocalStorage } from "../../utils/sessionStorageEncrypt";
import logo from "../../assets/logo.png";
import { updateUser } from "../../store/modules/user/actions";
import { setScreenStatus } from "../../store/modules/screen_controll/actions";
import { getValueFromlocalStorage } from "../../utils/sessionStorageEncrypt";

interface ModalType {
  title?: string;
  isOpen: boolean;
  userName: string;
  profile: string;
  user: any;
  onClickDelete?: () => void;
  onClickAplicar?: () => void;
  onClose: () => void;
  changePicture: boolean;
  setChangePicture: (e: boolean) => void;
}
export const onClickSair = async ({
  setIsLoading,
  dispatch,
  navigate,
}: any) => {
  dispatch(showLoading());
  setIsLoading(true);
  await LogOperation.postLogOperation("Logoff", 5, 0);
  const user = getValueFromlocalStorage("@Kenta:user");
  if (user) {
    const parsedUser = JSON.parse(user);
    localStorage.removeItem("IndexProfileSelected");

    //removendo time token caso exista
    deleteFromlocalStorage("timeToCount", "encrypt-time");
    await logout(parsedUser.hash);
  }

  localStorage.clear();
  localStorage.clear();
  navigate("/");
  setIsLoading(false);
  dispatch(hideLoading());
};

export default function ProfileModal(props: ModalType) {
  const [isLoading, setIsLoading] = useState(false);
  const [userName, setUserName] = useState(props.userName);
  const [userItem, setUserItem] = useState<any>();
  const [unit, setUnit] = useState<any>();
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const { policeUnit } = useSelector((state: any) => state.policeUnit);
  const [openModal, setOpenModal] = useState<boolean>(false);

  const { user: userRedux } = useSelector((e: any) => e.user);
  const [user, setUser] = useState(props.user);

  const searchUser = () => {
    const user = getValueFromlocalStorage("@Kenta:user");
    const unitStorage = policeUnit;

    if (user) {
      const passedUser = JSON.parse(user);
      setUserName(passedUser.name);
      setUserItem(passedUser);

      if (!userRedux) {
        dispatch(updateUser(passedUser));
      }
    }

    if (unitStorage) {
      setUnit(unitStorage);
    }
  };

  useEffect(() => {
    if (props.isOpen === true) searchUser();
  }, [props.isOpen]);

  useEffect(() => {
    searchUser();
  }, [props.changePicture]);

  function clearAllCookies() {
    const cookies = document.cookie.split(";");

    for (const cookie of cookies) {
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
      document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
    }
  }

  const [onClickModal, setOnClickModal] = useState<boolean>(false);

  return (
    <>
      <Modal
        testId="profile_modal_container"
        isOpen={props.isOpen}
        style={{ ...customStyles }}
        contentLabel="Example Modal"
        ariaHideApp={false}
        shouldCloseOnEsc={true}
      >
        <Container>
          <ContainerClose>
            <img
              src={close}
              style={{ ...styleImage }}
              onClick={() => props.onClose()}
              alt=""
            />
          </ContainerClose>
          <ContainerHeader>
            {userItem && userItem.picture && user && unit ? (
              <PictureContainer
                size={60}
                picture={`${storageBaseUrl}/${unit.containerName}/${user.picture}`}
                style={{ cursor: "default" }}
              />
            ) : (
              <Avatar
                name={userItem ? userItem.name : "Avatar"}
                size="60"
                round={true}
                style={{ ...avatarStyle }}
              />
            )}
            <TitleHeader>{userName}</TitleHeader>
            <Subtitle>{props.profile}</Subtitle>
          </ContainerHeader>
          {isLoading ? (
            <ReactLoading type="spin" color="#F2A60D" width={24} height={24} />
          ) : (
            <ContainerMenu>
              <Label
                style={{ cursor: "pointer" }}
                onClick={() => setOpenModal(!openModal)}
              >
                Minha conta
              </Label>
              <Separator />
              <TitleMenu>Suporte</TitleMenu>
              <LabelLink
                href="https://www.kenta.com.br/contato/"
                target="blank"
              >
                Contato do suporte
              </LabelLink>
              {/* 1.features.bugs */}
              <Label>Versão 1.46.221 - 02/10/2023</Label>
              <Separator />
              <Label
                id="profile_modal_sair"
                onClick={() => {
                  onClickSair({ setIsLoading, dispatch, navigate });
                  dispatch(setScreenStatus(false));
                  clearAllCookies();
                }}
                style={{
                  cursor: "pointer",
                }}
              >
                Sair
              </Label>
            </ContainerMenu>
          )}
          <VersionContainer>
            <img src={logo} alt="" />
          </VersionContainer>
        </Container>
      </Modal>
      <ModalRight
        title="Minha Conta"
        isOpen={openModal}
        setIsOpen={() => setOpenModal(!openModal)}
        //haveFooter={false}
        onClickAplicar={() => setOnClickModal(true)}
      >
        <MyAccount
          onClickModal={onClickModal}
          setOnClickModal={setOnClickModal}
          changePicture={props.changePicture}
          setChangePicture={props.setChangePicture}
        />
      </ModalRight>
    </>
  );
}
