import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

export default function SkeletonDashboardNormal(){
    return (
        <div style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
            height: "100%",
            flexWrap: "wrap",
            overflow: "auto",
            paddingLeft: 12,
            paddingRight: 12
        }}>
           
            <Skeleton height={200} width={340} count={2} style={{
                marginTop: 8,
                
            }}/>
            <Skeleton height={200} width={340} count={2} style={{
                marginTop: 8
            }}/>
            
              <Skeleton height={200} width={330} count={2} style={{
                marginTop: 8
            }}/>
            <Skeleton height={200} width={340} count={2} style={{
                marginTop: 8,
                
            }}/>
         
        </div>
    )
}