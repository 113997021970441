import styled from "styled-components";
import { stylesGlobalDefault } from "../../global/globalStyle";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
  align-items: center;
  justify-content: space-between;
  border: 2px solid ${stylesGlobalDefault.backgroundListItemColor};
  padding: 4px 16px 4px 4px;
  border-radius: 8px;

  &:hover {
    border: 2px solid ${stylesGlobalDefault.blue};
    opacity: 1;

    .icons {
      opacity: 1;
      display: block;
    }
  }
`;

export const ContainerDateText = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const DateArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 52px;
  background: ${ stylesGlobalDefault.inputsBorderFocusOff };
  border-radius: 4px;
  margin-right: 12px;
`;

export const Day = styled.div`
  font-size: 12px;
  font-family: ${ stylesGlobalDefault.fontFamily };
  font-weight: 700;
  color: ${ stylesGlobalDefault.black };
`;

export const Hour = styled.div`
  font-size: 12px;
  font-family: ${ stylesGlobalDefault.fontFamily };
  font-weight: 400;
  color: ${ stylesGlobalDefault.gray };
  margin-top: 4px;
`;
export const TextArea = styled.div``;

export const Title = styled.div`
  font-size: 12px;
  font-family: ${ stylesGlobalDefault.fontFamily };
  font-weight: 600;
  color: ${ stylesGlobalDefault.black };
`;
export const Description = styled.div`
  font-size: 12px;
  font-family: ${ stylesGlobalDefault.fontFamily };
  font-weight: 400;
  color: ${ stylesGlobalDefault.gray };
  margin-top: 4px;
`;

export const IconArea = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  opacity: 0;
  display: none;

  div {
    display: flex;
    flex-direction: row;
  }

  button {
    display: flex;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    background: ${stylesGlobalDefault.blue};
    margin-right: 8px;

    border: 0;

    img {
      width: 18px;
    }

    cursor: pointer;
  }

  img {
    cursor: pointer;
  }

  img + img {
    margin-left: 8px;
  }
`;
