/* eslint-disable @typescript-eslint/no-explicit-any */
import Modal from "react-modal";
import { Toast } from "../../components/Toast";
import { Container, customStyles } from "./styles";

interface IMessage {
  id: string;
  type?: "success" | "error" | "warning";
  title: string;
  description?: string;
  timeLimitToast?: number
}

interface IToastList {
  messages: Array<IMessage>;
}

export const ToastList = ({ messages }: IToastList) => {
  return (
    <Modal
      ariaHideApp={false}
      isOpen={!!messages.length}
      style={{ ...customStyles }}
      contentLabel="Example Modal"
    >
      <Container id="toast_list">
        {messages.length && (
          <Toast
            id={messages[messages.length - 1].id}
            key={messages[messages.length - 1].id}
            title={messages[messages.length - 1].title}
            type={messages[messages.length - 1].type as any}
            description={messages[messages.length - 1].description}
            timeLimitToast={messages[messages.length - 1].timeLimitToast}
          />
        )}
      </Container>
    </Modal>
  );
};
