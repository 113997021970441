import { useSelector } from "react-redux";
import { DashboardMaster } from "./DashboardMaster"
import { DashboardNormal } from "./DashboardNormal"

interface DashboardProperties {
    isMaster: boolean
}

export function Dashboard() {
    const { policeUnit } = useSelector((state: any) => state.policeUnit);

    
    return (
        <>
            {policeUnit && (policeUnit.profile === "Master" || policeUnit.profile === "Kenta") ?
                <DashboardMaster />
                :
                <DashboardNormal />
            }
        </>
    )
}