import React, { CSSProperties } from "react";
import { Container, PlusIcon } from "./styles";
import plus from "../../assets/procedure/delete.svg";
import dark_delete from "../../assets/procedure/dark_delete.svg";

export type IProps = {
  onClick?: () => unknown;
  style?: CSSProperties;
  id?: string;
  widthHeight?: number;
  disabled?: boolean;
};

export const DeleteButton: React.FC<IProps> = ({
  style,
  onClick,
  id,
  widthHeight,
  disabled,
}) => {
  return (
    <Container
      id={id}
      disabled={disabled}
      style={style}
      onClick={onClick}
      widthHeight={widthHeight}
    >
      <PlusIcon>
        <img src={disabled ? dark_delete : plus} alt="" />
      </PlusIcon>
    </Container>
  );
};
