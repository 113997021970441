import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { UserCustomerProfileService } from "../../services/user_customer_profile";
import { UserCustomerProfile } from "../../services/user_customer_profile/types";
import {
  ADMIN_ROUTES_BY_FORM_PERMISSION,
  FORMS_ADMIN,
  FORMS_RELATORIOS,
  Permission,
} from "../../utils/entities/permission";
import ProfileModal from "../../components/ProfileModal";
import { HeaderUp } from "../../components/HeaderUp";
import {
  returnPermissionsByFuncionality,
  returnReportPermisison,
  usePermissions,
} from "../../hooks/permission";
import { DownloadModal } from "../Download";
import { updateUser } from "../../store/modules/user/actions";
import { userService } from "../../services/users";
import {
  systemObject,
  systemObjectsSubMenus,
} from "../../services/systemObject";
import { listOperation } from "../../utils/listsOperations";
import { updateGlobalConfigurationsAction } from "../../store/modules/globalConfiguration/actions";
import { getValueFromlocalStorage } from "../../utils/sessionStorageEncrypt";

export type HeaderUpMenuProps = {
  item: string;
};

interface User {
  id: number;
  picture?: string;
  name: string;
  customerId: number;
}

export const HeaderUpMenu = ({
  qtdNotification,
  sendMessageNotification,
}: {
  qtdNotification: number;
  sendMessageNotification?: (userId: string) => any;
}) => {
  const [isOpenSideModal, setIsOpenSideModal] = useState(false);
  const [activeItemMenu, setActiveItemMenu] = useState("Procedimentos");
  const [userCustomerProfiles, setUserCustomerProfiles] = useState<
    UserCustomerProfile[]
  >([]);
  const [user, setUser] = useState<User | null>();
  const [unit, setUnit] = useState<any | null>();
  const userStorage = getValueFromlocalStorage("@Kenta:user");
  const { policeUnit } = useSelector((state: any) => state.policeUnit);
  const { permissions } = useSelector((state: any) => state.permissions);
  const [firstAdminRoutePermitted, setFirstAdminRoutePermitted] = useState("");
  const [firstAdminScreen, setFirstAdminScreen] = useState("");
  const [firstReportScreen, setFirstReportScreen] = useState("");
  const [firstRelatorioRoutePermitted, setFirstRelatorioRoutePermitted] =
    useState("");
  const [haveAdminRole, setHaveAdminRole] = useState(false);
  const [haveRelatorio, setHaveRelatorioRole] = useState(false);
  const [openDownload, setOpenDownload] = useState(false);
  const location = useLocation();
  const {
    proceduresPerYear,
    proceduresByPoliceStation,
    proceduresPerPoliceUnit,
    logOperation,
    logErrors,
  } = usePermissions(FORMS_RELATORIOS.REPORT);

  const [changePicture, setChangePicture] = useState<boolean>(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const unitStorage = policeUnit;

  const searchProfiles = async () => {
    const token = getValueFromlocalStorage("@Kenta:access_Token");

    if (!userStorage) {
      return;
    }

    if (!token || !user || !user.customerId) {
      return;
    }

    const response = await UserCustomerProfileService.getAllByUserCustomer(
      token,
      user.id
    );

    if (!response.status) {
      console.log("erro", response.message);
    } else {
      if (!response.data) return;

      setUserCustomerProfiles(response.data);
    }
  };

  function verifyAdministrationRole() {
    let haveAnyAdminPermission = false;
    let permissionsTyped = permissions as Permission[];

    for (let form of Object.values(FORMS_ADMIN)) {
      if (
        permissionsTyped.length > 0 &&
        permissionsTyped.filter((item) => item.form === form).length > 0 &&
        permissionsTyped.filter((item) => item.form === form)[0]
          .listObjectUserProfileActionDTOs.length > 0
      ) {
        haveAnyAdminPermission = true;
        setFirstAdminRoutePermitted(
          ADMIN_ROUTES_BY_FORM_PERMISSION.filter(
            (item) => item.formAdminPermissionName === form
          )[0].route
        );
        if (location.pathname.includes("/dashboard/administracao")) {
          // const adminRouter = localStorage.getItem("Kenta:admin-router");
          // if (adminRouter) navigate(adminRouter);
          // else navigate("dashboard/procedure/");
        }
        break;
      } else continue;
    }

    return haveAnyAdminPermission;
  }

  function verifyRelatorioRole() {
    let haveAnyRelatorioPermission = false;
    let permissionsTyped = permissions as Permission[];

    for (let form of Object.values(FORMS_RELATORIOS)) {
      if (
        permissionsTyped.length > 0 &&
        permissionsTyped.filter((item) => item.form === form).length > 0 &&
        permissionsTyped.filter((item) => item.form === form)[0]
          .listObjectUserProfileActionDTOs.length > 0
      ) {
        let formItem = permissionsTyped.filter((item) => item.form === form)[0];
        formItem.listObjectUserProfileActionDTOs.forEach((element) => {
          let finderPermissionReport = permissionsTyped.find(
            (x) => x.form === element.action
          );
          if (finderPermissionReport) {
            let havePermission =
              finderPermissionReport.listObjectUserProfileActionDTOs.find(
                (f) => f.action === "Read"
              );
            if (havePermission) {
              haveAnyRelatorioPermission = true;
              return haveAnyRelatorioPermission;
            }
          }
        });

        if (
          permissionsTyped
            .filter((item) => item.form === form)[0]
            .listObjectUserProfileActionDTOs.filter((item) => item.action == "")
            .length > 0
        ) {
          continue;
        }
        let firstRouteReport = "";

        if (logErrors) {
          firstRouteReport = "LogError";
        } else if (logOperation) {
          firstRouteReport = "LogOperation";
        } else if (proceduresByPoliceStation || proceduresPerPoliceUnit) {
          firstRouteReport = "proceduresByPoliceStation";
        } else if (proceduresPerYear) {
          firstRouteReport = "proceduresPerYear";
        }

        setFirstRelatorioRoutePermitted(
          `/dashboard/relatorio/${firstRouteReport}`
        );
        if (location.pathname.includes("/dashboard/relatorio")) {
          navigate(`/dashboard/relatorio/${firstRouteReport}`);
        }
        break;
      } else continue;
    }

    return haveAnyRelatorioPermission;
  }

  // Qual a primeira tela quando clica no menu
  function getMenuStorage() {
    let menuStorage = localStorage.getItem("KentaMenu");
    let globalConfigurations = localStorage.getItem("globalConfiguration");
    if (menuStorage) {
      const menuGlobal = JSON.parse(menuStorage);

      if (globalConfigurations) {
        const globalConfigurationsParsed = JSON.parse(globalConfigurations);

        dispatch(updateGlobalConfigurationsAction(globalConfigurationsParsed));
      }

      return menuGlobal;
    }
  }

  useEffect(() => {
    let menuStorage = getMenuStorage();
    let menuMaked = makeMenu(menuStorage);
    setFirstItemWithPermission(menuMaked ?? []);
  }, [permissions, policeUnit]);

  function setFirstItemWithPermission(
    menuListItems: Array<systemObjectsSubMenus>
  ) {
    if (menuListItems && menuListItems.length > 0) {
      for (let index = 0; index < menuListItems.length; index++) {
        for (
          let indexSubMenu = 0;
          indexSubMenu < menuListItems[index].list.length;
          indexSubMenu++
        ) {
          if (menuListItems[index].title === "Logs") {
            let firstWithPermissionRead =
              menuListItems[index].list[indexSubMenu];

            const isReport =
              firstWithPermissionRead.navigate.includes("relatorio");

            if (isReport) {
              localStorage.setItem(
                "Kenta:report-router",
                firstWithPermissionRead.navigate
              );
              setFirstReportScreen(firstWithPermissionRead.navigate);
            }
          }

          if (menuListItems[index].list[indexSubMenu].permissions.read) {
            let firstWithPermissionRead =
              menuListItems[index].list[indexSubMenu];

            const isAdministration =
              firstWithPermissionRead.navigate.includes("administracao");

            if (isAdministration) {
              localStorage.setItem(
                "Kenta:admin-router",
                firstWithPermissionRead.navigate
              );
              setFirstAdminScreen(firstWithPermissionRead.navigate);
            }
            return;
          }
        }
      }
    }
  }

  // Qual a primeira tela quando clica no menu
  function makeMenu(menus: any) {
    let menu: Array<systemObjectsSubMenus> = [];
    if (menus) {
      let orderByMenu = listOperation.groupBy(
        menus,
        (p: systemObject) => p.url
      );
      const keys = Object.keys(orderByMenu);

      for (let i = 0; i < keys.length; ++i) {
        const key = keys[i];
        let urlSplit = key.split("/");

        if (
          ["Cadastros", "Configurações", "Procedimentos", "Logs"].includes(
            urlSplit[3]
          )
        ) {
          let titleMenu: systemObjectsSubMenus = {
            id: i + 1,
            hasLineBottom: false,
            title: urlSplit[3],
            list: [],
          };

          orderByMenu[key].forEach((element: systemObject) => {
            let permissionsItems = returnPermissionsByFuncionality(
              element.name,
              permissions
            );

            const report = returnReportPermisison({
              name: element.name,
              permissions,
            });

            if (permissionsItems.read || report.read) {
              titleMenu.list.push({
                active: element.active,
                description: element.description,
                name: element.name,
                id: element.id,
                navigate: `/${urlSplit[1]}/${urlSplit[2]}/${element.name}`,
                url: element.url,
                permissions: element.permissions ?? permissionsItems,
              });
            }
          });
          if (titleMenu.list.length) {
            menu.push(titleMenu);
          }
        }
      }

      return menu;
    }
  }

  // Qual a primeira tela quando clica no menu
  useEffect(() => {
    setHaveAdminRole(verifyAdministrationRole());
    setHaveRelatorioRole(verifyRelatorioRole());
  }, [permissions]);

  useEffect(() => {
    searchProfiles();
  }, [user]);

  useEffect(() => {
    if (userStorage) {
      let userStorageImage = JSON.parse(userStorage);

      userStorageImage.picture = userStorageImage.picture
        ? userStorageImage.picture.replace(/\\/gm, "/")
        : "";

      setUser(userStorageImage);
    }

    if (unitStorage) {
      setUnit(unitStorage);
    }
  }, [unitStorage]);

  useEffect(() => {
    if (location.pathname.includes("administracao")) {
      setActiveItemMenu("Administracao");
    }
    if (location.pathname === "/dashboard/procedure") {
      setActiveItemMenu("Procedimentos");
    }
    if (location.pathname.includes("relatorio")) {
      setActiveItemMenu("Relatorios");
    }
  }, [location.pathname]);

  useEffect(() => {
    // 1 - get session storage user
    let useStorage = getValueFromlocalStorage("@Kenta:user");
    // 2 - pegar file name, trocar o image do getsession
    const user = useStorage ? JSON.parse(useStorage) : null;
    if (user) {
      (async () => {
        const { data } = await userService.get(user.id);
        const newUser = {
          ...data,
          hash: user.hash,
          picture:
            data && data.picture ? data.picture.replace(/\\/gm, "/") : null,
        };
        setUser(newUser);
        dispatch(updateUser(newUser));
      })();
    }
  }, []);

  const showDashboard = () => {
    navigate("/dashboard/procedure/");
    window.location.reload();
  };

  return (
    <>
      <HeaderUp
        activeItemMenu={activeItemMenu}
        firstAdminRoutePermitted={firstAdminRoutePermitted}
        haveAdminRole={haveAdminRole}
        haveRelatorioRole={haveRelatorio}
        sendMessageNotification={sendMessageNotification}
        firstRelatorioRoutePermitted={firstRelatorioRoutePermitted}
        unit={unit}
        user={user}
        qtdNotification={qtdNotification}
        userCustomerProfiles={userCustomerProfiles}
        onClickAdministration={() => {
          if (activeItemMenu !== "Administracao") {
            setActiveItemMenu("Administracao");
            navigate(firstAdminScreen);
          }
        }}
        onClickProcedure={() => {
          if (activeItemMenu !== "Procedimentos") {
            setActiveItemMenu("Procedimentos");
            navigate("/dashboard/procedure");
          }
        }}
        onClickRelatorios={() => {
          if (activeItemMenu !== "Relatorios") {
            setActiveItemMenu("Relatorios");
            navigate(`${firstRelatorioRoutePermitted}`);
          }
        }}
        setOpenDownload={setOpenDownload}
        onClickOpenSideModal={(isModalOpen: boolean) =>
          setIsOpenSideModal(isModalOpen)
        }
        showDashboard={() => showDashboard()}
      />
      {userCustomerProfiles && userCustomerProfiles.length > 0 && user ? (
        <ProfileModal
          isOpen={isOpenSideModal}
          onClose={() => setIsOpenSideModal(false)}
          user={user}
          profile={userCustomerProfiles[0].profile}
          userName={user && user.name ? user.name : "user test"}
          changePicture={changePicture}
          setChangePicture={setChangePicture}
        />
      ) : null}
      <DownloadModal
        isOpenDownloadsModal={openDownload}
        setIsOpenDownloadsModal={() => setOpenDownload(!openDownload)}
      />
    </>
  );
};
