import { SELECT_CUSTOMER_PROFILE, SET_IS_ADMIN } from "./actios.types";

export const selectCustomerProfile = (userCustomerProfileId: number) => {
  return {
    type: SELECT_CUSTOMER_PROFILE,
    userCustomerProfileId,
  };
};

export const setIsAdmin = (isAdmin: boolean) => {
  return {
    type: SET_IS_ADMIN,
    isAdmin,
  };
};
