import { AxiosError } from "axios";
import api from "../api";
import { returnResponse } from "../utils";

export const procedureCustomerAttendantService = {
  unbinding: async (procedureCustomerAttendantId: number) => {
    try {
      const { data } = await api.delete(
        `/ProcedureCustomerAttendant/UnbindingAttendant?procedureCustomerAttendantId=${procedureCustomerAttendantId}`
      );

      return {
        success: true,
        body: data.message,
      };
    } catch (error) {
      if (error instanceof AxiosError) {
        console.log(error?.response?.data.errors);
        let { severity, status, message, data } = returnResponse({
          statusCode: error.response?.status,
          data: error?.response?.data,
          message: error?.response?.data.message,
        });

        return {
          success: false,
          severity,
          status,
          body: message ? message : "Error",
          data,
        };
      }

      return {
        status: false,
        message: "Error",
        data: null,
        severity: "error",
      };
    }
  },
};
