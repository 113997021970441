import styled, { css } from "styled-components";

interface ILine {
  disabled?: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  align-items: center;
  width: 100%;
  height: 100%;
  /* padding: 5%; */
`;

export const Hr = styled.hr`
  width: 100%;
  background-color: #d1dbe3;
`;

export const Label = styled.span`
  font-size: 14px;
`;

export const Line = styled.button<ILine>`
  width: 100%;
  display: flex;
  flex-direction: row;
  vertical-align: middle;
  align-items: initial;
  padding-top: 10px;
  padding-right: 0px;
  padding-bottom: 10px;
  padding-left: 0px;
  background: none;
  border: none;
  cursor: pointer;

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.6;
      cursor: default;
    `}
`;

export const VideoContainer = styled.div`
  padding-bottom: 24px;
  padding-top: 24px;
`;

export const Icon = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  padding-right: 15px;
`;

export const ProcessingVideoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 160px;
  width: 100%;
  background: #fff;
  border: 2px solid #000;
  margin-bottom: 10px;

  strong {
    font-size: 14px;
    font-family: "Inter";
    font-weight: 700;
    color: #000;
  }
`;
