import React, { CSSProperties } from "react";
import Blueplus from "../../assets/procedure/blue_plus.svg";
import plus from "../../assets/procedure/plus.svg";
import { Container, PlusIcon } from "./styles";

type NewType = {
  onClick?: () => unknown;
  style?: CSSProperties;
  id?: string;
  icon?: boolean;
  widthHeight?: number;
};

export type IProps = NewType;

export const AddButton: React.FC<IProps> = ({
  onClick,
  id,
  style,
  icon,
  widthHeight,
}) => {
  return (
    <Container
      data-testid="add-button"
      id={id}
      onClick={onClick}
      style={style}
      widthHeight={widthHeight}
    >
      <PlusIcon id="add_button">
        {icon ? <img src={Blueplus} alt="" /> : <img src={plus} alt="" />}
      </PlusIcon>
    </Container>
  );
};
