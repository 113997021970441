export const editorInit = function (exportValue: any) {
  return {
    // eslint-disable-next-line no-restricted-globals
    height: innerHeight - 380,
    language: "pt_BR",
    menubar: false,
    branding: false,
    plugins: [
      "export",
      "advlist",
      "autolink",
      "lists",
      "link",
      "image",
      "charmap",
      "preview",
      "anchor",
      "searchreplace",
      "visualblocks",
      "code",
      "fullscreen",
      "insertdatetime",
      "media",
      "code",
      "wordcount",
    ],
    toolbar:
      `undo redo | ${exportValue ? "export |" : ""} blocks | ` +
      "bold italic forecolor | alignleft aligncenter " +
      "alignright alignjustify | bullist numlist outdent indent | " +
      "removeformat |",
    content_style:
      "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
    setup: function (editor: any) {
      editor.on("skinLoaded", (e: any) => {
        e.preventDefault();
      });
    },
  };
};
