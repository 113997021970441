import styled from 'styled-components';

export const Container = styled.div`
    width: 380px;
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
`;

export const ContainerTitle = styled.div`
    width: 380px;
    height: 22px;
    padding: 12px;
    text-align: center;    
`;

export const ContainerBar = styled.div`
    width: 380px;
    height: 22px;
    
    display: flex;
    justify-content: flex-start;
`;

export const ContainerDescription = styled.div`
    width: 380px;
    height: 40px;
    padding: 12px;
    text-align: center;
`;