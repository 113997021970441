import styled from "styled-components";
import { stylesGlobalDefault } from "../../global/globalStyle";

export const BoxWebCanImage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-bottom: 20px;
  padding-top: 0px;

  position: static;
  border-radius: 50%;
`;

export const WebCanContainer = styled.span`
  /* padding-bottom: 24px;
  padding-top: 0px; */
`;

export const ButtonTakePhoto = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${stylesGlobalDefault.white};
  font-size: 20px;
  height: 40px;

  position: relative;
  padding-top: 0px;
  margin-top: -40px;
  margin-right: -120px;

  cursor: pointer;
`;

export const avatarStyle = {
  height: 150,
  width: 150,
  borderRadius: "50%",
  objectFit: "cover",
};
