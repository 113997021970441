/* eslint-disable no-restricted-globals */
import styled from "styled-components";

export const Container = styled.div`
  display: block;
  width: 100%;
  background: #000;
  display: flex;
  flex-direction: column;
`;

export const VideoContainer = styled.div`
  height: 82.5vh;
 
  @media screen and (min-height: 850px) {
    height: 86vh;
  }
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const VideoText = styled.strong`
  margin-top: 10px;
  font-family: "Inter", Arial, Helvetica, sans-serif;
  font-weight: 700;
  color: #fff;
`;

export const VideoPlayerComponent = styled.video`
  top: 0;
  left: 0;
  position: absolute;

  display: block;
  flex-direction: column;
  width: 100%;
  height: 75vh;


`;
