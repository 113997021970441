import styled from "styled-components";
import { stylesGlobalDefault } from "../../../global/globalStyle";
import { shade } from "polished";

interface IProps {
  height: number;
}
interface IItemSelected {
  selected: boolean;
}

interface IButton {
  width?: number;
}

export const Container = styled.div<IProps>`
  height: ${(props) => props.height - 304}px;
  overflow-y: auto;
  padding-top: 20px;

  @media screen and (min-height: 850px) {
    height: ${(props) => props.height - 302}px;
  }
`;

export const ItemContainer = styled.div<IItemSelected>`
  background: ${(props) => (props.selected ? "#ECF0F3" : "#FFF")};
  padding: 16px;
`;

export const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
`;

export const HeaderTextContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: 0;
  background: none;
`;

export const MarkingTime = styled.span`
  font-size: 12px;
  width: 170px;
  font-family: "Inter", Arial, Helvetica, sans-serif;
  font-weight: 500;
  color: #e98d0b;
  cursor: pointer;
`;

export const MarkingTitle = styled.span`
  font-size: 12px;
  font-family: "Inter", Arial, Helvetica, sans-serif;
  font-weight: 500;
  color: #000;
  margin-left: 5px;
`;
export const MarkingDescription = styled.p`
  font-size: 14px;
  font-family: "Inter", Arial, Helvetica, sans-serif;
  font-weight: 400;
  text-align: justify;
  padding: 5px 5px 0 5px;
`;

export const BottomMarkingTime = styled.div`
  display: flex;
  width: 100% - 24px;
  height: 85px;
  background: #d1dbe3;
  align-items: center;
  justify-content: space-between;
  padding-left: 24px;
  padding-right: 24px;
`;

export const NewMarkingButton = styled.button<IButton>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(props) => (props.width ? props.width : 149)}px;
  height: 34px;
  
  background: ${(props) => (props.disabled ? "none" : "#1d9bf0")};
  /* margin-bottom: 10px; */
  border-radius: 50px;
  
  border: ${(props) =>
    props.disabled ? "2px solid rgba(120, 135, 169, 0.5)" : 0};

  strong {
    font-family: "Inter", Arial, Helvetica, sans-serif;
    color: ${(props) => (props.disabled ? "rgba(120, 135, 169, 0.5)" : "#fff")};
    font-size: 11px;
  }

  cursor: pointer;
  &:hover {
    background: ${shade(0.2, stylesGlobalDefault.blueDark)};
    transition: 0.2s;
    color: ${stylesGlobalDefault.white};
    cursor: pointer;
  }
  &:disabled {
    &:hover {
      cursor: default;
      transition: none;
      background: none;
    }
  }
`;

export const ContainerDelete = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
export const IconsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  img {
    cursor: pointer;
    margin-left: 10px;
  }
`;

export const Empty = styled.div`
  font-size: 14px;
  color: #7887a9;
  margin-top: 24px;
  font-family: "Inter Tight", Arial, Helvetica, sans-serif;
`;
