import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    overflow-y: hidden;
  `;

export const Title = styled.h3`
font-family: "Inter", Arial, Helvetica, sans-serif;
font-weight: 700;
font-size: 18px;
color: #fff;
margin-right: 12px;
`;

export const ContainerTitleWithNumber = styled.div`
display: flex;
align-items: center;
`;

//Body
export const ContainerBody = styled.div`
width: 100% - 48px;
overflow: hidden;
padding: 0 24px;
padding-bottom: 20px;
`;

//List
export const ContainerList = styled.div`
  width: 100%;
  height: 64vh;
  padding-top: 16px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  @media screen and (min-height: 850px) {
    height: 70vh;
  }
`;