import React from 'react';
import { Button } from '../Button';
import {
  Container,
  ContainerBody,
  ContainerFooter,
  ContainerHeader,
  customStyles,
  styleImage,
  SubtitleBody,
  Title,
  TitleBody,
} from './style';
import Modal from 'react-modal';
import close from '../../assets/svg/close.svg';

type ModalConfirmationProps = {
  isOpen: boolean,
  onClose: () => void,
  title: string,
  titleBody: string,
  subtitleBody: string,
  labelButton: string,
  onClickButton: () => void,
  showButtonSair?: boolean
};

export function ModalConfirmation({title, onClose, isOpen, titleBody, subtitleBody,showButtonSair=true, onClickButton, labelButton}: ModalConfirmationProps) {
  return (
    <Modal ariaHideApp={false} isOpen={isOpen} style={{ ...customStyles }} contentLabel='Example Modal'>
      <Container>
        <ContainerHeader>
          <img src={close} style={{ ...styleImage, position: 'absolute' }} onClick={onClose} alt=""/>
          <Title>{title ? title : 'Titulo da página'}</Title>
        </ContainerHeader>
        <ContainerBody>
          <TitleBody>{titleBody}</TitleBody>
          <SubtitleBody>{subtitleBody}</SubtitleBody>
        </ContainerBody>
        <ContainerFooter>
          {
          showButtonSair?
          <Button
          onClick={onClickButton}
          color='forth'
          styles={{
            padding: '12px 20px',
            marginRight: '10px',
          }}>
          {labelButton}
        </Button>:
        null
          }
          
          <Button color='fifth' onClick={onClose}>
            Cancelar
          </Button>
        </ContainerFooter>
      </Container>
    </Modal>
  );
}
