import styled, { css } from "styled-components";

interface IDropFile {
  error: boolean;
}

interface IDropzone {
  isDragActive: any;
  error: boolean;
}

interface ITextLocation {
  disabled?: boolean;
}

const dragActive = css`
  border-color: #1d9bf0;
`;
const isError = css`
  border-color: #e83845;
`;

export const FloatContainer = styled.div`
  border: 3px solid #fff;
  height: 75vh;
  overflow-y: auto;
`;

export const FloatChild = styled.div`
  width: 40%;
  float: left;
`;

export const FloatChildDropdown = styled.div`
  margin-right: 20px;
  float: left;
  align-items: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const HeigthChild = styled.div`
  margin-top: -3px !important;
  width: 100%;
  display: flex;
`;

export const PlusBetweenDrops = styled.div`
  height: 80px;
  align-content: "center";
  align-items: "center";
  font-size: 730px;
`;
export const ContainerSearch = styled.div`
  padding: 0 24px;
`;

export const Body = styled.div`
  padding: 24px;
  height: 60vh;
  overflow-y: auto;

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const ContainerModalBody = styled.div`
  display: flex;
  width: 100%;
  height: 75vh;
  justify-content: center;

  span {
    font-size: 14px;
    font-family: "Inter";
  }
`;

export const BodyWaterMark = styled.div`
  display: block;
  width: 100%;

  span {
    font-size: 14px;
    font-family: "Inter";
  }
`;

export const ContainerWaterMaker = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DropFileContainer = styled.div<IDropFile>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 200px;
  background-color: #ecf0f3;
  border-radius: 4px;
  border: 2px solid #ecf0f3;
`;

export const DropFileInput = styled.input`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 200px;
  border-radius: 4px;
  opacity: 0;
  cursor: pointer;
`;

export const DropLocationText = styled.div`
  font-size: 14px;
  font-family: "Inter";
  font-weight: 400;
  color: #000;
`;

export const FileTitle = styled.div`
  font-size: 14px;
  font-family: "Inter";
  font-weight: 400;
  color: #000;
`;

export const ClickLocationText = styled.div<ITextLocation>`
  font-size: 14px;
  font-family: "Inter";
  font-weight: 400;
  color: ${(props) => (props.disabled ? "#b2b2b2" : "#1d9bf0")};
  cursor: ${(props) => (!props.disabled ? "pointer" : "not-allowed")};
`;

export const SizeText = styled.div`
  font-size: 12px;
  font-weight: 500;
  font-family: "Inter";
  color: #666666;
  margin: 10px 0;
`;

export const ContainerDelete = styled.button`
  border: ${(props) =>
    props.disabled ? "2px solid #7887A980" : "2px solid #1d9bf0"};
  height: 40px;
  width: 40px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
`;

export const ContainerDivDropzone = styled.div<IDropzone>`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  align-items: center;
  justify-content: center;
  border: 2px solid #ecf0f3;

  ${(props) => props.error && isError}
  ${(props) => props.isDragActive && dragActive}
`;

export const ContainerSelectTypeMark = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 12px;
`;

export const BoxType1 = styled.div`
  width: 173px;
  height: 90px;
  border: 2px solid #c2c2c2;
  border-radius: 4px;
  padding: 5px;
  cursor: pointer;

  div {
    display: flex;
    flex-direction: column;
  }

  input {
    width: 20px;
    height: 20px;
  }
  span {
    margin-top: 50px;
    font-family: "Inter";
    font-size: 12px;
  }
`;

export const BoxType2 = styled.div`
  width: 173px;
  height: 90px;
  border: 2px solid #c2c2c2;
  border-radius: 4px;
  padding: 5px;
  cursor: pointer;

  div {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  input {
    width: 20px;
    height: 20px;
  }
  span {
    margin-top: 50px;
    font-family: "Inter";
    font-size: 12px;
  }
`;

export const BoxType3 = styled.div`
  width: 173px;
  height: 90px;
  border: 2px solid #c2c2c2;
  border-radius: 4px;
  padding: 5px;
  cursor: pointer;

  div {
    display: flex;
    flex-direction: column;
  }

  input {
    width: 20px;
    height: 20px;
  }
  span {
    margin-bottom: 50px;
    font-size: 12px;
    font-family: "Inter";
  }
`;

export const BoxType4 = styled.div`
  width: 173px;
  height: 90px;
  border: 2px solid #c2c2c2;
  border-radius: 4px;
  padding: 5px;
  cursor: pointer;

  div {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  input {
    width: 20px;
    height: 20px;
  }
  span {
    margin-bottom: 50px;
    font-size: 12px;
    font-family: "Inter";
  }
`;

export const ContainerModalRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 75.3vh;
  padding: 22px 0;
  overflow-y: auto;
`;

export const ContainerCheckboxList = styled.div`
  margin-top: 30px;

  div + div {
    margin-top: 12px;
  }
`;

export const ContainerServiceIntegrationConfig = styled.div`
  width: 100%;
  height: 100%;
`;
