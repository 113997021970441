import { Text } from "./style";


interface Props{
    size?: 'very-bigger' | 'bigger' | 'medium' | 'small' | 'very-small';
    children: React.ReactNode;
    fontStyle:  'bold' | 'regular' | 'semibold' | 'medium';
}

export function Typhography({children, size, fontStyle}:Props){
    return(
        <Text size={size} fontStyle={fontStyle} >{children}</Text>
    )
}