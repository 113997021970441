import React from "react";
import { stylesGlobalDefault } from "../../../global/globalStyle";
import { ContainerSchedule, Scheduled } from "./styles";

type IBadge = {
  id?: string;
  text: string;
  type: string;
  onClick?: (props?: unknown) => unknown;
};

export const StatusBadge: React.FC<IBadge> = ({ id, text, type, onClick }) => {
  let colorBackground = stylesGlobalDefault.activedItem;
  let colorText = stylesGlobalDefault.white;

  if (type === "success") {
    colorBackground = stylesGlobalDefault.activedItem;
    colorText = stylesGlobalDefault.white;
  } else if (type === "warning") {
    colorBackground = "#f0ca68";
    colorText = stylesGlobalDefault.black;
  } else if (type === "progress") {
    colorBackground = "#ffedbf";
    colorText = stylesGlobalDefault.black;
  } else if (type === "inactive") {
    colorBackground = stylesGlobalDefault.inactivedItem;
    colorText = stylesGlobalDefault.white;
  } else if (type === "error") {
    colorBackground = stylesGlobalDefault.inputsBorderFocusError;
    colorText = stylesGlobalDefault.white;
  } else if (type === "notificated") {
    colorBackground = stylesGlobalDefault.blue;
    colorText = stylesGlobalDefault.white;
  }

  return (
    <ContainerSchedule id={id} color={colorBackground} onClick={onClick}>
      <Scheduled color={colorText}>{text}</Scheduled>
    </ContainerSchedule>
  );
};
