import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UserArea } from "../../../../components/Body/UserArea";
import { InputGeneral } from "../../../../components/Input";
import { InputSwitch } from "../../../../components/InputSwitch";
import ModalRight from "../../../../components/ModalRight";
import { useToast } from "../../../../hooks/toast";
import { userService } from "../../../../services/users";
import {
  hideLoading,
  showLoading,
} from "../../../../store/modules/login/actions";
import { setUserCreateUnit } from "../../../../store/modules/userCreateUnit/actions";
import { FORMS_ADMIN } from "../../../../utils/entities/permission";
import {
  Container,
  ContainerBody,
  ContainerInputs,
  ContainerSwitch,
  Drawer,
} from "../../StyleCSS/bodyCreateEdit";
import { EditUnit } from "../EditUnid";
import api from "../../../../services/api";
import { LogOperation } from "../../../../utils/entities/logOperation";
import {
  IAddUserCustomerPoliceUnit,
  UserCustomerProfileService,
} from "../../../../services/user_customer_profile";

interface UserCreateEdit {
  isEdit: boolean;
  name: string;
  onChangeName: (e: any) => unknown;
  email: string;
  onChangeEmail: (e: any) => unknown;
  extraContent: any;
  userData?: any;
  onChangeExtraContent: (e: any) => unknown;
  active: boolean;
  onChangeSwitch: (e: boolean) => unknown;
  latLng: string;
  onChangeLatLng: (e: any) => unknown;
  fieldError: boolean;
  nameError: boolean;
  emailError: boolean;
  customerId?: any;
  userId?: number;
  openUnitModal: () => unknown;
  setName: (value: string) => unknown;
  setEmail: (value: string) => unknown;
  unitData?: any;
  isEditCreatedUnit: boolean;
  setIsEditCreatedUnit: (isEdit: boolean) => unknown;
  dropdownError?: boolean;
}
interface Props {
  id: number;
  customerId: number;
  uf: string;
  customer: string;
  containerName: string;
  root: string;
  username: string;
  unitId: number;
  policeUnit: string;
  policeUnitId: number;
  profileId: string;
  profile: string;
  expireIn: string;
  active: boolean;
  userId: number;
  userCustomerProfileId: number;
  type: number;
}

export const CreateEditUserUnit = (props: UserCreateEdit): JSX.Element => {
  const [isActive, setIsActive] = useState(false);
  const [currentIndex, setCurrentIndex] = useState<number>(-1);
  const [dataUnit, setDataUnit] = useState<any[]>([]);
  const [activeSelected, setActiveSelected] = useState(false);
  const [unitId, setUniId] = useState();
  const [newUnitId, setNewUnitId] = useState();
  const [profileId, setProfileId] = useState<any>();
  const [expiredDate, setExpiredDate] = useState<any>();
  const [valueActive, setValueActive] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [errorNameRequired, setErrorNameRequired] = useState(false);
  const [errorEmailRequired, setErrorEmailRequired] = useState(false);
  const { userCreateUnit } = useSelector((state: any) => state.userCreateUnit);
  const [openModal, setOpenModal] = useState(false);
  const [hasExpireIn, setHasExpireIn] = useState(false);
  const [dataUnitEdit, setDataUnitEdit] = useState<any>();
  const [typeItem, setTypeItem] = useState<any>();
  const [dateError, setDateError] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [selectedId, setSelecteId] = useState("");
  const type = useRef<any>(null);

  const dispatch = useDispatch();
  const { addToast } = useToast();

  async function getUnit(response: any) {
    const dataFormatted: any[] = response?.data?.data?.map((item: Props) => {
      return {
        id: item.id,
        email: props.email,
        unitId: item.unitId,
        policeUnit: item.policeUnit,
        profile: item.profile,
        expireIn: item.expireIn,
        active: item.active,
        userId: props.userId,
        policeUnitId: item.unitId,
        userCustomerProfileId: item.profileId,
        registerId: item.id,
        type: item.type,
      };
    });
    return dataFormatted;
  }

  function editUnit() {
    let newDataUnit = JSON.parse(localStorage.getItem("editUnit")!);
    let editedData = userCreateUnit?.userNewData;
    return {
      id: newDataUnit?.id,
      email: newDataUnit?.email,
      unitId: newDataUnit?.userId,
      policeUnit: editedData?.policeUnitId?.name
        ? editedData?.policeUnitId?.name
        : newDataUnit?.policeUnit,
      profile: editedData?.userCustomerProfileId?.label
        ? editedData?.userCustomerProfileId?.label
        : newDataUnit?.profile,
      expireIn: newDataUnit?.expireIn,
      active: editedData ? editedData?.active : newDataUnit?.active,
      userId: props.userId,
      policeUnitId:
        editedData?.policeUnitId === undefined ?? newDataUnit?.policeUnitId,
      userCustomerProfileId:
        editedData?.userCustomerProfileId?.id === undefined ??
        newDataUnit?.userCustomerProfileId,
      registerId: newDataUnit?.id,
      type: newDataUnit?.type,
    };
  }
  function createUnit() {
    let editedData = userCreateUnit?.userData;
    return {
      id: editedData?.id,
      email: editedData?.email,
      unitId: editedData?.userId?.id,
      policeUnit: editedData?.policeUnitLabel,
      profile: editedData?.userCustomerProfileLabel,
      expireIn: editedData?.expireIn,
      active: editedData?.active,
      userId: props.userId,
      policeUnitId: editedData?.unitId,
      userCustomerProfileId: editedData?.userCustomerProfileId?.id,
      registerId: editedData?.id,
    };
  }
  function createUnitList() {
    let unitList = userCreateUnit?.userData;
    props.unitData(unitList);

    return unitList?.map((unitInList: any) => {
      return {
        id: unitInList?.id,
        email: unitInList,
        unitId: unitInList?.userId,
        policeUnit: unitInList?.policeUnitLabel,
        profile: unitInList?.userCustomerProfileLabel,
        expireIn: unitInList?.expireIn,
        active: unitInList?.active,
        userId: props.userId,
        policeUnitId: unitInList?.name,
        userCustomerProfileId: unitInList?.userCustomerProfileId,
        registerId: unitInList?.id,
      };
    });
  }

  async function getDataUnit() {
    if (props.userId) {
      const response = await userService.getAllByUser(props.userId);

      const dataList = await getUnit(response);
      setDataUnit(dataList);
    }
  }
  async function handleSelectedAction(item: any) {
    type.current = item.type;
    setIsEdit(true);
    if (props.userId) {
      setOpenModal(true);
    } else {
      props.openUnitModal();
      props.setIsEditCreatedUnit(true);
    }
    localStorage.setItem("editUnit", JSON.stringify(item));
    localStorage.setItem("editUnitName", JSON.stringify(item));
    setExpiredDate(item.expireIn);
    setTypeItem(item.policeUnitId);
    setDataUnitEdit(item);
    setUniId(item.registerId);
    setCurrentIndex(item.id);
    setIsActive(item.active);
    setActiveSelected(item.active);
    setSelecteId(item.id);
  }

  async function setActiveAction(action: any) {
    try {
      dispatch(showLoading());

      await userService.putUnit(unitId!, valueActive);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log(error.message);
      }
    }

    await getDataUnit();
    await handleSelectedAction(action);

    dispatch(hideLoading());
  }
  function clear() {
    setOpenModal(false);
    setIsEdit(false);
    setTypeItem(0);
    type.current = null;
    localStorage.removeItem("editUnit");
    setDateError(false);
    setExpiredDate("");
  }

  async function handleUnitEdit() {
    if (!profileId) {
      addToast({
        type: "warning",
        title: "Editar unidade",
        description:
          "Para editar uma unidade é necessário selecionar um perfil.",
      });

      return;
    }

    if (hasExpireIn && !expiredDate) {
      addToast({
        type: "warning",
        title: "Aviso",
        description:
          "Para continuar selecione tempo indeterminado o insira uma data de validade de acesso",
      });

      setDateError(true);

      return;
    }

    setDateError(false);

    if (dataUnitEdit) {
      await api.patch(`/UserCustomerPoliceUnit/Update?id=${dataUnitEdit.id}`, [
        {
          op: "replace",
          value: isActive,
          path: "active",
        },
        {
          op: "replace",
          value: !!expiredDate ? `${expiredDate}T23:59:59` : null,
          path: "expireIn",
        },
        {
          op: "replace",
          value:
            dataUnitEdit.policeUnitId !== typeItem
              ? typeItem
              : dataUnitEdit.policeUnitId,
          path: "policeUnitId",
        },
        {
          op: "replace",
          value: profileId,
          path: "userCustomerProfileId",
        },
      ]);
    }

    await getDataUnit();

    type.current = null;
    setOpenModal(false);
  }

  const startAddNewUnit = () => {
    setIsActive(true);
    setExpiredDate(null);
    setOpenModal(true);
    setProfileId(null);
  };

  async function addNewUnit() {
    if (!typeItem || !profileId || !props.userId || !typeItem) {
      addToast({
        type: "warning",
        title: "Aviso",
        description:
          "Para adicionar uma unidade de policia é necessário preencher todos os campos",
      });

      return;
    }

    const createbody: IAddUserCustomerPoliceUnit = {
      userId: props.userId,
      policeUnitId: typeItem,
      userCustomerProfileId: profileId,
      expireIn: expiredDate,
      active: isActive,
      device: LogOperation.getDevice() as string,
      ip: LogOperation.getMachineIP() as string,
      logPoliceUnitId: LogOperation.getPoliceUnitId() as number,
      logUserId: LogOperation.getUserId() as number,
    };

    const { success } = await UserCustomerProfileService.add(createbody);

    if (success) {
      await getDataUnit();
      clear();
    }
  }

  useEffect(() => {
    setErrorNameRequired(!!userCreateUnit?.errorName);
    setErrorEmailRequired(!!userCreateUnit?.errorEmail);
  }, [userCreateUnit]);

  useEffect(() => {
    getDataUnit();
  }, [userCreateUnit, valueActive]);

  useEffect(() => {
    if (props.extraContent) {
      props.setName(props.extraContent.name);
      props.setEmail(props.extraContent.email);
    }
  }, [dataUnit]);

  useEffect(() => {
    let editedUnit = editUnit();

    if (editedUnit) {
      let newDataUnit = dataUnit.map((item: any) =>
        item.id === editedUnit.id ? editedUnit : item
      );
      setDataUnit(newDataUnit);
    }
  }, [userCreateUnit?.userNewData]);

  useEffect(() => {
    if (props.isEdit) {
      let createdUnitList = createUnit();
      setIsCreating(true);
      if (createdUnitList) {
        let newDataUnit = dataUnit;
        newDataUnit.push(createdUnitList);
        setDataUnit([...newDataUnit]);
      }
    }
  }, [userCreateUnit?.userData]);

  useEffect(() => {
    if (!props.isEdit) {
      let createdUnitList = createUnitList();
      setIsCreating(true);
      setDataUnit(createdUnitList);
    }
  }, [userCreateUnit]);

  return (
    <Container>
      <ContainerBody>
        <ContainerSwitch>
          <InputSwitch
            text="Ativo"
            checked={props.active}
            onChange={() => props.onChangeSwitch(!props.active)}
          />
        </ContainerSwitch>
        <ContainerInputs>
          <InputGeneral
            error={errorNameRequired}
            maxLength={100}
            value={props.name}
            onChange={(e) => props.onChangeName(e)}
            type="text"
            label="Nome"
            required
            startFocus={true}
          />
        </ContainerInputs>
        <ContainerInputs>
          <InputGeneral
            error={errorEmailRequired}
            value={props.email}
            maxLength={100}
            onChange={(e) => props.onChangeEmail(e)}
            type="text"
            label="Email"
            required
            startFocus={false}
            disabled={props.isEdit}
          />
        </ContainerInputs>
        <Drawer />
        <UserArea
          title="Unidades vinculadas"
          InputSearchOnChange={() => {}}
          headerAddButtonOnClick={
            props.userId ? startAddNewUnit : () => props.openUnitModal()
          }
          headerTrashButtonOnClick={() => {}}
          list={dataUnit}
          onClick={(item: any) => handleSelectedAction(item)}
          setItemReturnClick={() => {}}
          setOpenModal={() => setOpenModal}
          setIsEdit={() => {}}
          permissionName={FORMS_ADMIN.POLICE_UNIT}
          active={userCreateUnit?.userNewData?.active}
          currentIndex={currentIndex}
          activeUnit={activeSelected}
          items={(value: any) => setValueActive(value)}
          onClickDelete={setActiveAction}
        />
        <ModalRight
          isOpen={openModal}
          setIsOpen={() => clear()}
          title={isEdit ? "Editar unidade" : "Adicionar unidade"}
          onClickAplicar={isEdit ? handleUnitEdit : addNewUnit}
        >
          <EditUnit
            expiredDate={expiredDate}
            setExpiredDate={setExpiredDate}
            isEdit={isEdit}
            customerId={props.customerId}
            dateError={dateError}
            dataUnitItem={dataUnitEdit}
            setUnitId={setNewUnitId}
            typeItem={typeItem}
            setTypeItem={setTypeItem}
            currentSelectedType={type.current}
            profileId={profileId}
            setProfileId={setProfileId}
            active={isActive}
            setHasExpireIn={setHasExpireIn}
            userId={props.userId}
            onChangeSwitch={() => setIsActive(!isActive)}
          />
        </ModalRight>
      </ContainerBody>
    </Container>
  );
};
