import React, { useRef, useState } from "react";
import { InputGeneral } from "../../../../components/Input";
import {
  Container,
  ContainerBody,
  ContainerInputs,
  ContainerSwitch,
} from "../../StyleCSS/bodyCreateEdit";
import { InputSwitch } from "../../../../components/InputSwitch";
import { DropdownGlobal } from "../../../../components/DropdownGlobal";
import { InputAuth } from "../../../../components/Login/Input";
import { Editor } from "@tinymce/tinymce-react";
import { editorInit } from "./tiny";
import {
  KeyWordsTitle,
  BottomContainer,
  ContainerCheckboxList,
  ContainerTextEditor,
} from "./styles";
import { CheckBoxGlobal } from "../../../../components/CheckBoxGlobal";

interface IFieldError {
  type: boolean;
  title: boolean;
  description: boolean;
  template: boolean;
}
interface ICreateEditCustomerTemplate {
  isEdit: boolean;
  id?: number;
  type: number | undefined;
  title: string;
  description: string;
  template: string;
  keywords: Array<string>;
  actived: boolean;
  setTitle: (title: string) => unknown;
  setDescription: (title: string) => unknown;
  setTemplate: (title: string) => unknown;
  setType: (title: number) => unknown;
  setActived: (actived: boolean) => unknown;
  setKeywords: (prop: Array<string>) => unknown;
  setFieldError: (prop: IFieldError) => unknown;
  fieldError: IFieldError;
}

interface ICheckboxList {
  id: number;
  label: string;
  value: string;
}

export const CreateEditCustomerTemplate = ({
  fieldError,
  setFieldError,
  title,
  description,
  template,
  type,
  actived,
  setTitle,
  keywords,
  setKeywords,
  setType,
  setTemplate,
  setDescription,
  setActived,
}: ICreateEditCustomerTemplate): JSX.Element => {
  const refTiny = useRef<any>(null);
  const documentTypeList = [
    { id: 1, name: "Ata" },
    { id: 2, name: "Transcrição" },
  ];

  const checkboxList: Array<ICheckboxList> = [
    { id: 1, label: "Origem do procedimento", value: "ProcedureSourceName" },
    { id: 2, label: "Tipo do procedimento", value: "ProcedureTypeName" },
    { id: 3, label: "Número do procedimento", value: "ProcedureNumber" },
    { id: 4, label: "Unidade de polícia", value: "PoliceUnitName" },
    { id: 5, label: "Data de abertura", value: "ExpectedDate" },
    { id: 6, label: "Duração", value: "Duration" },
    { id: 7, label: "Responsável", value: "AuthorityName" },
    { id: 8, label: "Prazo final", value: "ProcedureFinishDate" },
    { id: 9, label: "Participante", value: "AttendantName" },
    { id: 10, label: "Tipo de oitiva", value: "HearingTypeName" },
    { id: 11, label: "Texto", value: "Text" },
  ];

  const onSelectKeyword = (keyword: string) => {
    if (keywords.includes(keyword)) {
      setTemplate(
        template.replace(
          `<strong>&lt;&lt;${keyword}&gt;&gt;</strong>&nbsp;`,
          ""
        )
      );
      return setKeywords(keywords.filter((x: string) => x !== keyword));
    }

    setTemplate(
      `${
        !template.includes("<p>") ? template : template.replace("</p>", "")
      } <b>&lt&lt${keyword}&gt&gt</b>&nbsp${
        template.includes("<p>") ? "</p>" : ""
      }`
    );

    setKeywords([...keywords, keyword]);
  };

  const newCheckList =
    type === 1
      ? checkboxList.filter((x: ICheckboxList) => x.id !== 11)
      : checkboxList;

  return (
    <>
      <Container>
        <ContainerBody>
          <ContainerSwitch>
            <InputSwitch
              text="Ativo"
              checked={actived}
              onChange={() => setActived(!actived)}
            />
          </ContainerSwitch>
          <ContainerInputs>
            <DropdownGlobal
              labelName="Tipo de documento"
              defaultValue={
                type ? documentTypeList.find((t) => t.id === type)?.name : ""
              }
              setSelected={(e: any) => {
                setFieldError({
                  template: fieldError.template,
                  type: false,
                  description: fieldError.description,
                  title: fieldError.title,
                });
                setType(e);
              }}
              required
              showDefaultValue={!!type}
              marginRight="10px"
              height="24px"
              width={"195px"}
              options={documentTypeList}
              error={fieldError.type}
            />
            <InputAuth
              error={fieldError.title}
              value={title}
              onChange={(e) => {
                setTitle(e);
                setFieldError({
                  template: fieldError.template,
                  type: fieldError.type,
                  description: fieldError.description,
                  title: false,
                });
              }}
              marginBottom="20px"
              type="text"
              label="Título"
              required={true}
            />
          </ContainerInputs>
          <ContainerInputs>
            <InputGeneral
              error={fieldError.description}
              value={description}
              onChange={(e) => {
                setDescription(e);
                setFieldError({
                  template: fieldError.template,
                  type: fieldError.type,
                  description: false,
                  title: fieldError.title,
                });
              }}
              type="text"
              label="Descrição"
              startFocus={false}
              required
            />
          </ContainerInputs>
          <ContainerTextEditor error={fieldError.template}>
            <Editor
              ref={refTiny}
              inline={false}
              onEditorChange={(e) => {
                setFieldError({
                  template: false,
                  type: fieldError.type,
                  description: fieldError.description,
                  title: fieldError.title,
                });
                setTemplate(e);
              }}
              value={template}
              apiKey="n8zc1mbomckd02imnx0kf30tjuhfosn1fcrspaf2yjlzlcpo"
              onInit={(evt, editor) => (refTiny.current = editor)}
              init={editorInit}
              // initialValue={transcriptions[0].textPlain}
              onCopy={(e) => e.preventDefault()}
              onCut={(e) => e.preventDefault()}
              //onPaste={(e) => e.preventDefault()}
            />
          </ContainerTextEditor>
          <BottomContainer>
            <KeyWordsTitle>Palavras chaves</KeyWordsTitle>
            <ContainerCheckboxList>
              {newCheckList.map((item: ICheckboxList) => (
                <CheckBoxGlobal
                  checked={keywords.includes(item.value)}
                  id={item.id}
                  label={item.label}
                  onChecked={() => onSelectKeyword(item.value)}
                />
              ))}
            </ContainerCheckboxList>
          </BottomContainer>
        </ContainerBody>
      </Container>
    </>
  );
};
