export const listOperation = {
  returnNewListItems: (
    listObjects: any[],
    listIds: Array<number>,
    orderBy?: string
  ) => {
    let returnList: any[] = [];
    listObjects.forEach((element) => {
      let search = listIds.find((id) => id === element.id);
      if (!search) {
        returnList.push(element);
      }
    });

    switch (orderBy) {
      case "name":
        return listOperation.sortByName(returnList);
      case "description":
        return listOperation.sortByDescription(returnList);
      case "id":
        return listOperation.sortById(returnList);
      default:
        return listOperation.sortByName(returnList);
    }
  },

  sortByName: (list: any[]) => {
    return list.sort((a, b) => a.name - b.name);
  },

  sortByDescription: (list: any[]) => {
    return list.sort((a, b) => a.description - b.description);
  },

  sortById: (list: any[]) => {
    return list.sort((a, b) => a.id - b.id);
  },

  groupBy<T>(arr: T[], fn: (item: T) => any) {
    return arr.reduce<Record<string, T[]>>((prev, curr) => {
      const groupKey = fn(curr);
      const group = prev[groupKey] || [];
      group.push(curr);

      return { ...prev, [groupKey]: group };
    }, {});
  },
};
