import { ContainerIconLabel, Icon, Label, Hr } from "./styles";

interface IItemDetailLine {
  disabled: boolean;
  onClick: () => unknown;
  isIconNotificateMd?: boolean;
  icon: any;
  label: string;
  hr?: boolean;
}

export const ItemDetailLine = ({
  disabled,
  onClick,
  isIconNotificateMd,
  icon,
  label,
  hr,
}: IItemDetailLine) => {
  return (
    <>
      <ContainerIconLabel>
        <Icon>{icon}</Icon>
        <Label
          onClick={() => {
            if (!disabled) onClick();
          }}
          disabled={disabled}
        >
          {label}
        </Label>
      </ContainerIconLabel>
      {hr ? <Hr /> : null}
    </>
  );
};
