import styled from "styled-components";
import { stylesGlobalDefault } from "../../../global/globalStyle";

export const ContainerInfosCertificate = styled.div`
  margin: "30px 0px 0px 30px";
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  margin-left: 30px;
  margin-top: 15px;
`;

export const TitleCertificate = styled.label`
  font-family: ${stylesGlobalDefault.fontFamily};
  font-weight: 700;
  font-size: 20px;
  color: #44546a;
  //margin: "30px 0px 0px 30px"
`;

export const InfoCertificate = styled.label`
  font-family: ${stylesGlobalDefault.fontFamily};
  font-weight: 700;
  font-size: 16px;
  color: #000000;
  margin-top: 10px;
  margin-bottom: 10px;
`;
