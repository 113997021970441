import api from "../api";
import { ProcedureCustomerSourceInterface } from "../../pages/app/ProcedureCustomerSource";
import { AxiosError } from "axios";
import { returnResponse } from "../utils";

export interface ProcedureCustomerSourceInterfaceResponse {
  data: ProcedureCustomerSourceInterface[];
  errors: string[];
  message: string;
  ok: boolean;
}

export const ProcedureCustomerSourceService = {
  getAllProcedureCustomerSource: async (
    customerId: number,
    status?: 0 | 1 | null
  ) => {
    try {
      const { data } = await api.get<ProcedureCustomerSourceInterfaceResponse>(
        `/ProcedureCustomerSource/GetAllByCustomerId?customerId=${customerId}${
          status !== null ? `&Active=${status}` : ""
        }`
      );

      return {
        status: true,
        data: data,
        message: data.message,
      };
    } catch (error) {
      if (error instanceof AxiosError) {
        let { severity, status, message, data } = returnResponse({
          statusCode: error.response?.status,
          data: error?.response?.data,
          message: error?.response?.data?.errors[0]?.message,
        });

        return {
          severity,
          status,
          message: message ? message : "Error",
          data,
        };
      }

      return {
        status: false,
        message: "Error",
        data: null,
        severity: "error",
      };
    }
  },

  postProcedureCustomerSource: async (
    authToken: string | null,
    procedureCustomerSource: any
  ) => {
    // if (validToken()) {
    //   return {
    //     status: false,
    //     message: 'Não é possível adicionar o registro sem o token correto.',
    //   };
    // }
    try {
      const body = {
        ...procedureCustomerSource,
      };
      const { data } = await api.post<any>(
        `/ProcedureCustomerSource/Add`,
        body
      );

      return {
        status: true,
        data: data.data,
      };
    } catch (error) {
      if (error instanceof AxiosError) {
        let { severity, status, message, data } = returnResponse({
          statusCode: error.response?.status,
          data: error?.response?.data,
          message: error?.response?.data?.errors[0]?.message,
        });

        return {
          severity,
          status,
          message: message ? message : "Error",
          data,
        };
      }
      let severity: "error" | "warning" | "success";
      severity = "error";
      return {
        status: false,
        message: "Error",
        data: null,
        severity: severity,
      };
    }
  },

  putProcedureCustomerSource: async (
    authToken: string | null,
    procedureCustomerSource: any
  ) => {
    // if (!authToken) {
    //   return {
    //     status: false,
    //     message: 'Não é possível editar o registro sem o token correto.',
    //   };
    // }

    try {
      const body = {
        ...procedureCustomerSource,
      };
      const { data } = await api.put<ProcedureCustomerSourceInterface>(
        `/ProcedureCustomerSource/Update`,
        body
      );

      return {
        status: true,
        data: data,
      };
    } catch (error) {
      if (error instanceof AxiosError) {
        let { severity, status, message, data } = returnResponse({
          statusCode: error.response?.status,
          data: error?.response?.data,
          message: error?.response?.data?.errors[0]?.message,
        });

        return {
          severity,
          status,
          message: message ? message : "Error",
          data,
        };
      }

      return {
        status: false,
        message: "Error",
        data: null,
        severity: "error",
      };
    }
  },

  deleteHearingType: async (
    authToken: string | null,
    hearingTypeId: number
  ) => {
    // if (!authToken) {
    //   return {
    //     status: false,
    //     message: 'Não é possível editar o registro sem o token correto.',
    //   };
    // }
    try {
      const body = [
        {
          op: "replace",
          value: false,
          path: "active",
        },
      ];

      const { data } = await api.patch(
        `/HearingType/update?id=${hearingTypeId}`,
        body
      );

      return {
        status: true,
        data: data,
      };
    } catch (error) {
      if (error instanceof AxiosError) {
        let { severity, status, message, data } = returnResponse({
          statusCode: error.response?.status,
          data: error?.response?.data,
          message: error?.response?.data?.errors[0]?.message,
        });

        return {
          severity,
          status,
          message: message ? message : "Error",
          data,
        };
      }

      return {
        status: false,
        message: "Error",
        data: null,
        severity: "error",
      };
    }
  },
};
