import styled, { css } from "styled-components";

interface IDropFile {
  error: boolean;
}

interface IDropzone {
  isDragActive: any;
  error: boolean;
}

interface ITextLocation {
  disabled?: boolean;
}

const dragActive = css`
  border-color: #1d9bf0;
`;
const isError = css`
  border-color: #e83845;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 80px;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const ContainerBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 80%;
`;

export const ContainerInputs = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const DropFileContainer = styled.div<IDropFile>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 200px;
  background-color: #ecf0f3;
  border-radius: 4px;
  border: 2px solid #ecf0f3;
`;

export const DropFileInput = styled.input`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 200px;
  border-radius: 4px;
  opacity: 0;
  cursor: pointer;
`;

export const DropLocationText = styled.div`
  font-size: 14px;
  font-family: "Inter";
  font-weight: 400;
  color: #000;
`;

export const FileTitle = styled.div`
  font-size: 14px;
  font-family: "Inter";
  font-weight: 400;
  color: #000;
`;

export const ClickLocationText = styled.div<ITextLocation>`
  font-size: 14px;
  font-family: "Inter";
  font-weight: 400;
  color: ${(props) => (props.disabled ? "#b2b2b2" : "#1d9bf0")};
  cursor: ${(props) => (!props.disabled ? "pointer" : "not-allowed")};
`;

export const SizeText = styled.div`
  font-size: 12px;
  font-weight: 500;
  font-family: "Inter";
  color: #666666;
  margin: 10px 0;
`;

export const ContainerDelete = styled.button`
  border: ${(props) =>
    props.disabled ? "2px solid #7887A980" : "2px solid #1d9bf0"};
  height: 40px;
  width: 40px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
`;

export const ContainerDivDropzone = styled.div<IDropzone>`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  align-items: center;
  justify-content: center;
  border: 2px solid #ecf0f3;

  ${(props) => props.error && isError}
  ${(props) => props.isDragActive && dragActive}
`;
