import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ContainerSwitchAndText = styled.div`
  width: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const SwitchDiv = styled.div`
  padding-right: 3%;
  align-items: initial;
`;

export const TextArea = styled.div`
  width: 100%;
  align-items: center;
`;
