import { SELECT_CUSTOMER_PROFILE, SET_IS_ADMIN } from "../actions/actios.types";

const INITIAL_STATE: any = {
  userCustomerProfileId: null,
  isAdmin: false,
};

const profile = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case SELECT_CUSTOMER_PROFILE:
      return {
        ...state,
        userCustomerProfileId: action.userCustomerProfileId,
      };
    case SET_IS_ADMIN:
      return {
        ...state,
        isAdmin: action.isAdmin,
      };
    default:
      return state;
  }
};

export default profile;
