/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-globals */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hideLoading, showLoading } from "../../../store/modules/login/actions";
import { Hearing } from "../../../components/HeaderHearing";
import { ProcedureList } from "../../../components/ProcedureList";
import { RightSidebar } from "../../../components/RightSidebar";
import api from "../../../services/api";
import { Container, ProcedureContainer } from "./styles";
import ModalRight from "../../../components/ModalRight";
import { ProcedureDetail } from "../../../features/ProcedureDetail";
import { formatYmd } from "../../../utils/format";
import { useToast } from "../../../hooks/toast";
import { v4 as uuidv4 } from "uuid";
import { CreateEditHearing } from "../../../components/Hearing/CreateEdit";

import ModalRightDetail from "../../../components/ModalRight/Detail/detail";
import { HearingDetail } from "../../../components/Hearing/HearingDetailsModalRight";
import { HearingService } from "../../../services/hearing";
import { usePermissions } from "../../../hooks/permission";
import { FORMS } from "../../../utils/entities/permission";
import { useLocation, useNavigate } from "react-router-dom";
import { ProcedureService } from "../../../services/procedure";
import {
  Procedure,
  ProcedureFilterSearch,
} from "../../../services/procedure/types";
import { useCookies } from "react-cookie";
import { Dashboard } from "../../../features/Dashboard";

import { LogOperation } from "../../../utils/entities/logOperation";
import { userService } from "../../../services/users";
import { ModalConfirmation } from "../../../components/ModalConfirmation";
import { getValueFromlocalStorage } from "../../../utils/sessionStorageEncrypt";

interface IProcedure {
  id?: number;
  guid: string;
  expectedDate: string | Date | null;
  deadLineDate: string | Date | null;
  procedureNumber: string;
  procedureCustomerTypeId: number;
  procedureCustomerSourceId: number;
  policeUnitId: number | undefined;
  description?: string;
  status: string;
  active: boolean;
  device: string;
  ip: string | null;
  logPoliceUnitId: number;
  logUserId: number;
}

export const ProcedureScreen: React.FC = () => {
  const { policeUnit } = useSelector((state: any) => state.policeUnit);

  const [procedureNumber, setProcedureNumber] = useState<string>("");
  const [finalDate, setFinalDate] = useState<string | null>(null);
  const [procedureTypeId, setProcedureTypeId] = useState<any>();
  const [procedureOriginId, setProcedureOriginId] = useState<any>();
  const [policeUnitId, setPoliceUnitId] = useState<number | undefined>();
  const [updateItem, setUpdateItem] = useState(0);
  const [isFilter, setIsFilter] = useState<boolean>(false);

  const [date, setDate] = useState<string | null>(null);

  const [procedureList, setProcedureList] = useState<Array<Procedure> | null>(
    null
  );
  const [policeChiefList, setPoliceChiefList] = useState<Array<any>>([]);
  const [itemData, setItemData] = useState<any | null>(null);
  const [editProcedureTypeName, setEditProcedureTypeName] = useState("");
  const [editProcedureOriginName, setEditProcedureOriginName] = useState("");
  const [currentIndex, setCurrentIndex] = useState<number>(-1);
  const [isOpen, setIsOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isClassified, setIsClassified] = useState(false);
  const dispatch = useDispatch();
  const { addToast } = useToast();
  const [activeProcedure, setActiveProcedure] = useState(true);
  const [showModalConfirmation, setShowModalConfirmation] = useState(false);
  const location = useLocation();

  const { read } = usePermissions(FORMS.PROCEDURE_CUSTOMER);

  const [errorFields, setErrorFields] = useState({
    procedureNumber: false,
    finalDate: false,
    date: false,
    procedureType: false,
    procedureOrigin: false,
  });

  useEffect(() => {
    (async () => {
      if (policeUnit) {
        const { unitId } = policeUnit;
        dispatch(hideLoading());

        const { data } = await ProcedureService.getAllByPoliceUnit(unitId);

        if (data) setProcedureList(data);
      }
    })();
  }, [updateItem]);

  useEffect(() => {
    (async () => {
      if (policeUnit) {
        dispatch(showLoading());
        const { unitId } = policeUnit;
        setPoliceUnitId(unitId);

        setIdProcedureSelected(null);
        setItemData(null);
        setItemDataHearing(null);
        setCurrentIndex(-1);
        setIsFilter(false);
        setProcedureList([]);

        await getAllPoliceChief();

        const { data } = await ProcedureService.getAllByPoliceUnit(unitId);
        dispatch(hideLoading());

        if (data) setProcedureList(data);

        if (location.state?.procedureNumber) {
          selectProcedureById(location.state.procedureNumber);
        }
      } else {
        dispatch(hideLoading());
      }
    })();
  }, [policeUnit]);

  function selectProcedureById(procedureNumber: number) {
    setProcedureNumber(String(procedureNumber));
  }

  const validFields = (
    procedureNumber: string,
    date: string | null,
    finalDate: string | null,
    procedureOriginId: any,
    procedureTypeId: any
  ) => {
    setErrorFields({
      procedureNumber: !procedureNumber,
      date: !date,
      finalDate: !finalDate,
      procedureType: !procedureOriginId,
      procedureOrigin: !procedureTypeId,
    });
  };

  useEffect(() => {}, [finalDate, date]);

  function validDatesToday() {
    const dNow = new Date();
    const startDate = new Date(`${date}T23:59:00`);
    const finalDateValidator = new Date(`${finalDate}T23:59:00`);

    if (date && finalDate) {
      if (startDate < dNow) {
        setErrorFields({
          finalDate: false,
          date: true,
          procedureNumber: false,
          procedureOrigin: false,
          procedureType: false,
        });
        return false;
      } else if (finalDateValidator < dNow) {
        setErrorFields({
          finalDate: true,
          date: false,
          procedureNumber: false,
          procedureOrigin: false,
          procedureType: false,
        });
        return false;
      } else if (finalDateValidator < startDate) {
        setErrorFields({
          finalDate: true,
          date: false,
          procedureNumber: false,
          procedureOrigin: false,
          procedureType: false,
        });
        return false;
      } else {
        setErrorFields({
          finalDate: false,
          date: false,
          procedureNumber: false,
          procedureOrigin: false,
          procedureType: false,
        });
        return true;
      }
    } else {
      if (!startDate) {
        setErrorFields({
          finalDate: false,
          date: true,
          procedureNumber: false,
          procedureOrigin: false,
          procedureType: false,
        });
      }
      if (!finalDateValidator) {
        setErrorFields({
          finalDate: true,
          date: false,
          procedureNumber: false,
          procedureOrigin: false,
          procedureType: false,
        });
      }
      return false;
    }
  }

  const createProcedure = async () => {
    try {
      if (
        !procedureNumber ||
        !date ||
        !finalDate ||
        !procedureOriginId ||
        !procedureTypeId ||
        !policeUnitId
      ) {
        validFields(
          procedureNumber,
          date,
          finalDate,
          procedureOriginId,
          procedureTypeId
        );
        addToast({
          type: "warning",
          title: "Preencha os campos",
          description: "Para continuar é necessario preencher todos os campos.",
        });

        return dispatch(hideLoading());
      }

      if (!validDatesToday()) {
        addToast({
          type: "warning",
          title: "Aviso",
          description: "Data invalida",
        });
        return;
      }

      const bodyData = {
        guid: uuidv4(),
        expectedDate: date ? formatYmd(new Date(date)) : null,
        deadLineDate: finalDate ? formatYmd(new Date(finalDate)) : null,
        procedureNumber: procedureNumber,
        procedureCustomerTypeId: procedureTypeId,
        procedureCustomerSourceId: procedureOriginId,
        policeUnitId: policeUnitId,
        status: "A",
        active: true,
        device: LogOperation.getDevice(),
        ip: LogOperation.getMachineIP(),
        logPoliceUnitId: LogOperation.getPoliceUnitId(),
        logUserId: LogOperation.getUserId(),
      };

      dispatch(showLoading());

      await api.post("/ProcedureCustomer/add", bodyData);

      const { data } = await ProcedureService.getAllByPoliceUnit(policeUnitId);

      const indexFinded = data.findIndex(
        (x: IProcedure) => x.procedureNumber === procedureNumber
      );
      setItemData(data[indexFinded]);

      setCurrentIndex(indexFinded);
      setFinalDate(null);
      setDate(null);
      setProcedureNumber("");
      setProcedureList(data);
      setProcedureTypeId(undefined);
      setProcedureOriginId(undefined);
      setIsOpen(false);

      setErrorFields({
        date: false,
        finalDate: false,
        procedureNumber: false,
        procedureOrigin: false,
        procedureType: false,
      });
      addToast({
        type: "success",
        title: "Procedimento criado com sucesso",
      });

      dispatch(hideLoading());
    } catch (error: any) {
      addToast({
        type: "warning",
        title: "Aviso",
        description:
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : "Aconteceu um erro ao criar procedimento",
      });
      dispatch(hideLoading());
    }
  };

  const onClickInativarProcedimento: any = async () => {
    try {
      setIsEdit(false);
      setIsOpen(false);
      dispatch(showLoading());
      const body = [
        {
          op: "replace",
          value: false,
          path: "Active",
        },
      ];

      await api.patch(`/ProcedureCustomer/Update?id=${itemData.id}`, body);

      if (policeUnitId) {
        const { data } = await ProcedureService.getAllByPoliceUnit(
          policeUnitId
        );

        setItemData(null);
        setCurrentIndex(-1);
        dispatch(hideLoading());
        addToast({
          type: "success",
          title: "Procedimento inativado com sucesso",
        });

        if (data) setProcedureList(data);
      }
    } catch (error: any) {
      alert(`error: ${error.message}`);
      dispatch(hideLoading());
    }
    setShowModalConfirmation(false);
  };

  const onOpenEditItem = () => {
    if (itemData) {
      setIsOpen(true);
      setIsEdit(true);
      setProcedureNumber(itemData.procedureNumber);

      setDate(new Date(itemData.expectedDate).toISOString().slice(0, 10));
      setFinalDate(
        String(new Date(itemData.deadLineDate).toISOString().slice(0, 10))
      );

      setProcedureTypeId(itemData.procedureCustomerTypeId);
      setProcedureOriginId(itemData.procedureCustomerSourceId);
      setEditProcedureTypeName(itemData.procedureCustomerTypeName);
      setEditProcedureOriginName(itemData.procedureCustomerSourceName);
      setActiveProcedure(itemData.active);
    }
  };

  const onCloseModal = () => {
    setFinalDate(null);
    setDate(null);
    setProcedureNumber("");
    setProcedureTypeId(undefined);
    setProcedureOriginId(undefined);
    setEditProcedureTypeName("");
    setEditProcedureOriginName("");
    setIsOpen(false);
    setIsEdit(false);
    setItemData(null);
    setCurrentIndex(-1);
    setErrorFields({
      date: false,
      finalDate: false,
      procedureNumber: false,
      procedureOrigin: false,
      procedureType: false,
    });
  };

  const clearProcedureBeforeCreate = () => {
    setFinalDate(null);
    setDate(null);
    setProcedureNumber("");
    setProcedureTypeId(undefined);
    setProcedureOriginId(undefined);
    setEditProcedureTypeName("");
    setEditProcedureOriginName("");
    setItemData(null);
    setErrorFields({
      date: false,
      finalDate: false,
      procedureNumber: false,
      procedureOrigin: false,
      procedureType: false,
    });
  };

  const onCloseModalUpdateProcedure = () => {
    setFinalDate(null);
    setDate(null);
    setProcedureTypeId(undefined);
    setProcedureOriginId(undefined);
    setEditProcedureTypeName("");
    setEditProcedureOriginName("");
    setIsOpen(false);
    setIsEdit(false);
    setErrorFields({
      date: false,
      finalDate: false,
      procedureNumber: false,
      procedureOrigin: false,
      procedureType: false,
    });
  };

  const onPressCloseProcedureModal = () => {
    setFinalDate(null);
    setDate(null);
    setProcedureTypeId(undefined);
    setProcedureOriginId(undefined);
    setEditProcedureTypeName("");
    setEditProcedureOriginName("");
    setIsOpen(false);
    setIsEdit(false);
    setErrorFields({
      date: false,
      finalDate: false,
      procedureNumber: false,
      procedureOrigin: false,
      procedureType: false,
    });
  };

  const updateProcedureIem = async () => {
    try {
      if (
        !procedureNumber ||
        !date ||
        !finalDate ||
        !procedureOriginId ||
        !procedureTypeId ||
        !policeUnitId
      ) {
        validFields(
          procedureNumber,
          date,
          finalDate,
          procedureOriginId,
          procedureTypeId
        );
        addToast({
          type: "warning",
          title: "Preencha os campos",
          description: "Para continuar é necessario preencher todos os campos.",
        });

        return dispatch(hideLoading());
      }

      if (!validDatesToday()) {
        addToast({
          type: "warning",
          title: "Aviso",
          description: "Data invalida",
        });
        return;
      }

      dispatch(showLoading());

      const {
        data: { data: procedureItem },
      } = await api.get(`/ProcedureCustomer/Get?id=${itemData.id}`);

      const bodyData: IProcedure = {
        id: itemData.id,
        guid: procedureItem.guid,
        expectedDate: date
          ? formatYmd(new Date(date))
          : procedureItem.expectedDate,
        deadLineDate: finalDate
          ? formatYmd(new Date(finalDate))
          : procedureItem.deadLineDate,
        procedureNumber:
          itemData.procedureNumber === procedureItem.procedureNumber
            ? itemData.procedureNumber
            : procedureNumber,
        procedureCustomerTypeId: procedureTypeId
          ? procedureTypeId
          : procedureItem.procedureCustomerTypeId,
        procedureCustomerSourceId: procedureOriginId
          ? procedureOriginId
          : procedureItem.procedureCustomerSourceId,
        policeUnitId: policeUnitId,
        status: "A",
        active: activeProcedure,
        device: LogOperation.getDevice(),
        ip: LogOperation.getMachineIP(),
        logPoliceUnitId: LogOperation.getPoliceUnitId(),
        logUserId: LogOperation.getUserId(),
      };

      await api.put("/ProcedureCustomer/Update", bodyData);

      const { data } = await ProcedureService.getAllByPoliceUnit(policeUnitId);

      setProcedureList(data);

      const index = data.findIndex(
        (x: any) => x.procedureNumber === itemData.procedureNumber
      );

      setItemData(data[index]);
      setCurrentIndex(index);

      onCloseModalUpdateProcedure();
      dispatch(hideLoading());
      addToast({
        type: "success",
        title: "Atualização de procedimento",
        description: "Procedimento atualizado com sucesso",
      });
    } catch (error: any) {
      addToast({
        type: "error",
        title: "Procedimento",
        description:
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : "Aconteceu um erro ao atualizar o procedimento",
      });
      dispatch(hideLoading());
    }
  };

  function clearProcedure() {
    setProcedureNumber("");
    setFinalDate(null);
    setProcedureTypeId(undefined);
    setProcedureOriginId(undefined);
    setDate("");
    setEditProcedureTypeName("");
    setEditProcedureOriginName("");
  }

  const getAllPoliceChief = async () => {
    const { unitId } = policeUnit;
    const { success, body } = await userService.getAllPoliceChief(unitId);

    if (success) {
      setPoliceChiefList(body);
    }
  };

  //AREA HEARING============================================================================================================
  interface User {
    id: number;
    picture?: string;
    name: string;
    customerId: number;
  }
  const userStorage = getValueFromlocalStorage("@Kenta:user");
  const [user, setUser] = useState<User | null>();
  const [isEditHearing, setIsEditHearing] = useState(false);
  const [expectedDateValue, setExpectedDateValue] = useState<string>("");
  const [expectedTimeValue, setExpectedTimeValue] = useState<string>("");
  const [hearingTypeDropSelected, setHearingTypeDropSelected] = useState<
    number | null
  >();
  const [attendantDropSelected, setAttendantDropSelected] = useState<
    any | null
  >();
  const [openModalHearing, setOpenModalHearing] = useState(false);
  const [hearingList, setHearingList] = useState<Array<any>>([]);
  const authToken = getValueFromlocalStorage("@Kenta:access_Token");
  //Edit Hearing
  const [itemDataHearing, setItemDataHearing] = useState<any | null>(null);
  const [editAttendantName, setEditAttendantName] = useState("");
  const [editHearingTypeName, setEditHearingTypeName] = useState("");
  const [testeOpenModal, setOpenModalTeste] = useState(false);
  const [reloadList, setReloadList] = useState(false);
  const [activeHearing, setActiveHearing] = useState(true);
  const [errorFieldHearing, setErrorFieldHearing] = useState<boolean>(false);
  const [chief, setChief] = useState<any>();
  const [cookie, setCookie] = useCookies(["authToken", "deviceToken"]);
  const [idProcedureSelected, setIdProcedureSelected] = useState<string | null>(
    null
  );
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);

  const idProcedure = queryParams.get("procedureId");
  const hearingId: any = queryParams.get("hearingId");

  //Controle validation
  const [dateClear, setDateClear] = useState<boolean>(false);
  const [timeClear, setTimeClear] = useState<boolean>(false);

  useEffect(() => {
    if (userStorage) {
      setUser(JSON.parse(userStorage));
    }
  }, []);

  useEffect(() => {
    if (procedureList && procedureList.length > 0) {
      if (idProcedure) {
        setIdProcedureSelected(idProcedure);
      }
    }
  }, [procedureList]);

  useEffect(() => {
    if (itemDataHearing) {
      setIsClassified(itemDataHearing ? itemDataHearing.isClassified : false);
    }
  }, [itemDataHearing]);

  async function postHearing() {
    try {
      if (
        !hearingTypeDropSelected ||
        !attendantDropSelected ||
        !expectedDateValue ||
        !expectedTimeValue
      ) {
        addToast({
          type: "warning",
          title: "Oitiva",
          description:
            "Para criar uma oitiva é necessário preencher os campos destacados.",
        });
        setErrorFieldHearing(true);
        return;
      }

      const dNow = new Date();
      const [YYYY, MM, DD] = expectedDateValue.split("-");

      const expectedDateValueValidator = new Date(
        `${expectedDateValue}T12:00:00.000Z`
      );

      if (
        (expectedDateValueValidator && Number(DD) < dNow.getDate()) ||
        Number(MM) < dNow.getMonth() + 1 ||
        Number(YYYY) < dNow.getFullYear()
      ) {
        addToast({
          type: "warning",
          title: "Aviso",
          description: "Data invalida",
        });

        setErrorFieldHearing(true);
        return dispatch(hideLoading());
      }

      interface policeUnitInterface {
        unitId: number;
      }

      if (!policeUnit) {
        return;
      }

      const policeUnitObj: policeUnitInterface = policeUnit;

      const bodyPost = {
        policeUnitId: policeUnitObj.unitId,
        procedureCustomerId: itemData.id,
        hearingTypeId: hearingTypeDropSelected,
        procedureCustomerAttendantId: attendantDropSelected,
        status: "A",
        procedureNumber: itemData.procedureNumber,
        expectedDate: expectedDateValue + "T" + expectedTimeValue,
        duration: 0,
        userId: user?.id,
        exportCount: 0,
        isClassified: isClassified,
        active: true,
        device: LogOperation.getDevice(),
        ip: LogOperation.getMachineIP(),
        logPoliceUnitId: LogOperation.getPoliceUnitId(),
        logUserId: LogOperation.getUserId(),
      };

      dispatch(showLoading());

      onCloseModalHearing();
      await api.post("/Hearing/Add", bodyPost);

      await getAllHearing();
      addToast({
        type: "success",
        title: "Oitiva cadastrada",
        description: "Oitiva foi cadastrada com sucesso.",
      });

      dispatch(hideLoading());

      setOpenModalHearing(false);
      setReloadList(!reloadList);
    } catch (e) {
      addToast({
        type: "error",
        title: "Ocorreu um erro",
      });
      dispatch(hideLoading());
    }
  }

  async function putHearing() {
    try {
      if (dateClear || timeClear || !expectedDateValue) {
        if (dateClear) {
          setExpectedDateValue("");
        }
        if (timeClear) {
          setExpectedTimeValue("");
        }

        addToast({
          type: "warning",
          title: "Oitiva",
          description:
            "Para atualizar uma oitiva é necessário preencher os campos destacados.",
        });
        setErrorFieldHearing(true);
        return;
      }

      const bodyPut = {
        id: itemDataHearing.id,
        guid: itemDataHearing.guid,
        foreignKey: itemDataHearing.foreignKey
          ? itemDataHearing.foreignKey
          : null,
        policeUnitId: itemDataHearing.policeUnitId,
        procedureCustomerId: itemData.id,
        hearingTypeId: hearingTypeDropSelected
          ? hearingTypeDropSelected
          : itemDataHearing.hearingTypeId,
        procedureCustomerAttendantId: attendantDropSelected
          ? attendantDropSelected
          : itemDataHearing.procedureCustomerAttendantId,
        status: itemDataHearing.status,
        procedureNumber: itemData.procedureNumber,
        expectedDate: joinDateAndTime(itemDataHearing.expectedDate),
        duration: itemDataHearing.duration,
        userId: chief ? chief : user?.id,
        exportCount: 0,
        isClassified: isClassified,
        active: activeHearing,
        device: LogOperation.getDevice(),
        ip: LogOperation.getMachineIP(),
        logPoliceUnitId: LogOperation.getPoliceUnitId(),
        logUserId: LogOperation.getUserId(),
      };

      dispatch(showLoading());

      onCloseModalHearing();

      await api.put("/Hearing/Update", bodyPut);

      await getAllHearing();

      addToast({
        type: "success",
        title: "Oitiva atualizada",
        description: "Oitiva foi atualizada com sucesso.",
      });

      dispatch(hideLoading());
    } catch {
      addToast({
        type: "warning",
        title: "Ocorreu um erro inesperado",
      });
      dispatch(hideLoading());
    }
  }

  const onCloseModalHearing = () => {
    setExpectedDateValue("");
    setExpectedTimeValue("");
    setAttendantDropSelected(undefined);
    setHearingTypeDropSelected(undefined);
    setOpenModalHearing(false);
    setIsEditHearing(false);

    clearHearing();
  };

  function OpenModalEditHearing(hearing?: any) {
    setIsEditHearing(true);
    setOpenModalHearing(true);

    setErrorFieldHearing(false);
    let hearingData;
    if (hearing) {
      hearingData = hearing;

      setItemDataHearing(hearingData);
      setAttendantDropSelected(hearing.procedureCustomerAttendantId);

      setIsClassified(hearing ? hearing.isClassified : false);
    } else {
      hearingData = itemDataHearing;
    }

    if (hearingData !== null) {
      try {
        setEditAttendantName(hearingData.attendantName);
        setEditHearingTypeName(hearingData.hearingTypeName);

        const dateEdit = hearingData.expectedDate.split("T");

        setExpectedDateValue(dateEdit[0].toString());
        setExpectedTimeValue(dateEdit[1].toString());
      } catch (error) {
        addToast({
          type: "error",
          title: "Aviso",
          description: "Ocorreu um erro inesperado",
        });
      }
    }
  }

  function joinDateAndTime(expectedDateItem: any) {
    const dateTime = expectedDateItem.split("T");
    const date = dateTime[0];
    const time = dateTime[1];
    if (!expectedDateValue && !expectedTimeValue) {
      return expectedDateItem;
    } else if (!expectedDateValue) {
      return date + "T" + expectedTimeValue;
    } else if (!expectedTimeValue) {
      return expectedDateValue + "T" + time;
    } else if (expectedDateValue && expectedTimeValue) {
      return expectedDateValue + "T" + expectedTimeValue;
    }
  }

  const getAllHearing = async () => {
    try {
      if (itemData) {
        dispatch(showLoading());
        const { data } = await api.get(
          `/Hearing/GetAllByProcedureCustomerId2?ProcedureCustomerId=${itemData.id}`
        );

        const listHearingActived: Array<any> = [];
        if (data.data) {
          data.data.forEach((element: { active: boolean }) => {
            if (element.active) listHearingActived.push(element);
          });
          setHearingList(listHearingActived);
          dispatch(hideLoading());
          return;
        }

        dispatch(hideLoading());
      }
    } catch (error) {
      dispatch(hideLoading());
    }
  };

  const onDeleteExcluirOitiva = async (hearingId: number) => {
    await HearingService.deleteHearing(authToken, hearingId);
    await getAllHearing();
    addToast({
      type: "success",
      title: "Oitiva inativada com sucesso",
    });
  };

  const closeModalRight = () => {
    setItemDataHearing(null);
    setOpenModalTeste(false);
  };

  const openModalHearingFunction = () => {
    clearHearing();
    setOpenModalHearing(true);
    setOpenModalTeste(false);
    setItemDataHearing(null);
  };

  function clearHearing() {
    setIsEditHearing(false);
    setExpectedDateValue("");
    setExpectedTimeValue("");
    setHearingTypeDropSelected(null);
    setAttendantDropSelected(null);
    setEditAttendantName("");
    setEditHearingTypeName("");
    setErrorFieldHearing(false);
    setDateClear(false);
    setTimeClear(false);
  }
  function clearAllCookies() {
    const cookies = document.cookie.split(";");

    for (const cookie of cookies) {
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
      document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
    }
  }

  async function requestPermission() {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true,
      });

      stream.getTracks().forEach((track) => track.stop()); // Stop the tracks
      return true; // Permission granted
    } catch (error) {
      return false; // Permission denied or other error
    }
  }

  const createHearingRecord = async (item: any) => {
    let temPermissaoDeCamera = await requestPermission();
    if (!temPermissaoDeCamera) {
      addToast({
        type: "error",
        title: "Permitir câmera",
        description:
          "É necessário que seja permitido o uso da câmera para prosseguir",
      });
      return;
    }

    if (authToken && itemData && itemData.id) {
      clearAllCookies();
      dispatch(showLoading());
      const response = await HearingService.createHearingRecord(
        authToken,
        item.id
      );

      if (response.status) {
        setCookie("authToken", response.data.token);
        document.cookie = `${response.data.token}`;
        document.cookie = `${response.data.sessionToken}`;
        navigate("/dashboard/hearingRecord/una", {
          state: {
            ...itemData,
            hearingId: item.id,
            urlModerator: response.data.urlModerator,
            urlAttendee: response.data.urlAttendee,
            videoRoomId: response.data.videoRoom.id,
            authToken: response.data.token,
            deviceToken: response.data.sessionToken,
          },
        });
      } else {
        addToast({
          type: "error",
          title: "Não foi possivel fazer a gravação",
          description: "Verifique se a oitiva foi criada corretamente",
        });
      }
      dispatch(hideLoading());
    }
  };

  const searchProcedureByFilters = async (filters: ProcedureFilterSearch) => {
    dispatch(showLoading());
    setProcedureList([]);
    setHearingList([]);
    setCurrentIndex(0);
    setItemData(null);
    setItemDataHearing(null);
    const { data } = await ProcedureService.getAllByFilters(
      policeUnit.unitId,
      filters
    );

    if (data) {
      !filters ? setIsFilter(false) : setIsFilter(true);
      setProcedureList(data);
      if (data.length) {
        setItemData(data[0]);
        setIdProcedureSelected(data[0].id);
        setCurrentIndex(0);
        navigate(`/dashboard/procedure?procedureId=${data[0].id}`, {
          state: {
            procedureNumber: data[0].id,
          },
        });
      }
    }

    dispatch(hideLoading());
  };

  //============================================================================================================

  return (
    <>
      {read ? (
        <Container id="procedure_container_screen">
          <ProcedureContainer id="procedure">
            {procedureList && (
              <ProcedureList
                isFilter={isFilter}
                setModalHearing={(item) => {
                  setUpdateItem(Math.random() * 10);
                  setOpenModalTeste(item);
                }}
                currentIndex={currentIndex}
                setCurrentIndex={setCurrentIndex}
                setItemData={(data) => {
                  setItemData(data);
                  if (
                    data &&
                    data.procedureNumber > 0 &&
                    idProcedure !== data.procedureNumber
                  ) {
                    setItemDataHearing(null);
                    onCloseModalHearing();
                    navigate(
                      `/dashboard/procedure?procedureId=${data.procedureNumber}`,
                      {
                        replace: true,
                      }
                    );
                  }
                }}
                procedureNumber={procedureNumber}
                selectCurrentProcedure={idProcedureSelected}
                data={procedureList}
                title="Procedimentos"
                searchProcedureByFilters={(filters: ProcedureFilterSearch) => {
                  searchProcedureByFilters(filters);
                  clearProcedure();
                  clearHearing();
                  setCurrentIndex(-1);
                  navigate(`/dashboard/procedure`, { replace: true });
                }}
                addButtonClick={() => {
                  clearProcedureBeforeCreate();
                  setIsOpen(!isOpen);
                  setItemData(null);
                  setCurrentIndex(-1);
                }}
                deleteButtonClick={
                  itemData && hearingList.length
                    ? () =>
                        addToast({
                          type: "warning",
                          title: "Aviso",
                          description:
                            "Não está permitido inativar procedimentos com oitivas",
                        })
                    : itemData
                    ? () => setShowModalConfirmation(true)
                    : undefined
                }
              />
            )}
          </ProcedureContainer>
          {currentIndex !== -1 ? (
            <Hearing
              openModal={testeOpenModal}
              setItemData={setItemData}
              procedureItem={itemData}
              title="Oitivas gravadas"
              setIsEditHearing={setIsEditHearing}
              setOpenModal={setOpenModalTeste}
              addButtonClick={openModalHearingFunction}
              reloadList={reloadList}
              itemDataHearing={itemDataHearing}
              setItemDataHearing={setItemDataHearing}
              getAllHearing={getAllHearing}
              selectedHearingId={hearingId}
              hearingList={
                hearingList.length > 0
                  ? hearingList.filter((item) => item.status !== "A")
                  : []
              }
            />
          ) : (
            <Dashboard />
          )}

          {!testeOpenModal && itemData && (
            <RightSidebar
              onClickGravarOitiva={(item: any) => createHearingRecord(item)}
              onClickEditarOitiva={(item: any) => OpenModalEditHearing(item)}
              loadingList={getAllHearing}
              onEditItem={onOpenEditItem}
              itemData={{
                ...itemDataHearing,
                ...itemData,
                hearingList: hearingList.filter((item) => {
                  return item.status === "A" && item.active;
                }),
              }}
              onClickAddSidebar={openModalHearingFunction}
            />
          )}
          {testeOpenModal && itemDataHearing && (
            <ModalRightDetail
              isOpen={testeOpenModal}
              title={"Detalhes da oitiva"}
              id="teste"
              setIsOpen={closeModalRight}
            >
              <HearingDetail
                procedureItem={itemData}
                onClickExcluir={() => onDeleteExcluirOitiva(itemDataHearing.id)}
                onClickEdit={() => OpenModalEditHearing()}
                loadingList={getAllHearing}
                hearing={itemDataHearing}
              />
            </ModalRightDetail>
          )}
        </Container>
      ) : (
        <Dashboard />
      )}
      <ModalRight
        onClickAplicar={isEdit ? updateProcedureIem : createProcedure}
        setIsOpen={onPressCloseProcedureModal}
        title={isEdit ? "Editar procedimento" : "Criar procedimento"}
        isOpen={isOpen}
        onClickDelete={() => {
          setShowModalConfirmation(true);
        }}
        showIconDelete={false}
        isEdit={isEdit}
      >
        <ProcedureDetail
          itemData={itemData}
          hearingList={hearingList}
          editProcedureTypeName={editProcedureTypeName}
          editProcedureOriginName={editProcedureOriginName}
          procedureNumber={procedureNumber}
          errorFields={errorFields}
          setProcedureTypeId={setProcedureTypeId}
          procedureTypeId={procedureTypeId}
          finalDate={finalDate}
          date={date}
          setProcedureOriginId={setProcedureOriginId}
          procedureOriginId={procedureOriginId}
          setProcedureNumber={setProcedureNumber}
          setFinalDate={(e) => {
            setFinalDate(e);
          }}
          setDate={(e) => {
            setDate(e);
          }}
          active={activeProcedure}
          onChangeSwitch={(active) => setActiveProcedure(active)}
        />
      </ModalRight>
      <ModalRight
        setIsOpen={onCloseModalHearing}
        isOpen={openModalHearing}
        title={isEditHearing ? "Editar oitiva" : "Criar oitiva"}
        onClickAplicar={isEditHearing ? putHearing : postHearing}
        isEdit={isEditHearing}
        showIconDelete={false}
      >
        <CreateEditHearing
          date={expectedDateValue}
          setDateSelected={setExpectedDateValue}
          time={expectedTimeValue}
          chief={chief}
          setChief={setChief}
          setTimeSelected={setExpectedTimeValue}
          attendantDropSelected={attendantDropSelected}
          setAttendantDropSelected={setAttendantDropSelected}
          hearingTypeDropSelected={hearingTypeDropSelected}
          setHearingTypeDropSelected={setHearingTypeDropSelected}
          procedureCustomerId={itemData}
          errorFieldHearing={errorFieldHearing}
          isConfidential={isClassified}
          setIsConfidential={setIsClassified}
          policeChiefList={policeChiefList}
          //editando
          isEditHearing={isEditHearing}
          editAttendantName={editAttendantName}
          editHearingTypeName={editHearingTypeName}
          itemDataHearings={itemDataHearing}
          active={activeHearing}
          onChangeSwitch={() => setActiveHearing(!activeHearing)}
          //controle validation
          setDateClear={setDateClear}
          setTimeClear={setTimeClear}
        />
      </ModalRight>
      <ModalConfirmation
        onClose={() => {
          setShowModalConfirmation(false);
        }}
        title="Deseja inativar o procedimento?"
        titleBody="O processo a seguir irá inativar o procedimento"
        subtitleBody="Deseja prosseguir com essa ação?"
        labelButton="Sim"
        onClickButton={onClickInativarProcedimento}
        isOpen={showModalConfirmation}
      />
    </>
  );
};
