import styled, { css } from "styled-components";
import { stylesGlobalDefault } from "../../global/globalStyle";

interface IStatus {
  active?: boolean;
  isActive?: boolean;
  background?: boolean;
  notPointer?: boolean;
}

export const CardContainer = styled.div<IStatus>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px -40px;
  width: 100% - 40px;
  background: ${({ background }) =>
    background ? "#D1DBE3" : stylesGlobalDefault.backgroundListItemColor};
  margin-bottom: 4px;
  border-radius: 8px;
  padding: 20px;
  border: 2px solid
    ${({ isActive }) =>
      isActive ? "#1d9bf0" : stylesGlobalDefault.backgroundListItemColor};
  cursor: ${(props) => (props.notPointer ? "default" : "pointer")};

  ${(props) =>
    !props.notPointer &&
    css`
      &:hover {
        border: 2px solid #1d9bf0;
      }
    `}
`;
export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
export const ContainerTitleWithNumber = styled.div`
  display: flex;
  align-items: center;
`;

export const ContainerTitle = styled.div`
  width: 60%;
`;

export const LabelCard = styled.label`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 125%;
  color: #000;
  text-align: start;
`;

export const StatusContainer = styled.div<IStatus>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  background: ${(props) =>
    props.active
      ? stylesGlobalDefault.activedItem
      : stylesGlobalDefault.inactivedItem};
  padding: 4px 8px;
  border-radius: 20px;

  strong {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 12px;
    text-align: right;
    text-transform: uppercase;
    color: #fff;
  }
`;
export const Content = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
`;
