import { Container, Label, Svg, Title } from "./style";

interface GenericMetricProps {
  title: string;
  svg: any;
  value: number;
  labelButton: string;
  onClick: () => void;
  color: string;
}

export function GenericMetric({
  title,
  svg,
  value,
  labelButton,
  color,
  onClick,
}: GenericMetricProps) {
  return (
    <Container
      style={{
        backgroundColor: color,
      }}
    >
      <Title>{title}</Title>
      <Svg src={svg}></Svg>
      <Label>{value}</Label>
    </Container>
  );
}
