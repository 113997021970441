import { IlistPerYer } from "../../components/Body/ReportArea/dtos";
import { CardContainer, Content, LabelCard, Wrapper } from "./style";

export function CardReportPerYer({
  status,
  total,
  year,
  background,
  notPointer = false,
}: IlistPerYer) {
  return (
    <CardContainer notPointer={notPointer} background={background}>
      <Wrapper>
        <Content>
          <LabelCard>{year}</LabelCard>
        </Content>
        <Content>
          <LabelCard>{status}</LabelCard>
        </Content>
        <Content>
          <LabelCard>{total}</LabelCard>
        </Content>
      </Wrapper>
    </CardContainer>
  );
}
