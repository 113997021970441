import React, { useEffect, useState } from "react";
import ArrowDown from "../../assets/svg/arrow-down.svg";
import ArrowUp from "../../assets/svg/arrow-up.svg";
import { CheckBoxProfile } from "../CheckBoxProfile";
import * as S from "./styles";
import { ExpandableLabel } from "../TreeCheckboxProfile";
import { useLoadingProfileContext } from "../../pages/app/profiles/profile-hook";

interface Actions {
  action: string;
  active: boolean;
  id: number;
  systemActionId: number;
  systemObjectUserCustomerProfileActionId: number | null;
}

interface DropdownOption {
  title: string;
  id: string;
  actionsList: Actions[];
}

interface IIdPair {
  optionId: string | number;
  actionId: string | number;
  systemObjectUserCustomerProfileId?: string | number;
}

interface DropdownProps {
  title: string;
  options: DropdownOption[];
  onChange: (value: IIdPair) => void;
  isOpen?: any;
  setIsOpen?: any;
  isCreateNewProfile: boolean;
}

const Dropdown: React.FC<DropdownProps> = ({
  title,
  options,
  onChange,
  isOpen,
  setIsOpen,
  isCreateNewProfile,
}) => {
  const [dataFormatted, setDataFormatted] = useState<any[]>([]);
  const {setLoadingProfile} = useLoadingProfileContext();

  const handleDropdownClick = () => {
    setIsOpen(!isOpen);
  };

  function getActionList() {
    const titleToFilterBy = "Relatórios";

    if (title === titleToFilterBy) {
      const newOptions =
        options &&
        options.length &&
        options?.filter(
          (optionInList: any) => optionInList?.title === titleToFilterBy
        );

      setDataFormatted(newOptions || []);
    } else {
      const newOptions =
        options &&
        options.length &&
        options?.filter(
          (optionInList: any) => optionInList?.title !== titleToFilterBy
        );

      setDataFormatted(newOptions || []);
    }
  }

  useEffect(() => {
    getActionList();
  }, [options]);

  return (
    <S.DropdownContainer>
      <S.DropdownTitle onClick={handleDropdownClick}>
        {title}
        <S.DropdownIcon>
          {isOpen ? <img src={ArrowUp} /> : <img src={ArrowDown} />}
        </S.DropdownIcon>
      </S.DropdownTitle>

      {isOpen && (
        <S.DropdownMenu>
          {title === "Ações" && <S.LabalContent></S.LabalContent>}
          {dataFormatted &&
            dataFormatted?.map((option, index) => (
              <S.DropdownOptionItem key={index}>
                <ExpandableLabel title={option.title}>
                  {/* {
                                title === 'Ações' && (
                                    <div style={{ fontSize: 14, width: '45%' }}>{option.title}</div>
                                )
                            }
                            <div style={
                                title === 'Relatórios' ?
                                    { display: 'flex', flexDirection: 'column', marginRight: 'auto' }
                                    :
                                    { display: 'flex', flexDirection: 'row', marginRight: 'auto' }
                            }> */}
                  {option?.actionsList
                    ? option?.actionsList?.map((item: Actions, index: any) => {
                        return (
                          <CheckBoxProfile
                            label={item.action}
                            key={index}
                            checked={item.active}
                            systemActionId={item.systemActionId}
                            systemObjectUserCustomerProfileId={
                              option.systemObjectUserCustomerProfileId
                            }
                            style={
                              title === "Ações"
                                ? { marginLeft: "24px", marginTop: 4 }
                                : { marginBottom: 10, marginTop: 10 }
                            }
                            userCustomerProfileId={option.userCustomerProfileId}
                            id={option.id}
                            systemObjectCustomerId={
                              option.systemObjectCustomerId
                            }
                            systemObjectUserCustomerProfileActionId={
                              item.systemObjectUserCustomerProfileActionId
                            }
                            onChange={(e: any) => {
                              onChange(e);
                              if (e.actionId != 1) {
                                option = option?.actionsList?.map(
                                  (item: Actions) => {
                                    let element = document.getElementById(
                                      `option-${option.id}-action-${1}`
                                    );
                                    if (
                                      item.systemActionId == 1 &&
                                      !item.active &&
                                      e.active
                                    ) {
                                      setLoadingProfile(true);
                                      setTimeout(() => {
                                        if (element) element.click();
                                        setLoadingProfile(false);
                                      }, 2000);
                                    }
                                  }
                                );
                              }
                            }}
                            isCreateNewProfile={isCreateNewProfile}
                          />
                        );
                      })
                    : null}
                  {/* </div> */}
                </ExpandableLabel>
              </S.DropdownOptionItem>
            ))}
        </S.DropdownMenu>
      )}
    </S.DropdownContainer>
  );
};

export default Dropdown;
