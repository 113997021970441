import ReactLoading from 'react-loading';
import { Container, ContainerGeral } from './styles';

interface Props {
  loading: boolean;
}

export function Loading({ loading }: Props) {
  if (loading) {
    return (
      <>
        <ContainerGeral />
        <Container>
          <span>Carregando...</span>
          <ReactLoading type="spin" color="#F2A60D" width={24} height={24} />
        </Container>
      </>
    );
  }
  return <div />;
}
