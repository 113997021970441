import styled from "styled-components";
import { stylesGlobalDefault } from "../../global/globalStyle";

export const Container = styled.div``;

export const AttachmentListContainer = styled.div`
  overflow-y: auto;
  max-height: 300px;
`;

export const AttachmentEmpty = styled.div`
  font-size: 14px;
  color: #7887a9;
  margin-top: 24px;
  font-family: "Inter Tight", Arial, Helvetica, sans-serif;
`;

export const AttachmentContainer = styled.div`
  display: flex;
  width: "100%";
  height: 28px;

  border-radius: 8px;
  align-items: center;
  padding: 10px;
  margin-top: 8px;
  border: 2px solid rgb(236, 240, 243);
  align-items: center;
  justify-content: space-between;

  .container_buttons {
    visibility: hidden;
  }

  &:hover {
    border: 2px solid #1d9bf0;

    .container_buttons {
      visibility: visible;
    }
  }
`;

export const AttachmentIconContainer = styled.div`
  display: flex;
  width: 28px;
  height: 28px;
  border-radius: 50%;

  border: 1.5px solid #7887a9;
  align-items: center;
  justify-content: center;
`;

export const ContainerText = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 12px;
`;
export const LeftContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Title = styled.div`
  font-family: "Inter";
  font-weight: 600;
  font-size: 12px;
`;
export const Description = styled.div`
  font-family: "Inter";
  font-size: 12px;
  font-weight: 400;
  margin-top: 4px;
`;

export const RightContainer = styled.div`
  display: flex;
  flex-direction: row;

  button {
    display: flex;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    background: ${stylesGlobalDefault.blue};
    margin-left: 12px;
    border: 0;

    cursor: pointer;
  }
`;
