import styled, { css } from "styled-components";
import { stylesGlobalDefault } from "../../global/globalStyle";

interface Props{
  marginTop?: string
}

interface IProps {
  error: boolean;
  notError: boolean;
  height?: number;
  width?: number;
  focus: boolean;
}

export const Container = styled.div<Props>`
  width: 100%;
  margin-bottom: 22px;
  margin-top:${({marginTop})=> marginTop}rem;

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
    -moz-appearance: textfield;
  }

  input[type="date"],
  input[type="time"] {
    font-family: ${stylesGlobalDefault.fontFamily};
    font-weight: 400;
    color: ${ stylesGlobalDefault.gray };
    ::-webkit-calendar-picker-indicator {
      color: red;
      opacity: 1;
    }
  }
`;

export const ContainerTitleWithBadge = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ContainerLabel = styled.div`
  width: 100%;
`;

export const ContainerBadge = styled.div`
  margin-left: 4px;
`;

export const Label = styled.span`
  font-family: ${stylesGlobalDefault.fontFamily};
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  height: 17px;
  color: ${ stylesGlobalDefault.gray };;
  //margin-bottom: 4.5px;
`;

export const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  strong {
    font-family: ${stylesGlobalDefault.fontFamily};
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
  }

  img {
    cursor: pointer;
  }
`;

export const ContainerInput = styled.div<IProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(props) => (props.width ? props.width + "px" : "100% -20px")};
  height: 48px;
  //border: 2px solid #d1dbe3;
  border: 2px solid ${stylesGlobalDefault.inputsBorderFocusOff};
  border-radius: 4px;
  margin-top: 4.5px;

  ${(props) =>
    props.error &&
    css`
      border: 2px solid ${stylesGlobalDefault.inputsBorderFocusError};
    `}

  ${(props) =>
    props.notError &&
    css`
      border: 2px solid ${stylesGlobalDefault.inputsBorderFocusOff};
    `}

    ${(props) =>
    props.focus && !props.error &&
    css`
      border: 2px solid ${stylesGlobalDefault.blue};
    `}    
`;

export const InputText = styled.input`
  width: 100%;
  padding: 5px 10px;
  height: 37px;
  border: none;
  outline: none;
  font-size: 14px;
  font-family: ${stylesGlobalDefault.fontFamily};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`;

export const ContainerIcon = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  padding-right: 17px;
  padding-left: 17px;
`;
