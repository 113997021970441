import styled, { css } from "styled-components";

interface IListItem {
  isSelected: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  /* padding-right: 30px; */
  height: 100%;
  align-items: center;
  margin-right: 30px;
  z-index: 999;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
`;

export const DropDownContainer = styled("div")`
  cursor: pointer;
`;

export const DropDownHeader = styled("div")`
  border-radius: 4px;
  background: #ffffff;
  margin-right: 12px;

  strong {
    color: #3e424e;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
  }
`;

export const DropDownListContainer = styled("div")``;

export const DropDownList = styled("ul")`
  padding: 2px;
  margin-top: 20px;
  margin-left: -6px;
  position: fixed;
  background: #fff;
  border: 2px solid #d1dbe3;
  box-sizing: border-box;
  color: #3e424e;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  font-size: 14px;
  border-radius: 4px;
`;

export const ListItem = styled("li")`
  list-style: none;
  padding: 8px 12px 8px 10px;

  ${(props: IListItem) =>
    props.isSelected &&
    css`
      background: #e4f1fa;
    `}

  &:hover {
    background: #e4f1fa;
  }

  img {
    margin-left: 12px;
  }
`;
