import { userCreateUnitTypes } from "../actions/actios.types";

interface userCreateUnit {
  userCreateUnit: {
    userData?: any[];
    userNewData?: any;
    errorName?: boolean;
    errorEmail?: boolean;
  };
  isEdit: boolean;
}

const initialState: userCreateUnit = {
  userCreateUnit: {
    userData: [],
  },
  isEdit: false,
};

const userCreateUnitReducer = (
  state = initialState,
  action: any
): userCreateUnit => {
  switch (action.type) {
    case userCreateUnitTypes.SET_USERCREATE_UNIT:
      return {
        ...state,
        userCreateUnit: action.payload,
      };
    default:
      return state;
  }
};
export default userCreateUnitReducer;
