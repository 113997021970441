import { useState, useEffect } from "react";
import { ChangePassword } from "../../components/ChangePassword";
import {
  Container,
  ContainerBody,
} from "../../pages/app/StyleCSS/bodyCreateEdit";

import PhotoWebCam from "../../components/PhotoWebCan";
import { FileService } from "../../services/file";
import { LogOperation } from "../../utils/entities/logOperation";
import { v4 } from "uuid";
import moment from "moment";
import { useToast } from "../../hooks/toast";
import { userService } from "../../services/users";
import { useDispatch } from "react-redux";
import { updateUser } from "../../store/modules/user/actions";
import { useNavigate } from "react-router-dom";
import { getValueFromlocalStorage, insertTolocalStorage } from "../../utils/sessionStorageEncrypt";

interface IMyAccount {
  onClickModal: boolean;
  setOnClickModal: (e: boolean) => void;
  changePicture: boolean;
  setChangePicture: (e: boolean) => void;
}

export const MyAccount = (props: IMyAccount) => {
  //Photo
  const [file, setFile] = useState<File>();
  const [img, setImg] = useState<string>("");
  const [fileImg, setFileImg] = useState<File>();
  const navigate = useNavigate();

  //Password
  const [passwordOld, setPasswordOld] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [submitNewPassword, setSubmitNewPassword] = useState<boolean>(false);

  const dispatch = useDispatch();
  const { addToast } = useToast();

  useEffect(() => {
    if (props.onClickModal) {
      if (passwordConfirm !== "") {
        setSubmitNewPassword(true);
      }

      if (img !== "" || file !== undefined) {
        postPhoto();
      }
    }

    props.setOnClickModal(false);
  }, [props.onClickModal]);

  async function postPhoto() {
    if (img === "" && file === undefined) {
      addToast({
        type: "warning",
        title: "Aviso",
        description: "Escolha uma imagem ou tire uma foto",
      });
      return;
    }

    if (file !== undefined) {
      await submitFile(file);
    } else {
      await submitFile(fileImg);
    }
  }

  async function alterlocalStorage() {
    // 1 - get session storage user
    let useStorage = getValueFromlocalStorage("@Kenta:user");
    // 2 - pegar file name, trocar o image do getsession
    const user = useStorage ? JSON.parse(useStorage) : null;

    if (user) {
      const { data } = await userService.get(LogOperation.getUserId());
      const newUser = {
        ...data,
        picture: data.picture.replace(/\\/gm, "/"),
        hash: user.hash,
      };
      insertTolocalStorage("@Kenta:user", JSON.stringify(newUser));
      dispatch(updateUser(newUser));
      props.setChangePicture(!props.changePicture);
      navigate(0);
    }
  }

  async function submitFile(file: any) {
    try {
      const extension = file.name.match(/\.[a-z0-9A-Z]{2,4}/i);
      const filename = `${v4()}_${moment().format(
        "DDMMYYYYHHmmss"
      )}${extension}`;
      let fileName = `inqueritodigitalssppc\\${new Date().getFullYear()}\\unidade_${LogOperation.getCustomerId()}\\photo\\${filename}`;
      const fileItem = new FormData();
      fileItem.append("content", file, fileName);

      let response = await FileService.upload(fileItem);
      if (response.success) {
        addToast({
          type: "success",
          title: "Aviso",
          description: "Foto atualizada com sucesso",
        });
        await alterlocalStorage();
        return;
      } else {
        addToast({
          type: "error",
          title: "Aviso",
          description: "Falha ao enviar o arquivo",
        });
        return;
      }
    } catch (error) {
      addToast({
        type: "error",
        title: "Aviso",
        description: "Falha ao enviar o arquivo",
      });
      return;
    }
  }

  return (
    <>
      <Container>
        <ContainerBody>
          <PhotoWebCam img={img} setImg={setImg} setFileImg={setFileImg} />
          <ChangePassword
            isLoged={true}
            passwordOld={passwordOld}
            setPasswordOld={setPasswordOld}
            password={password}
            setPassword={setPassword}
            passwordConfirm={passwordConfirm}
            setPasswordConfirm={setPasswordConfirm}
            submitNewPassword={submitNewPassword}
            setSubmitNewPassword={setSubmitNewPassword}
          />
        </ContainerBody>
      </Container>
    </>
  );
};
