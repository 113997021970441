import styled, { css } from "styled-components";
import { stylesGlobalDefault } from "../../../global/globalStyle";

interface ILine {
  disabled?: boolean;
}
export const ContainerIconLabel = styled.div`
  /* width: 100%;
  display: flex;
  vertical-align: middle; */

  //background-color: blue;
  //height: 20px;
  width: 100%;
  height: 24px;
  display: flex;
  flex-direction: row;
  vertical-align: middle;
  align-items: initial;
  padding-top: 8px;
  padding-right: 0px;
  padding-bottom: 8px;
  padding-left: 0px;

  // -----------------------------
  //background-color: blue;
`;

export const ContainerTitleWithNumber = styled.div`
  display: flex;
  align-items: center;
`;

export const Icon = styled.div`
  width: 24px;
  height: 24px;

  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;

  // -----------------------------
  //background-color: green;
`;

export const Label = styled.div<ILine>`
  font-family: ${stylesGlobalDefault.fontFamily};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */

  flex: 1 0 0;
  padding-left: 8px;
  height: 24px;

  // -----------------------------

  display: flex;
  align-items: center;
  justify-content: start;

  cursor: pointer;
  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.6;
      cursor: default;
    `}
`;

export const Hr = styled.hr`
  width: 100%;
  height: 1px;
  background-color: ${stylesGlobalDefault.grayLight};
  border: none;
`;
