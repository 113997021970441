// import { IlistPerYer } from "../../components/Body/ReportArea/dtos";
import moment from "moment";
import {
  CardContainer,
  ContainerTitle,
  Content,
  LabelCard,
  Wrapper,
} from "./style";

export function CardReportLogOperation({
  userName,
  operation,
  systemObject,
  dateTime,
  version,
  background = false,
  notPointer = false,
}: any) {
  // Option 2: Constructing a custom format

  const dateFormated =
    moment(dateTime).format("DD/MM/YYYY") +
    ", " +
    moment(dateTime).format("HH:mm") +
    " hrs";

  return (
    <CardContainer notPointer={notPointer} background={background}>
      <Wrapper>
        <ContainerTitle>
          <LabelCard>{userName}</LabelCard>
        </ContainerTitle>
        <Content>
          <LabelCard>{operation}</LabelCard>
        </Content>
        <Content>
          <LabelCard>{systemObject}</LabelCard>
        </Content>
        <Content>
          <LabelCard>{background ? dateTime : dateFormated}</LabelCard>
        </Content>
        <Content>
          <LabelCard>{version}</LabelCard>
        </Content>
      </Wrapper>
    </CardContainer>
  );
}
