import React, { CSSProperties } from "react";
import Lupa from "../../assets/svg/lupa-white.svg";
import { Container, PlusIcon } from "./styles";

type NewType = {
  onClick?: () => unknown;
  style?: CSSProperties;
  id?: string;
  icon?: boolean;
  widthHeight?: number;
  isFilter: boolean;
};

export type IProps = NewType;

export const FilterButton: React.FC<IProps> = ({
  onClick,
  id,
  style,
  icon,
  widthHeight,
  isFilter,
}) => {
  return (
    <Container
      data-testid="add-button"
      id={id}
      onClick={onClick}
      style={style}
      widthHeight={widthHeight}
      isFilter={isFilter}
    >
      <PlusIcon id="add_button">
        <img src={Lupa} alt="" height={"18px"} />
      </PlusIcon>
    </Container>
  );
};
