import styled, { css } from "styled-components";
import { stylesGlobalDefault } from "../../global/globalStyle";

interface IProps {
  error: boolean;
  notError: boolean;
  height?: number;
  width?: number;
  focus: boolean;
}

export const Container = styled.div`
  width: 100%;
  margin-bottom: 22px;

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
    -moz-appearance: textfield;
  }

  input[type="date"],
  input[type="time"] {
    font-family: ${stylesGlobalDefault.fontFamily};
    font-weight: 400;
    color: ${stylesGlobalDefault.gray};
    ::-webkit-calendar-picker-indicator {
      opacity: 1;
    }
  }

  input[type="date"]::-webkit-calendar-picker-indicator {
    opacity: 0.5;
  }
`;

export const Label = styled.span`
  font-family: ${stylesGlobalDefault.fontFamily};
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  height: 17px;
  color: ${stylesGlobalDefault.gray};
`;

export const ContainerInput = styled.div<IProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(props) => (props.width ? props.width + "px" : "100% -20px")};
  height: 48px;
  border: 2px solid ${stylesGlobalDefault.inputsBorderFocusOff};
  border-radius: 4px;
  margin-top: 4.5px;

  ${(props) =>
    props.error &&
    css`
      border: 2px solid #eb5861;
    `}

  ${(props) =>
    props.notError &&
    css`
      border: 2px solid ${stylesGlobalDefault.inputsBorderFocusOff};
    `}
  
  ${(props) =>
    props.focus &&
    css`
      border: 2px solid ${stylesGlobalDefault.blue};
    `}
`;

export const InputText = styled.input`
  width: 100%;
  padding: 5px 10px;
  height: 37px;
  border: none;
  outline: none;
  font-size: 14px;
  font-family: ${stylesGlobalDefault.fontFamily} !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 20px;
`;

export const ContainerIcon = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  padding-right: 17px;
  padding-left: 17px;
`;
