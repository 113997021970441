import React, { useEffect, useState } from "react";
import { CircleBadge } from "../Badges/Circle";

import {
  Container,
  InputTextAreaCss,
  Label,
} from "./styled";

type IInput = {
  id?: string;
  width?: number;
  height?: number;
  marginLeft?: number;
  label: string;
  value?: string | number;
  icon?: boolean;
  maxLength?: number;
  type?: "text" | "number" | "date" | "time";
  error?: boolean;
  setType?: (e: any) => unknown;
  onChange: (e: string) => unknown;
  disabled?: boolean;
  required?: boolean;
  startFocus?: boolean;
};

export const InputTextArea: React.FC<IInput> = ({
  id,
  label,
  maxLength,
  onChange,
  value,
  disabled = false,
  error = false,
  type = "text",
  width,
  height,
  required,
  marginLeft,
  startFocus
}) => {
  const [focus, setFocus] = useState<boolean>(false);
  const [errorRequired, setErrorRequired] = useState<boolean>(false);

  const activeFocus = () => {
    setFocus(true);
  };

  const inactiveFocus = () => {
    setFocus(false);
  };

  function selectValue(e: any) {
    if (e !== null) {
      onChange(e);
      setErrorRequired(false);
    }
  }

  useEffect(() => {
    if (error) {
      setErrorRequired(true);
    }
    if (startFocus) {
      setFocus(true);
    }
  }, [error]);

  return (
    <Container style={{ marginLeft: marginLeft }}>
      <Label>{label}</Label>
      {required && (
        <CircleBadge radius="50px" widthHeigt="8px" />
      )}
      <InputTextAreaCss
        id={id}
        maxLength={maxLength}
        autoComplete="on"
        onFocus={activeFocus}
        onBlur={inactiveFocus}
        autoCapitalize="off"
        disabled={disabled}
        defaultValue={value}
        required={required}
        value={value}
        onChange={(e) => selectValue(e.target.value)}
        width={width}
        height={height}
        notError={!errorRequired && !!value}
        error={errorRequired}
        focus={focus}
        autoFocus={true}
      />
    </Container>
  );
};
