import { LOGIN_USER, SELECT_PROFILE, UPDATE_USER } from "./actios.types";

export const loginUser = (user: any) => {
  return {
    type: LOGIN_USER,
    user,
  };
};

export const selectProfile = (profile: any) => {
  return {
    type: SELECT_PROFILE,
    profile,
  };
};

export const updateUser = (user: any) => {
  return {
    type: UPDATE_USER,
    user,
  };
};