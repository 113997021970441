import styled, { css } from "styled-components";
import { stylesGlobalDefault } from "../../global/globalStyle";

interface IProps {
  size?: number;
}
interface IContainer {
  hasLineTop?: boolean;
  hasLineBottom?: boolean;
}

interface IButton {
  isDisabled?: boolean;
}

export const Container = styled.div<IContainer>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  ${(props) =>
    props.hasLineTop &&
    css`
      border-top: 1px solid ${stylesGlobalDefault.inputsBorderFocusOff};
      padding-top: 26px;
      margin-top: 36px;
    `}

  ${(props) =>
    props.hasLineBottom &&
    css`
      border-bottom: 1px solid ${stylesGlobalDefault.inputsBorderFocusOff};
      padding-top: 26px;
      margin-top: 36px;
    `}
`;

export const Title = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${stylesGlobalDefault.black};
  font-size: ${({ size }: IProps) => (size ? size : 18)}px;
  margin-right: 8px;
  font-family: ${stylesGlobalDefault.fontFamily};
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
`;

export const EditContainer = styled.button<IButton>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid ${stylesGlobalDefault.blue};
  cursor: pointer;

  &:hover {
    background-color: ${stylesGlobalDefault.blue};
    border: 2px solid ${stylesGlobalDefault.blue};
  }

  ${(props) =>
    props.isDisabled &&
    css`
      border: 2px solid ${stylesGlobalDefault.grey_disabled};
      background-color: none;

      &:hover {
        background-color: ${stylesGlobalDefault.grey_disabled};
        border: 2px solid ${stylesGlobalDefault.grey_disabled};
      }
    `}
`;

export const ShowContainer = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border: none;
  cursor: pointer;
  margin-left: 10px;
`;

export const RightContainerSidebar = styled.div`
  display: flex;
  flex-direction: row;
`;
