import styled, { css } from "styled-components";
import { stylesGlobalDefault } from "../../../../global/globalStyle";

interface IContainerInput {
  error?: boolean;
}

export const BottomContainer = styled.div`
  width: 100%;
  align-items: flex-start;
  margin-top: 12px;
`;

export const KeyWordsTitle = styled.span`
  font-size: 16px;
  font-family: "Inter";
  font-weight: 600;
  line-height: 0.36px;
`;

export const ContainerCheckboxList = styled.div`
  margin-top: 12px;

  div + div {
    margin-top: 12px;
  }
`;

export const ContainerTextEditor = styled.div<IContainerInput>`
  border: 2px solid ${stylesGlobalDefault.inputsBorderFocusOff};

  ${(props) =>
    props.error &&
    css`
      border: 2px solid ${stylesGlobalDefault.inputsBorderFocusError};
    `}
  width: 100%;
  border-radius: 10px;
`;
