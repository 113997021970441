import { PoliceUnit } from "../../../../utils/entities/policeUnit";
import { PoliceUnitTypes } from "./actios.types";

export interface SetPoliceUniteAction{
  type: PoliceUnitTypes.SET_POLICE_UNIT,
  payload: PoliceUnit
}

export const setPoliceUnit = (policeUnit: PoliceUnit): SetPoliceUniteAction => ({
    type: PoliceUnitTypes.SET_POLICE_UNIT,
    payload: policeUnit
});


