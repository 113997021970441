import { ChartContainer } from "../ChartContainer";
import { Divider } from "../ChartContainer/styles";
import { MinutesTranscriptionCertificate } from "./MinutesTranscription";
import { SpaceBetweenCharts } from "./styles";

export function MinutesCertificate({
  chartMinutes,
  certificates,
}: {
  chartMinutes: any;
  certificates: any;
}) {
  // const totalRestCertificates = certificates.totalAccess - certificates.usersLoggedIn
  return (
    <ChartContainer
      title="Certificado e minutagem"
      styleContainer={{
        width: "49%",
        height: "99%",
        marginTop: "8px",
        justifyContent: "flex-start",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "100%",
        }}
      >
        <Divider
          style={{
            background: "#CCC",
          }}
        />
        <div
          style={{
            height: "50%",
          }}
        >
          <MinutesTranscriptionCertificate
            usersLoggedin={certificates.usersActive}
            isMinutagem={false}
            explanation=""
            title={
              certificates.description.charAt(0).toUpperCase() +
              certificates.description.slice(1)
            }
            labelUsed="Usuarios ativos"
            labelRest="Cadastro restante"
            total={certificates.totalAccess}
            used={certificates.usersLoggedIn}
          />
        </div>
        {chartMinutes && chartMinutes.creditAmount <= 0 ? null : (
          <SpaceBetweenCharts>
            <MinutesTranscriptionCertificate
              usersLoggedin={certificates.usersActive}
              isMinutagem={true}
              explanation="Valores em minutos"
              title="Minutagem de transcrições"
              labelUsed="Minutos consumidos"
              labelRest="Saldo restante"
              total={chartMinutes.creditAmount}
              used={chartMinutes.creditUsed}
            />
          </SpaceBetweenCharts>
        )}
      </div>
    </ChartContainer>
  );
}
