import React, { useEffect, useState } from "react";
import { ListEmpty } from "../ListEmpty";
import "./styles.css";
import { ContainerList, ListItems, ListPagination } from "./styles";
import ReactPaginate from "react-paginate";

interface IReactPaginate {
  list: Array<any>;
  title: string;
  height?: string;
}

export const ReactPaginateKenta = ({ list, title, height }: IReactPaginate) => {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [valeuRegisterPerPage, setValeuRegisterPerPage] = useState<number>(20);
  const [previousLabel, setPreviousLabel] = useState<string>("<");
  const [nextLabel, setNextLabel] = useState<string>(">");

  const offset = currentPage * valeuRegisterPerPage;
  const currentPageData = list.slice(offset, offset + valeuRegisterPerPage);
  const pageCount: number = Math.ceil(list.length / valeuRegisterPerPage);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }

    let paginationLocal = localStorage.getItem("PaginationList");
    paginationLocal !== null
      ? setValeuRegisterPerPage(parseInt(paginationLocal))
      : setValeuRegisterPerPage(10);

    if (pageCount === 1) {
      setPreviousLabel("");
      setNextLabel("");
    }
  }, []);

  useEffect(() => {
    if (pageCount === 0) {
      setPreviousLabel("");
      setNextLabel("");
    } else {
      if (pageCount > 1) {
        setPreviousLabel("");
        setNextLabel(">");
      }
    }
  }, [pageCount]);

  function handlePageClick(selectedPage: number) {
    setCurrentPage(selectedPage);
    formartPreviousLabel(selectedPage);
    formartNextLabel(selectedPage);
  }

  function formartPreviousLabel(selectedPage: number) {
    if (selectedPage === 0) {
      setPreviousLabel("");
    } else {
      setPreviousLabel("<");
    }
  }

  function formartNextLabel(selectedPage: number) {
    let lastPage = selectedPage + 1;
    if (lastPage === pageCount) {
      setNextLabel("");
    } else {
      setNextLabel(">");
    }
  }

  return (
    <>
      <ContainerList height={height}>
        {list.length > 0 ? (
          <div>
            <ListItems>
              <div className="menudivList">{currentPageData}</div>
            </ListItems>
          </div>
        ) : (
          <ListEmpty title={title} />
        )}
      </ContainerList>
      <div>
        <ListPagination>
          <ReactPaginate
            previousLabel={previousLabel}
            nextLabel={nextLabel}
            pageCount={pageCount > 1 ? pageCount : 0}
            onPageChange={(e: any) => handlePageClick(e.selected)}
            containerClassName={"pagination"}
            previousLinkClassName={
              previousLabel === "<"
                ? "pagination__link"
                : "previusAndNextDisableCursor"
            }
            nextLinkClassName={
              nextLabel === ">"
                ? "pagination__link"
                : "previusAndNextDisableCursor"
            }
            disabledClassName={"pagination__link--disabled"}
            activeClassName={"pagination__link--active"}
          />
        </ListPagination>
      </div>
    </>
  );
};
