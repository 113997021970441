import styled, { keyframes } from "styled-components";
import { stylesGlobalDefault } from "../../global/globalStyle";
interface Props {
  profile?: boolean;
  scroll?: boolean
}

export const appearFromLeftOLD = keyframes`
  from {
    opacity: 0;
    position: absolute;
    transform: translateX(125%);
  }
  to{
    opacity: 1;
    position: relative;
    transform: translateX(0);
  }
`;

export const appearFromLeft = keyframes`
  from {
    opacity: 0;
    position: fixed;
    transform: translateX(125%);
  }
  to {
    opacity: 1;
    position: relative;
    transform: translateX(125);
  }
`;

export const Container = styled.div`
  position: absolute;
  background: #00000088;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  justify-content: flex-end;
  z-index: 1000;
`;

export const Content = styled.div<Props>`
  width: ${({ profile }) => (profile ? "720px" : "480px")};
  height: 100vh;
  animation: ${appearFromLeft} 0.5s;
`;

export const ContainerTitle = styled.div`
  width: 100% - 60px;
  height: 10%;
  background-color: ${stylesGlobalDefault.blackMenu1};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;

  img {
    cursor: pointer;
  }
`;

export const Title = styled.h3`
  font-family: ${ stylesGlobalDefault.fontFamily };
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: ${ stylesGlobalDefault.white };
`;

export const ContainerBody = styled.div<Props>`
  width: 100%;
  height: 80%;
  background-color: ${ stylesGlobalDefault.white };
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  overflow: ${({scroll}) => scroll ? 'auto' : 'hidden'};
`;

export const ContainerFooter = styled.div`
  width: 100%;
  height: 10%;
  background-color: ${stylesGlobalDefault.blackMenu1};
  bottom: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
`;

export const styleDelete = {
  border: 1,
  borderStyle: "solid",
  borderColor: stylesGlobalDefault.blue,
  borderRadius: "20px",
  marginRight: 14,
  cursor: "pointer",
  padding: 4,
};

export const customStyles = {
  content: {
    border: "none",
    backgroundColor: stylesGlobalDefault.white,
    width: "45%",
    height: "100%",
    inset: "auto",
    padding: 0,
    top: 0,
    left: 0,
    borderRadius: 0,
    // position: 'initial',
  },
  overlay: {
    backgroundColor: "rgba(35, 35, 35, 0.75)",
    display: "flex",
    justifyContent: "flex-end",
  },
};

export const Button = styled.button`
  padding: 10px 15px;
  border: none;
  border-radius: 50px;
  font-weight: 500;
  outline: none;
  transition: all 0.2s;
  margin-right: 14px;
  background-color: ${stylesGlobalDefault.blue};
  color: ${ stylesGlobalDefault.white };
  border: 2px ${stylesGlobalDefault.blue} solid;
  cursor: pointer;

  &:hover {
    border-color: ${ stylesGlobalDefault.white };
    background-color: ${stylesGlobalDefault.blueDark};
    background: ${stylesGlobalDefault.blueDark};
  }
`;
