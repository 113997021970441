import React from 'react';
import close from '../../../assets/svg/close.svg';
import { Container, ContainerBody, ContainerTitle, Title } from './style';

export default function ModalRightDetail({ title, children, setIsOpen }: any) {
  return (
    <Container>
      <ContainerTitle>
        <Title>{title ? title : 'Titulo da página'}</Title>
        <img className='closeButton' src={close} onClick={setIsOpen} alt=""/>
      </ContainerTitle>
      <ContainerBody>{children}</ContainerBody>
    </Container>
  );
}
