import styled from "styled-components";
import { stylesGlobalDefault } from "../../global/globalStyle";

interface IProps {
  lowyer?: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  border: 2px solid ${stylesGlobalDefault.backgroundListItemColor};
  border-radius: 8px;
  padding: 10px;

  &:hover {
    border: 2px solid ${stylesGlobalDefault.blue};
    opacity: 1;

    .icons {
      opacity: 1;
      display: block;
    }
  }
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  border: 1.5px solid
    ${({ lowyer }: IProps) => (lowyer ? stylesGlobalDefault.blue : stylesGlobalDefault.orange)};
  border-radius: 50%;
  margin-right: 12px;
`;

export const AttendantContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Name = styled.div`
  font-family: ${stylesGlobalDefault.fontFamily};
  font-weight: 600;
  font-size: 12px;
  color: ${stylesGlobalDefault.black};
`;

export const Role = styled.div`
  font-family: ${stylesGlobalDefault.fontFamily};
  font-weight: 400;
  font-size: 12px;
  color: ${stylesGlobalDefault.gray};
  margin-top: 4px;
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const IconArea = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  opacity: 0;
  display: none;
  div {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    background: ${stylesGlobalDefault.blue};
    cursor: pointer;
  }

  svg {
    font-size: 14px;
    cursor: pointer;
    outline: none;
    color: ${stylesGlobalDefault.white};
  }

  img + img {
    margin-left: 10px;
  }
`;
