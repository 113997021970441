export interface ProfileAction {
  id: number;
  action: string;
}

export interface Permission {
  id: number;
  formId: number;
  form: string;
  url: string;
  listObjectUserProfileActionDTOs: ProfileAction[];
}

export const FORMS = {
  PROCEDURE_CUSTOMER: "ProcedureCustomer",
  PROCEDURE_TYPE: "ProcedureCustomerType",
};

export const FORMS_ADMIN = {
  IMPORT_SISP: "ImportSISP",
  PROCE_CUSTO_SOURSE: "ProcedureCustomerSource",
  POLICE_UNIT: "PoliceUnit",
  ATTENDANT_ROLE: "AttendantRole",
  HEARING_TYPE: "HearingType",
  ATTENDANT: "Attendant",
  PROFILE: "UserCustomerProfile",
  USER_CUSTOMER: "UserCustomer",
  SYSTEM_CONFIGURATION: "SystemConfiguration",
  SYSTEM_WATERMARK: "SystemWatermark",
  CERTIFICATE: "CustomerCertificate",
  PROCEDURE_CUS_TYPE: "ProcedureCustomerType",
  HEARING_TYPE_ATTENDANT_ROLE: "HearingTypeAttendantRole",
  PROCEDURE_CUSTOMER_TYPE_ATTENDANT_ROLE: "ProcedureCustomerTypeAttendantRole",
  PROCEDURE_TYPE_ATTENDANT_ROLE: "ProcedureCustomerTypeAttendantRole",
};

export const FORMS_ADMIN_V2 = [
  { ProcedureCustomerSource: "ProcedureCustomerSource" },
  { PoliceUnit: "PoliceUnit" },
  { AttendantRole: "AttendantRole" },
  { HearingType: "HearingType" },
  { Attendant: "Attendant" },
  { UserCustomerProfile: "UserCustomerProfile" },
  { UserCustomer: "UserCustomer" },
  { SystemConfiguration: "SystemConfiguration" },
  { SystemWatermark: "SystemWatermark" },
  { CustomerCertificate: "CustomerCertificate" },
  { ProcedureCustomerType: "ProcedureCustomerType" },
  { HearingTypeAttendantRole: "HearingTypeAttendantRole" },
  { ProcedureCustomerTypeAttendantRole: "ProcedureCustomerTypeAttendantRole" },
];

export const ADMIN_ROUTES_BY_FORM_PERMISSION = [
  {
    route: "ImportSISP",
    formAdminPermissionName: "ImportSISP",
  },
  {
    route: "ProcedureCustomerSource",
    formAdminPermissionName: "ProcedureCustomerSource",
  },
  {
    route: "policeUnit",
    formAdminPermissionName: "PoliceUnit",
  },
  {
    route: "tiposParticipantes",
    formAdminPermissionName: "AttendantRole",
  },
  {
    route: "hearingType",
    formAdminPermissionName: "HearingType",
  },
  {
    route: "attendant",
    formAdminPermissionName: "Attendant",
  },
  {
    route: "users",
    formAdminPermissionName: "UserCustomer",
  },
  {
    route: "profiles",
    formAdminPermissionName: "UserCustomerProfile",
  },
  {
    route: "systemConfiguration",
    formAdminPermissionName: "SystemConfiguration",
  },
  {
    route: "customerCertificate",
    formAdminPermissionName: "CustomerCertificate",
  },
  {
    route: "procedureCustomerType",
    formAdminPermissionName: "ProcedureCustomerType",
  },
  {
    route: "procedureTypeAttendantRole",
    formAdminPermissionName: "ProcedureCustomerTypeAttendantRole",
  },
];

export const FORMS_RELATORIOS = {
  REPORT: "Report",
};

export const RELATORIO_ROUTES_BY_FORM_PERMISSION = [
  {
    route: "relatorio",
    formRelatorioPermissionName: "Report",
  },
  {
    route: "relatorio",
    formRelatorioPermissionName: "Logs",
  },
  {
    route: "error",
    formRelatorioPermissionName: "Procedimentos",
  },
];

export const FORM_EXPORT_FILES = {
  HEARING_FILE: "HearingFile",
};
